import React from "react";
import { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import productImg from "../../../assets/images/productImg.svg";
import mealAcordingIcon2 from "../../../assets/images/mealAcordingIcon2.svg";
import mealAcordingIcon3 from "../../../assets/images/mealAcordingIcon3.svg";
import mealAcordingIcon4 from "../../../assets/images/mealAcordingIcon4.svg";
import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

// Formik and Api Call
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makePostRequest,
  makeGetRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import swal from "sweetalert";
import moment from "moment";

// For Filter
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// For Filter End

import deliveryTruck from "../../../assets/images/delivery-truck.png";
import packageIm from "../../../assets/images/package.png";
import loaderImg from "../../../assets/images/logo.png";

import TaskAltIcon from '@mui/icons-material/TaskAlt';


function KitchenSuppliesTab() {
  const navigate = useNavigate();

  // ScanPop
  const [ScanPop, setOpenScanPop] = React.useState(false);

  const handleClickScanPop = (scrollType) => () => {
    setOpenScanPop(true);
    setScroll(scrollType);
  };

  const closeScanPop = () => {
    productItems.length = 0;
    setProductItems([]);
    setExistingQty(0);
    setUpdatedQty(0);
    setIsNew(false);
    setProductName("");
    setOpenScanPop(false);
  };
  // End

  // For Filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilter = (value) => {
    if (value == "Carton" || value == "SingleUnit") setFilterType(value);
    else setFilterType(null);
    setAnchorEl(null);
    getKitchenSuppliesList(null);
  };
  // For Filter End

  const [categoryListErrMsg, setCategoryListErrMsg] = useState("");
  const [categoriesDropDown, setCategoriesDropDown] = useState([]);

  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [masterStarAmount, setMasterStarAmount] = useState(0);
  const [priceErrMsg, setPriceErrMsg] = React.useState("");
  const [allowance_in_dollar, setAllowenceInDollar] = React.useState(0);
  const [star_allowance, setStarAllowence] = React.useState(0);

  // const [inputGrossaryData, setInputGrossaryData] = React.useState('');
  const [productItems, setProductItems] = React.useState([]);


  const [qrVal, setQrVal] = useState("")

  // For Append
  const AddedElement = () => (
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className="uploadImBox">
            <h1 className="">Upload Image</h1>
            <Button
              className="uploadImBt"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
            <span>or drag file in here</span>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Brand Name</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Brand Name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Total Unit Bought</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Total Unit"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Weight Per Unit</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Weight Per Unit"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Price Per Unit in INR (₹)</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="No. of Unit"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <label className="pageLabel">Select Weight Unit</label>
          <div className="unitDivMain">
            <div className="detailsTableFilterSec">
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                className="detailsTableFilterSecActionInr"
              >
                <ToggleButton value="Miligrams" className="filterBtn">
                  Miligrams
                </ToggleButton>
                <ToggleButton value="Grams" className="filterBtn">
                  Grams
                </ToggleButton>
                <ToggleButton value="KiloGrams" className="filterBtn">
                  Kilo Grams
                </ToggleButton>
                <ToggleButton value="Liter" className="filterBtn">
                  Liter
                </ToggleButton>
                <ToggleButton value="Milliliter" className="filterBtn">
                  Milliliter
                </ToggleButton>
                <ToggleButton value="Pounds" className="filterBtn">
                  Pounds
                </ToggleButton>
                <ToggleButton value="N/A" className="filterBtn">
                  N/A
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Origin</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Country Of Origin"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Low Stock Limit</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Stock Limit"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Expiry Date</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Expiry Date"
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <Button
            className="modalBtSmallWidth"
          // onClick={handleClickOpen("body")}

          // onClick={handleClickOpen3("body")}
          >
            Save
          </Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <div className="productDetails groceryTableMain">
            <table width="100%">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Brand</th>
                  <th>Weight Per Unit</th>
                  <th>Price Per Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tur Daal</td>
                  <td>Brand</td>
                  <td>26Gram</td>
                  <td>₹ 15</td>
                </tr>
                <tr>
                  <td>Tea</td>
                  <td>Tea Brand</td>
                  <td>1Kg</td>
                  <td>₹ 15</td>
                </tr>
                <tr>
                  <td>Potato Bhaji</td>
                  <td>Tea Brand</td>
                  <td>1Kg</td>
                  <td>₹ 15</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
  const [count, setCount2] = useState(0);
  // End

  const [selected, setSelected] = useState(false);

  //Formik Validation Category Start
  const [formValues, setFormValues] = useState(null);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [pageNo, setPageNo] = useState(0);
  const [sortColumnNameCategory, setSortColumnNameCategory] =
    useState("created_at");
  const [formValuesAddKitchenSupplies, setFormValuesAddKitchenSupplies] =
    useState(null);
  const [sortColumnName, setSortColumnName] = useState("created_at");
  const [kitchenSuppliesData, setKitchenSuppliesData] = useState([]);
  const [isEditKitchenSupplies, setIsEditKitchenSupplies] = useState(false);
  const [selectedType, setSelectedType] = useState("Carton");
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [profilePic, setProfilePic] = React.useState([]);
  const [filterType, setFilterType] = useState(null);
  const [duplicateErrMsg, setDuplicateErrMsg] = React.useState("");
  const [cartonWtUnitErrMsg, setCartonWtUnitErrMsg] = React.useState("");
  const [wtUnitErrMsg, setWtUnitErrMsg] = React.useState("");

  const [new_quantity, setNewQuantity] = useState(0);
  const [total_cartons, setTotalCartons] = useState(0);
  const [total_unit_bought, setTotalUnitBought] = useState(0);
  const [updatedQuantity, setUpdatedQuantity] = useState(0);

  const initialValuesAddCategory = {
    category_name: "",
  };
  const validationSchemaAddCategory = Yup.object({
    category_name: Yup.string().required("Required"),
  });
  const [upload, setUpload] = React.useState(false);
  //Formik Validation Category End

  // Edit Scanned
    const [EditScanned, setOpenEditScanned] = React.useState(false);

    const closeEditScanned = () => {
      setOpenEditScanned(false);
    };
    

  //Add Category || Manage Category Start
  const addOrUpdateCategory = async (values, { resetForm }) => {
    let updateCategory = true;
    let dupRes = categoryData.find(
      (o) =>
        o.category_name.toLowerCase() === values.category_name.toLowerCase()
    );

    if (isEditCategory) {
      if (dupRes) {
        if (values.category_id == dupRes.category_id) {
          updateCategory = true;
        } else {
          updateCategory = false;
        }
      } else {
        updateCategory = true;
      }
      if (updateCategory) {
        values.category_id = parseInt(values.category_id);
        values.status = values.status;

        makePostRequest("starcafe/updatekitchencategory", values)
          .then((response) => {
            if (response.data) {
              getCategoryList(null);
            }
            setIsEditCategory(false);
            values.category_name = "";
            values.category_id = "";
            setDuplicateErrMsg("");
          })
          .catch((err) => {
            console.log(err);
          });
      } else setDuplicateErrMsg("Duplicate Value");
    } else {
      if (dupRes) {
        setDuplicateErrMsg("Duplicate Value");
      } else {
        values.status = true;
        makePostRequest("starcafe/insertkitchencategory", values)
          .then((response) => {
            if (response.data) {
              console.log(response);
              getCategoryList(null);
            }
            values.category_name = "";
            resetForm();
          })
          .catch((err) => {
            console.log(err);
          });
        setDuplicateErrMsg("");
      }
    }
  };
  //Add Category || Manage Category Start

  // Get Category Kitchen Supplies Start
  const getCategoryList = async (SearchStr) => {
    if (!SearchStr) SearchStr = null;

    console.log(SearchStr);
    setShowLoader(true);
    makeGetRequest(
      "starcafe/kitchencategorysearch/" +
      sortColumnNameCategory +
      "/" +
      sortBy +
      "/" +
      SearchStr +
      "/" +
      pageNo
    )
      .then((response) => {
        if (response.data) {
          //console.log("Data",response.data.data)
          setCategoryData(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  const getCategoryDropDown = async () => {
    setCategoriesDropDown([]);
    let categoryObj;
    categoriesDropDown.length = 0;
    categoriesDropDown.push({ key: "Select Category", value: null });

    await makeGetRequest("starcafe/getkitchencategorylist/null/true")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              categoryObj = { key: "", value: "" };
              categoryObj.key = response.data.data[i].category_name;
              categoryObj.value = response.data.data[i].category_id;
              categoriesDropDown.push(categoryObj);
            }
            console.log(categoriesDropDown);
            setCategoriesDropDown(categoriesDropDown);
          } else setCategoriesDropDown(categoriesDropDown);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // Get Category Kitchen Supplies Start

  // Sort Category Start
  function handleChangeSortByCategory() {
    //alert("In ASC")
    setSortColumnNameCategory("category_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getCategoryList(null);
  }
  // Sort Category End

  //Edit Category Api start
  const editCategory = (categoryObj) => {
    console.log("data", categoryObj);
    setFormValues(categoryObj);
    setIsEditCategory(true);
  };
  // Edit Category End

  const cancelCategory = () => {
    setFormValues(null);
    setIsEditCategory(false);
    setDuplicateErrMsg("");
  };

  // Status Category Active InActive Start
  const handleStatusChangeCategory = (event, category_id) => {
    let inputdata = {
      category_id: category_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starcafe/updatekitchencategory", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getCategoryList(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Category Active InActive Start

  // Delete Category Api Start
  const deleteCategory = async (category_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Category Deleted!",
          text: "Category successfully deleted!",
          icon: "success",
        }).then(function () {
          let inputdata = {
            category_id: category_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starcafe/updatekitchencategory", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                cancelCategory();
                getCategoryList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Category Api End

  //Add Kitchen Supplies
  const [AddKitchen, setOpenAddKitchen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpenAddKitchen = (scrollType) => () => {
    getCategoryDropDown();
    setOpenAddKitchen(true);
    setScroll(scrollType);
  };

  const closeAddKitchen = () => {
    setOpenAddKitchen(false);
    setIsEditKitchenSupplies(false);
    setFormValuesAddKitchenSupplies(null);
    setProfilePic([]);
    setCartonWeight(0);
    setWeightUnit("");
    setPriceErrMsg("");
    setSelectedType("Carton");
    setOpenCartonOrSingle(false);
    setCategoryListErrMsg("");
    setCartonWtUnitErrMsg("");
    setWtUnitErrMsg("");
    setTotalCartons(0);
    setTotalUnitBought(0);
    setUpdatedQuantity(0);
    setNewQuantity(0);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Deduct
  const [DeductInventoryKitchen, setOpenDeductInventoryKitchen] =
    useState(false);
  const [isDeductProduct, setIsDeductProdct] = useState(true);

  const handleClickOpenDeductInventoryKitchen =
    (scrollType, isDeduct) => () => {
      setFilterType(null);
      setIsDeductProdct(isDeduct);
      setOpenDeductInventoryKitchen(true);
      setScroll(scrollType);
      getKitchenSuppliesList(null);
    };

  const closeDeductInventoryKitchen = () => {
    setOpenDeductInventoryKitchen(false);
  };
  // End

  // Add Items to Kitchen
  const [AddToKitchen, setOpenAddToKitchen] = React.useState(false);

  const handleClickAddToKitchen = (scrollType) => () => {
    setOpenAddToKitchen(true);
    setScroll(scrollType);
  };

  const closeAddToKitchen = () => {
    setOpenAddToKitchen(false);
  };
  // End

  // Modal
  const [open, setOpen] = useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setFormValues(null);
    setIsEditCategory(false);
    setDuplicateErrMsg("");
    setOpen(false);
  };
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const initialValuesKitchenSupplies = {
    // Add Kitchen Supplies
    product_name: "",
    low_stock_limit: 0,
    brand_name: "",
    // current_qty_carton:0,
    // total_cartons: 0,
    item_per_carton: 0,
    single_carton_weight: 0,
    wt_per_item: 0,
    price_per_carton: 0,
    price_per_item: 0,
    expiry_date: "",
    country_of_origin: "",
  };

  const validationSchemaKitchenSupplies = Yup.object({
    // Add Kitchen Supplies
    product_name: Yup.string().required("Required"),
    low_stock_limit: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    brand_name: Yup.string().required("Required"),
    // current_qty_carton:Yup.number().typeError("Invalid Number").moreThan(Yup.ref("low_stock_limit"),"value must be greater than low stock limit").required("Required"),
    // total_cartons: Yup.number()
    //   .typeError("Invalid Number")
    //   .moreThan(
    //     Yup.ref("low_stock_limit"),
    //     "value must be greater than low stock limit"
    //   )
    //   .required("Required"),
    item_per_carton: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    single_carton_weight: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    wt_per_item: Yup.number().typeError("Invalid Number").required("Required"),
    price_per_carton: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    price_per_item: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    expiry_date: Yup.date()
      .min(new Date(), "Date must be greater than todays date")
      .required("Required"),
    country_of_origin: Yup.string().required("Required"),
  });

  // Initial Values Single Unit
  const initialValuesKitchenSuppliesSingleUnit = {
    // Add Kitchen Supplies
    product_name: "",
    low_stock_limit: 0,
    brand_name: "",
    // current_qty: 0,
    // total_unit_bought: 0,
    wt_per_item: 0,
    price_per_item: 0,
    expiry_date: "",
    country_of_origin: "",
  };

  // Validation Schema Single Unit
  const validationSchemaKitchenSuppliesSingleUnit = Yup.object({
    product_name: Yup.string().required("Required"),
    low_stock_limit: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    brand_name: Yup.string().required("Required"),
    // current_qty: Yup.number().typeError("Invalid Number").moreThan(Yup.ref("low_stock_limit"), "value must be greater than low stock limit").required("Required"),

    // total_unit_bought: Yup.number()
    //   .typeError("Invalid Number")
    //   .moreThan(
    //     Yup.ref("low_stock_limit"),
    //     "value must be greater than low stock limit"
    //   )
    //   .required("Required"),
    wt_per_item: Yup.number().typeError("Invalid Number").required("Required"),
    price_per_item: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    expiry_date: Yup.date()
      .min(new Date(), "Date must be greater than todays date")
      .required("Required"),
    country_of_origin: Yup.string().required("Required"),
  });

  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|jfif|JFIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    console.log(e);
    setProfileErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    console.log(file);
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setProfilePic([...profilePic, imageUrl]);
      console.log("img arr", profilePic);
    } else {
      setProfileErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End

  // Delete Image Start
  const deleteImg = (id) => {
    setShowLoader(true);
    profilePic.splice(id, 1);
    setProfilePic(profilePic);
    setUpload(!upload);
    setShowLoader(false);
  };
  // Delete Image End

  // Add Kitchen Supplies Start
  const [tempKitchenSuppliesList, setTempKitchenSuppliesList] = useState([]);

  async function handleChangeNewQuantity(qty) {
    if (isEditKitchenSupplies == true) {
      console.log("In If")
      if (selectedType == 'Carton')
        setUpdatedQuantity(parseInt(qty) + parseInt(total_cartons))
      else
        setUpdatedQuantity(parseInt(qty) + parseInt(total_unit_bought))
    }
    else {
      if (selectedType == 'Carton')
        setTotalCartons(parseInt(qty));
      else
        setTotalUnitBought(parseInt(qty));
    }
  }

  const addKitchenSupplies = (values) => {
    // alert("In Fun")
    console.log(values)
    console.log()
    setDuplicateErrMsg("");
    if (values.category_id.split("|||")[0] == null) {
      values.category_id = undefined;
      setCategoryListErrMsg("Please select category");
    }
    else {
      values.category_id = parseInt(values.category_id.split("|||")[0]);
    }

    if (values.category_id && weightUnit) {
      if (selectedType == "Carton" && !cartonWeight) {
        setCartonWtUnitErrMsg("Required");
      }
      else {
        let updateInventory = true;
        let dupRes = kitchenSuppliesData.find(
          (o) =>
            o.product_name.toLowerCase() === values.product_name.toLowerCase()
        );

        if (isEditKitchenSupplies) {
          if (dupRes) {
            if (values.inventory_id == dupRes.inventory_id) {
              updateInventory = true;
            } else {
              updateInventory = false;
            }
          } else {
            updateInventory = true;
          }

          if (updateInventory) {
            values.category_type = selectedType;
            delete values.category_name;
            values.low_stock_limit = parseInt(values.low_stock_limit);
            values.weight_per_item_unit = weightUnit;
            values.wt_per_item = parseInt(values.wt_per_item);
            values.price_per_item = parseInt(values.price_per_item);
            values.item_image = profilePic;
            if (selectedType == "Carton") {
              values.total_cartons = parseInt(updatedQuantity);
              values.item_per_carton = parseInt(values.item_per_carton);
              values.single_carton_weight = parseInt(values.single_carton_weight);
              values.single_carton_weight_unit = cartonWeight;
              values.price_per_carton = parseInt(values.price_per_carton);
            } else {
              values.total_unit_bought = parseInt(updatedQuantity);
            }

            
            if (new_quantity > 0) {
              values.new_quantity=parseInt(new_quantity);
              values.quantity_updated_dt = new Date();
            }

            makePostRequest("starcafe/updatekitchensubcategory", values)
              .then((response) => {
                if (response.data) {
                  //console.log(response)
                  getKitchenSuppliesList(null);
                }
                if (selectedType == "Carton") closeAddKitchen();
                else closeSingleUnitPop();

                setOpenCartonOrSingle(false);
                setProfilePic([]);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          else
            setDuplicateErrMsg("Duplicate Value");
        } else {
          if (dupRes) {
            setDuplicateErrMsg("Duplicate Value");
          } else {
            values.category_type = selectedType;
            delete values.category_name;
            values.low_stock_limit = parseInt(values.low_stock_limit);
            values.weight_per_item_unit = weightUnit;
            values.wt_per_item = parseInt(values.wt_per_item);
            values.price_per_item = parseInt(values.price_per_item);
            values.item_image = profilePic;
            values.status = true;
            if (selectedType == "Carton") {
              values.single_carton_weight_unit = cartonWeight;
              values.total_cartons = parseInt(total_cartons);
              values.new_quantity = parseInt(new_quantity);
              values.item_per_carton = parseInt(values.item_per_carton);
              values.single_carton_weight = parseFloat(
                values.single_carton_weight
              );
              values.price_per_carton = parseInt(values.price_per_carton);
            } else {
              values.total_unit_bought = parseInt(total_unit_bought);
            }
            console.log(values);
            makePostRequest("starcafe/insertkitchensubcategory", values)
              .then((response) => {
                if (response.data) {
                  console.log(response.data.message);
                  console.log(response);
                  getKitchenSuppliesList(null);
                }
                setCartonWeight("");
                setWeightUnit("");
                setFormValuesAddKitchenSupplies(null);
                if (selectedType == "Carton") closeAddKitchen();
                else closeSingleUnitPop();

                setOpenCartonOrSingle(false);
                setProfilePic([]);
              })
              .catch((err) => {
                console.log(err);
              });
            setDuplicateErrMsg("");
          }
        }
      }
    } else {
      if (!values.category_id) setCategoryListErrMsg("Please select category");
      if (!weightUnit) setWtUnitErrMsg("Required");
    }
  };

  const updateQty = async (sku, current_qty, new_qty ) => {

    if (isNew) {
      let newItem = {
        sku: qrVal,
        product_name: productName,
        current_qty: 1,
        new_qty: 1,
      };
      newItem.status = true;
      productItems.unshift(newItem);
      setProductItems(productItems);
      console.log(newItem);
      setUpload(!upload);
      setShowLoader(true);
      await makePostRequest("starcafe/insertkitchensubcategorybyscan", newItem)
        .then((response) => {
          if (response.data) {
            console.log(response.data.message);
            console.log(response);

            setIsNew(false);
            setOpenEditScanned(false);
            setProductName("");
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    } else {
      await makePostRequest("starcafe/scankitchensubcategory/", {
        sku: sku,
        current_qty: current_qty,
        new_qty: parseInt(new_qty),
        total_unit_bought: parseInt(current_qty) + parseInt(new_qty),
      }).then((res) => {
        if (res.data.data) {
          if (
            productItems.some(
              (product) => product.sku === res.data.data[0].sku
            )
          ) {
            let xcnt = productItems.findIndex(
              (product) => product.sku === res.data.data[0].sku
            );
            const updateItem = productItems.filter((elem, ind) => {
              return ind !== xcnt;
            });
            updateItem.unshift(res.data.data[0]);
            setProductItems(updateItem);
          } else {
            setProductItems(res.data.data);
            productItems.unshift(res.data.data[0]);
            setProductItems(productItems);
          }
        } else {
          swal({
            title: "Product Not Available!",
            text: "Product ",
            icon: "success",
            timer: 3000,
          });
        }
      });
    }
  }

  const handleqrqty = (event, index) => {
    let data = [...productItems];
    data[index].new_qty = event.target.value;
    setProductItems(data);
  }

   const [timer, setTimer] = React.useState();
    const timeoutVal = 10;
    const [skuErrMsg, setSkuErrMsg] = React.useState("");
  
    function handleKeyUp(e) {
      window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
      setTimer(
        window.setTimeout(() => {
          console.log(e.target.value);
          if (e.target.value != "") {
            setSkuErrMsg("");
            setQrVal(e.target.value);
            addByScan(e.target.value);
            console.log(e.target.value);
          }
        }, timeoutVal)
      );
    }
  
    function handleKeyPress(e) {
      window.clearTimeout(timer);
      setQrVal(e.target.value);
    }


    const [productName, setProductName] = React.useState("");
    const [isNew, setIsNew] = React.useState(false);
    const [existingQty, setExistingQty] = React.useState(0);
    const [updatedQty, setUpdatedQty] = React.useState(0);
    const [isAddProduct, setIsAddProduct] = React.useState(false);
  
    async function addByScan(scannedBarcode) {
      let sku = document.getElementById("scanText").value;
      let isProductAvailable = false;
      let productObj = {};
      let tempExisting = 0;
      let tempUpdated = 0;
      if (sku) {
        // setShowLoader(true);
        await makeGetRequest("starcafe/getkitchensubcategorylistBysku/" + sku)
          .then((response) => {
            console.log(response);
  
            if (response.data.data && response.data.data.length > 0) {
              isProductAvailable = true;
              productObj = response.data.data[0];
              // if (existingQty == 0)
              //   setExistingQty(response.data.data[0].total_unit_bought);
              if (existingQty == 0) {
                setExistingQty(response.data.data[0].total_unit_bought);
                tempExisting = response.data.data[0].total_unit_bought;
              } else tempExisting = existingQty;
              setUpdatedQty(updatedQty + 1);
              tempUpdated = updatedQty + 1;
              setUpload(!upload);
            } else {
              isProductAvailable = false;
            }
            setShowLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setShowLoader(false);
          });
        console.log(isProductAvailable);
  
        if (isProductAvailable == true) {
          // setShowLoader(true);
          if (isAddProduct == true) {
            setSkuErrMsg("Already Exist");
          } else {
            await makePostRequest("starcafe/scankitchensubcategory/", {
              sku: sku,
              current_qty: tempExisting, //groceryObj.total_unit_bought,
              new_qty: tempUpdated, //1,
              total_unit_bought: parseInt(productObj.total_unit_bought) + 1,
            }).then((res) => {
              if (res.data.data) {
                // setInputGrossaryData(res.data.data);
  
                if (
                  productItems.some(
                    (product) => product.sku === res.data.data[0].sku
                  )
                ) {
                  let xcnt = productItems.findIndex(
                    (product) => product.sku === res.data.data[0].sku
                  );
                  const updateItem = productItems.filter((elem, ind) => {
                    return ind !== xcnt;
                  });
                  updateItem.unshift(res.data.data[0]);
                  setProductItems(updateItem);
                } else {
                  setProductItems(res.data.data);
                  productItems.unshift(res.data.data[0]);
                  setProductItems(productItems);
                }
  
                document.getElementById("scanText").value = "";
                setShowLoader(false);
              }
            });
          }
        } else {
          if (isAddProduct == false) {
            await swal({
              title: "Scanned item is not in a list.",
              text: "If you would like to save this item please add Kitchen Supplies name",
              icon: "success",
              buttons: ["No", "Yes"],
            }).then(function (isConfirm) {
              if (isConfirm) {
                // onClick={handleClickEditScanned("body")},
                setIsNew(true);
                setOpenEditScanned(true);
                setScroll("body");
               
              }
  
              document.getElementById("scanText").value = "";
            });
          }
        }
      }
    }


  // Add Kitchen Supplies End

  //Edit Kitchen Supplies start
  const editKitchenSupplies = (KitchenSuppliesObj) => {
    setShowLoader(true);
    console.log(KitchenSuppliesObj);

    let id =
      KitchenSuppliesObj.category_id + "|||" + KitchenSuppliesObj.category_name;
    console.log(id);
    KitchenSuppliesObj.category_id = id;
    console.log(KitchenSuppliesObj);
    setFormValuesAddKitchenSupplies(KitchenSuppliesObj);
    setIsEditKitchenSupplies(true);
    setCartonWeight(KitchenSuppliesObj.single_carton_weight_unit);
    setWeightUnit(KitchenSuppliesObj.weight_per_item_unit);
    setSelectedType(KitchenSuppliesObj.category_type);
    if (KitchenSuppliesObj.item_image)
      setProfilePic(KitchenSuppliesObj.item_image);
    else setProfilePic([]);
    if (KitchenSuppliesObj.category_type == "Carton") {
      setTotalCartons(KitchenSuppliesObj.total_cartons)
      setOpenAddKitchen(true);
      setScroll("body");
    } else {
      setTotalUnitBought(KitchenSuppliesObj.total_unit_bought)
      setOpenSingleUnitPop(true);
      setScroll("body");
    }
    setNewQuantity(0);
    setShowLoader(false);
  };
  // Edit Kitchen Supplies End

  const cancelSubCategory = () => {
    setFormValuesAddKitchenSupplies(null);
    setIsEditKitchenSupplies(false);
    setCartonWeight(false);
    setWeightUnit(false);
    setTotalCartons(0);
    setTotalUnitBought(0);
    setUpdatedQuantity(0);
    setNewQuantity(0);
    closeAddKitchen();
  };

  // Status Kitchen Supplies Start
  const handleStatusChangeKitchenSupplies = (event, inventory_id) => {
    let inputdata = {
      inventory_id: inventory_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starcafe/updatekitchensubcategory", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getKitchenSuppliesList(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Kitchen Supplies end

  // Delete Kitchen Supplies Start
  const deleteKitchenSupplies = async (inventory_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Product Deleted!",
          text: "Product is successfully deleted!",
          icon: "success",
        }).then(function () {
          let inputdata = {
            inventory_id: inventory_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starcafe/updatekitchensubcategory", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getKitchenSuppliesList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Kitchen Supplies End

  // Sort By Start Kitchen Supplies
  const handleChangeSortByKitchenSupplies = () => {
    //alert("In ASC")
    setSortColumnName("product_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getKitchenSuppliesList(null);
  };
  // Sort By Kitchen Supplies End

  //Sort According to column Start
  function handleChangeSortByColumn(columnName) {
    setSortColumnName(columnName);
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    //console.log('columnName '+columnName+ ' selected '+selected +' sortBy '+sortBy);
    getKitchenSuppliesList(null);
  }
  //Sort According to column End

  const handlePageChange = (e, page) => {
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  // get Kitchen Supplies Data Start
  const getKitchenSuppliesList = (SearchStr) => {
    setShowLoader(true);
    if (!SearchStr) SearchStr = null;
    makeGetRequest(
      "starcafe/kitchensubcategorysearch/" +
      sortColumnName +
      "/" +
      sortBy +
      "/" +
      SearchStr +
      "/" +
      offsetvalue +
      "/" +
      filterType +
      "/" +
      itemsPerPage
    )
      .then((response) => {
        if (response.data) {
          console.log("Kitchen Data", response.data.data);
          setKitchenSuppliesData(response.data.data.res);
          setTotalItems(response.data.data.totalCount);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // get Kitchen Supplies Data End

  // Check Deduct quantity Start
  const [errorMessage, setErrorMessage] = useState("");
  const [deductData, setDeductData] = useState([]);

  const checkQuantity = (value, quantity, index) => {
    let Value = 0;
    const regex = /^[0-9\b]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (isDeductProduct == true) {
      if (regex.test(value) && quantity >= value) {
        document.getElementById(index).innerHTML = quantity - value;
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    } else {
      if (regex.test(value)) {
        document.getElementById(index).innerHTML =
          parseInt(quantity) + parseInt(value);
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", Value);
  };
  // Check Deduct Quantity End
  // Deduct Kitchen Supplies Start
  const deductKitchenSupplies = async (
    value,
    inventoryId,
    quantity,
    index,
    type
  ) => {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value) && quantity >= value) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to move given quantity to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);
            let inputData = {
              inventory_id: inventoryId,
            };
            if (type == "Carton")
              inputData.total_cartons = parseInt(
                document.getElementById(index).innerHTML
              );
            else
              inputData.total_unit_bought = parseInt(
                document.getElementById(index).innerHTML
              );

            console.log("input", inputData);
            makePostRequest("starcafe/updatekitchensubcategory", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity moved to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getKitchenSuppliesList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });
            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", value);
  };
  // Deduct Kitchen Supplies End
  // Add Kitchen Supplies Start
  async function addKitchen(value, inventoryId, quantity, index, type) {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";

    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value)) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to add given quantity of items to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);

            let inputData = {
              inventory_id: inventoryId,
            };
            if (type == "Carton")
              inputData.total_cartons = parseInt(
                document.getElementById(index).innerHTML
              );
            else
              inputData.total_unit_bought = parseInt(
                document.getElementById(index).innerHTML
              );
              inputData.new_quantity=parseInt(value);
              inputData.quantity_updated_dt=new Date();

            makePostRequest("starcafe/updatekitchensubcategory", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity added to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getKitchenSuppliesList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });

            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", value);
  }
  // Add Kitchen Supplies End

  // Carton Selection

  const [cartonWeight, setCartonWeight] = useState("");
  const handleCartonWeightUnit = (event, newAlignment) => {
    setCartonWeight(newAlignment);
  };

  const [weightUnit, setWeightUnit] = useState("");
  const handleWeightPerUnit = (event, newAlignment) => {
    setWeightUnit(newAlignment);
  };

  // End

  const [alignment, setAlignment] = useState("Breakfast");
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // Modal Main
  const [AddInventory, setOpenAddInventory] = useState(false);

  const handleClickOpenAddInventory = (scrollType) => () => {
    setOpenAddInventory(true);
    setScroll(scrollType);
  };

  React.useEffect(() => { }, [profilePic, upload]);

  useEffect(() => {
    getCategoryList(null);
    getKitchenSuppliesList(null);
    getCategoryDropDown();
    if (AddInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (DeductInventoryKitchen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (AddKitchen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [
    AddInventory,
    open,
    DeductInventoryKitchen,
    AddKitchen,
    selectedType,
    filterType,
    pageNo,
    upload,
  ]);
  // End

  // React.useEffect(() => {

  // },[upl])

  // Open Carton Or Single
  const [CartonOrSingle, setOpenCartonOrSingle] = React.useState(false);

  const handleClickCartonOrSingle = (scrollType) => () => {
    setOpenCartonOrSingle(true);
    setScroll(scrollType);
  };

  const closeCartonOrSingle = () => {
    setOpenCartonOrSingle(false);
  };
  // End

  // Open Carton Or Single
  const [SingleUnitPop, setOpenSingleUnitPop] = React.useState(false);

  const handleClickSingleUnitPop = (scrollType) => () => {
    setSelectedType("SingleUnit");
    getCategoryDropDown();
    setOpenSingleUnitPop(true);
    setScroll(scrollType);
  };

  const closeSingleUnitPop = () => {
    setOpenSingleUnitPop(false);
    setIsEditKitchenSupplies(false);
    setFormValuesAddKitchenSupplies(null);
    setProfilePic([]);
    setCartonWeight(0);
    setWeightUnit("");
    setPriceErrMsg("");
    setSelectedType("Carton");
    setOpenCartonOrSingle(false);
  };
  // End

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <div className="listingSearchAndFilterMain">
              <div className="prodSearchDiv">
                <input
                  type="text"
                  className="prodSearchInpt"
                  placeholder="Search"
                  onChange={(e) => getKitchenSuppliesList(e.target.value)}
                />
                <div>
                  <FilterListOutlinedIcon
                    className="filterIconSearch"
                    id="basic-button"
                    aria-controls={open1 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleFilter}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem value="Carton" onClick={() => handleFilter(null)}>
                      All
                    </MenuItem>
                    <MenuItem
                      value="Carton"
                      onClick={() => handleFilter("Carton")}
                    >
                      Carton
                    </MenuItem>
                    <MenuItem
                      value="SingleUnit"
                      onClick={() => handleFilter("SingleUnit")}
                    >
                      Single Unit
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              {/* <div className="prodFilter">
                <select className="prodSearchInpt">
                  <option>Filter</option>
                  <option>Select 1</option>
                  <option>Select 2</option>
                  <option>Select 3</option>
                </select>
              </div> */}
              <div className="sortingIcoOut">
                <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    handleChangeSortByKitchenSupplies();
                  }}
                >
                  <ImportExportIcon
                    className="visitorFilterIco"
                    aria-haspopup="true"
                  />
                </ToggleButton>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={9} className="addImpotBtOuter">
            <Button
              className="pageTopMainBt"
              onClick={handleClickScanPop("body")}
            >
              Add Kitchen Inventory
            </Button>

            <Button className="pageTopMainBt" onClick={handleClickOpen("body")}>
              <AddIcon /> Manage Category
            </Button>
            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenDeductInventoryKitchen("body", false)}
            >
              <AddIcon /> Quick add
            </Button>
            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenDeductInventoryKitchen("body", true)}
            >
              <RemoveIcon /> Move to Kitchen
            </Button>
            <Button
              className="pageTopMainBt"
              // onClick={handleClickOpen2("body")}
              // onClick={handleClickOpenAddKitchen("body")}
              onClick={handleClickCartonOrSingle("body")}
            >
              <AddIcon /> Add Inventory
            </Button>
            <Tooltip title="Import" arrow placement="top">
              <Button
                className="pageTopMainBt forImportB"
                component="label"
                variant="contained"
              >
                <UploadIcon />
                <VisuallyHiddenInput type="file" />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="serviceistOuter">
              {kitchenSuppliesData.map((KitchenSuppliesObj) => {
                return (
                  <div
                    className="mainListDiv activeLowStockLimit"
                    key={KitchenSuppliesObj.inventory_id}
                  >
                    {KitchenSuppliesObj.category_type == "Carton" ? (
                      <div className="flagSt">Carton</div>
                    ) : (
                      <div className="flagSt">Single Unit</div>
                    )}
                    <div className="mainListCont">
                      <h1
                        onClick={() =>
                          navigate(
                            "/ProductDetails/" + KitchenSuppliesObj.inventory_id
                          )
                        }
                      >
                        {KitchenSuppliesObj.product_name}
                      </h1>
                      <p>ID #{KitchenSuppliesObj.inventory_id}</p>
                      <span className="dateStNew">
                        Added on{" "}
                        {moment(KitchenSuppliesObj.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Category</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("category_name")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {KitchenSuppliesObj.category_name}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Low Stock Limit</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("low_stock_limit")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {KitchenSuppliesObj.low_stock_limit}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Weight Per Unit</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("wt_per_item")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {KitchenSuppliesObj.wt_per_item}{" "}
                        {KitchenSuppliesObj.weight_per_item_unit}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Brand</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("brand_name")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {KitchenSuppliesObj.brand_name}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Quantity</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("total_cartons")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      {KitchenSuppliesObj.category_type == "SingleUnit" ? (
                        <span className="mainListSubTx">
                          {KitchenSuppliesObj.total_unit_bought}
                        </span>
                      ) : (
                        <span className="mainListSubTx">
                          {KitchenSuppliesObj.total_cartons}
                        </span>
                      )}
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Price Per Unit in INR (₹)</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("price_per_item")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        ₹ {KitchenSuppliesObj.price_per_item}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Total Price in INR (₹)</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("price_per_carton")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      {KitchenSuppliesObj.category_type == "SingleUnit" ? (
                        <span className="mainListSubTx">
                          ₹ {KitchenSuppliesObj.price_per_item}
                        </span>
                      ) : (
                        <span className="mainListSubTx">
                          ₹ {KitchenSuppliesObj.price_per_carton}
                        </span>
                      )}
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="actionBtnOut">
                        <Tooltip title="Edit" arrow placement="top">
                          <IconButton
                            onClick={() =>
                              editKitchenSupplies(KitchenSuppliesObj)
                            }
                          >
                            <img
                              src={editIco}
                            // onClick={handleClickOpenAddKitchen("body")}
                            />
                          </IconButton>
                        </Tooltip>

                        <Switch
                          {...label}
                          checked={KitchenSuppliesObj.status}
                          className="swicthMain"
                          onChange={(event) =>
                            handleStatusChangeKitchenSupplies(
                              event,
                              KitchenSuppliesObj.inventory_id
                            )
                          }
                        />

                        <Tooltip title="Delete" arrow placement="top">
                          <IconButton
                            onClick={() =>
                              deleteKitchenSupplies(
                                KitchenSuppliesObj.inventory_id
                              )
                            }
                          >
                            <img src={deletIco} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={pageNo}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </div>

      {/* Add Category */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Manage Category{" "}
            <CloseIcon className="modalCloseBt" onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Formik
                        initialValues={formValues || initialValuesAddCategory}
                        validationSchema={validationSchemaAddCategory}
                        onSubmit={addOrUpdateCategory}
                        enableReinitialize
                      >
                        {(formik) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={12}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Category Name"
                                  placeholder="Category Name"
                                  labelClass="pageLabel"
                                  name="category_name"
                                  isRequired="true"
                                  className="prodSearchInpt"
                                />
                                <span style={{ color: "red", fontSize: 14 }}>
                                  {duplicateErrMsg}
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "right" }}
                              >
                                {isEditCategory ? (
                                  <Button
                                    className="pageTopMainBt pageTopMainCancelBt"
                                    onClick={() => cancelCategory()}
                                  >
                                    <>Cancel</>
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                <Button
                                  className="pageTopMainBt"
                                  //onClick={handleClose}
                                  type="submit"
                                >
                                  {isEditCategory ? (
                                    <>Update Category</>
                                  ) : (
                                    <>Add Category</>
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getCategoryList(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortByCategory();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain verticleScroll">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>
                                Category Name{" "}
                                <em style={{ color: "#878787", fontSize: 12 }}>
                                  (Total {categoryData.length} Categories)
                                </em>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {categoryData.map((data) => (
                            <tbody key={data.category_id}>
                              <tr>
                                <td>{data.category_name}</td>
                                <td>
                                  <div className="actionBtnOut">
                                    <Tooltip title="Edit" arrow placement="top">
                                      <IconButton
                                        // onClick={() => navigate("/")}
                                        onClick={() => editCategory(data)}
                                      >
                                        <img src={editIco} />
                                      </IconButton>
                                    </Tooltip>

                                    <Switch
                                      {...label}
                                      checked={data.status}
                                      className="swicthMain"
                                      onChange={(event) =>
                                        handleStatusChangeCategory(
                                          event,
                                          data.category_id
                                        )
                                      }
                                    />

                                    <Tooltip
                                      title="Delete"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton
                                        onClick={() =>
                                          deleteCategory(data.category_id)
                                        }
                                      >
                                        <img src={deletIco} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Kitchen Supplies Carton*/}
      <div>
        <Dialog
          open={AddKitchen}
          // onClose={closeAddKitchen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditKitchenSupplies ? (
              <>Edit Kitchen Supplies</>
            ) : (
              <>Add Kitchen Supplies (Carton)</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeAddKitchen} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv" id="cartonAndUnitId">
                  {selectedType == "Carton" ? (
                    <Formik
                      initialValues={
                        formValuesAddKitchenSupplies ||
                        initialValuesKitchenSupplies
                      }
                      validationSchema={validationSchemaKitchenSupplies}
                      onSubmit={addKitchenSupplies}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <div>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Item Name"
                                  placeholder="Item Name"
                                  labelClass="pageLabel"
                                  name="product_name"
                                  isRequired="true"
                                  className="prodSearchInpt"
                                />
                                <span className="errorSt">
                                  {duplicateErrMsg}
                                </span>
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Low Stock Limit"
                                  placeholder="Stock Limit"
                                  labelClass="pageLabel"
                                  name="low_stock_limit"
                                  isRequired="true"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="select"
                                  type="selectcompany"
                                  label="Product Categories"
                                  labelClass="pageLabel"
                                  name="category_id"
                                  isRequired="true"
                                  options={categoriesDropDown}
                                  className="prodSearchInpt"
                                />
                                <span className="errorSt">
                                  {categoryListErrMsg}
                                </span>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={12}>
                                    <div className="addRemoveImagesBox">
                                      {profilePic ? (
                                        <>
                                          {profilePic.map((img, index) => (
                                            <div
                                              className="imageAddedBox"
                                              key={index}
                                            >
                                              <img src={img} />
                                              <div className="cloIm">
                                                <CloseIcon
                                                  onClick={() =>
                                                    deleteImg(index)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </>
                                      ) : null}
                                      {profilePic.length < 4 ? (
                                        <div className="uploadImBox">
                                          <h1 className="">Upload Image</h1>
                                          <Button
                                            className="uploadImBt"
                                            component="label"
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                          >
                                            Upload
                                            <VisuallyHiddenInput
                                              type="file"
                                              onChange={(event) =>
                                                uploadProfilePic(event)
                                              }
                                            />
                                          </Button>
                                          <span>or drag file in here</span>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div style={{ color: "red" }}>
                                      {profileErrMsg}
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Brand Name"
                                      placeholder="Brand Name"
                                      labelClass="pageLabel"
                                      name="brand_name"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  
                                    <Grid item xs={12} md={4}>
                                      <label class="reggistraLabel">Total Number Of Cartons <span className="requiredStar">*</span></label>
                                      <input
                                        type="text"
                                        placeholder="Number Of Cartons"
                                        value={total_cartons}
                                        className="prodSearchInpt"
                                        isRequired="true"
                                        disabled
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                      <label class="reggistraLabel">New Cartons <span className="requiredStar">*</span></label>
                                      <input
                                        type="text"
                                        placeholder="New Cartons"
                                        value={new_quantity}
                                        onChange={(e) => setNewQuantity(e.target.value)}
                                        onBlur={(e) => handleChangeNewQuantity(e.target.value)}
                                        className="prodSearchInpt"
                                        isRequired="true"
                                      />
                                    </Grid>
                                 
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Packs Per Carton"
                                      placeholder="Packs Per Carton"
                                      labelClass="pageLabel"
                                      name="item_per_carton"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Carton Weight"
                                      placeholder="Carton Weight"
                                      labelClass="pageLabel"
                                      name="single_carton_weight"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <label className="pageLabel">
                                      Select Carton Weight Unit
                                      <span className="requiredStar">*</span>
                                    </label>
                                    <div className="unitDivMain uniTopBotttomSpace">
                                      <div className="detailsTableFilterSec">
                                        <ToggleButtonGroup
                                          value={cartonWeight}
                                          exclusive
                                          onChange={handleCartonWeightUnit}
                                          className="detailsTableFilterSecActionInr"
                                        >
                                          <ToggleButton
                                            value="Kilograms"
                                            className="filterBtn"
                                          >
                                            Kilograms
                                          </ToggleButton>
                                          <ToggleButton
                                            value="Tonne"
                                            className="filterBtn"
                                          >
                                            Tonne
                                          </ToggleButton>
                                          <ToggleButton
                                            value="Liter"
                                            className="filterBtn"
                                          >
                                            Liter
                                          </ToggleButton>
                                        </ToggleButtonGroup>
                                      </div>
                                      <span className="errorSt">
                                        {cartonWtUnitErrMsg}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Weight Per Unit"
                                      placeholder="Weight Per Unit"
                                      labelClass="pageLabel"
                                      name="wt_per_item"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <label className="pageLabel">
                                      Select Weight Per Unit
                                      <span className="requiredStar">*</span>
                                    </label>
                                    <div className="unitDivMain uniTopBotttomSpace">
                                      <div className="detailsTableFilterSec">
                                        <ToggleButtonGroup
                                          value={weightUnit}
                                          exclusive
                                          onChange={handleWeightPerUnit}
                                          className="detailsTableFilterSecActionInr"
                                        >
                                          <ToggleButton
                                            value="Kilograms"
                                            className="filterBtn"
                                          >
                                            Kilograms
                                          </ToggleButton>
                                          <ToggleButton
                                            value="Miligrams"
                                            className="filterBtn"
                                          >
                                            Miligrams
                                          </ToggleButton>
                                          <ToggleButton
                                            value="Gram"
                                            className="filterBtn"
                                          >
                                            Gram
                                          </ToggleButton>
                                          <ToggleButton
                                            value="Liter"
                                            className="filterBtn"
                                          >
                                            Liters
                                          </ToggleButton>
                                        </ToggleButtonGroup>
                                      </div>
                                      <span className="errorSt">
                                        {wtUnitErrMsg}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Price Per Carton in INR (₹)"
                                      placeholder="Price Per Carton in INR (₹)"
                                      labelClass="pageLabel"
                                      name="price_per_carton"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Price Per Unit in INR (₹)"
                                      placeholder="Price Per Unit in INR (₹)"
                                      labelClass="pageLabel"
                                      name="price_per_item"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="date"
                                      type="date"
                                      label="Enter Expiry Date"
                                      placeholder="Enter Expiry Date"
                                      labelClass="pageLabel"
                                      name="expiry_date"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Enter Origin"
                                      placeholder="Country Origin"
                                      labelClass="pageLabel"
                                      name="country_of_origin"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="date"
                                  label="Select Date"
                                  placeholder="Select Date"
                                  labelClass="pageLabel"
                                  name="selected_date"
                                  className="prodSearchInpt"
                                />
                              </Grid> */}

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className="lineBtns"
                                  >
                                    {isEditKitchenSupplies ? (
                                      <Button
                                        className="cancelEditBtn"
                                        onClick={() => cancelSubCategory()}
                                      >
                                        <>Cancel</>
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                    <Button
                                      className="modalBtSmallWidth"
                                      type="submit"
                                    >
                                      {isEditKitchenSupplies ? (
                                        <>Update Kitchen Supplies</>
                                      ) : (
                                        <>Add Kitchen Supplies</>
                                      )}
                                    </Button>
                                  </Grid>
                                  {tempKitchenSuppliesList.length > 0 ? (
                                    <>
                                      <Grid item xs={12} md={12}>
                                        <div className="probDetBtOut tablTypsOuterMain">
                                          <div className="tablTypsOuter">
                                            <Button className="probDetBt">
                                              <label>Product Name</label>
                                              <b>Tur Daal</b>
                                            </Button>
                                            <Button className="probDetBt">
                                              <label>Type</label>
                                              <b>Carton</b>
                                            </Button>
                                          </div>

                                          <Button className="pageTopMainBt blueBt">
                                            <AddIcon /> Add New
                                          </Button>
                                        </div>
                                      </Grid>

                                      <Grid item xs={12} md={12}>
                                        <div className="productDetails groceryTableMain">
                                          <table width="100%">
                                            <thead>
                                              <tr>
                                                <th>Brand</th>
                                                <th>Total Carton Bought</th>
                                                <th>Weight Per Carton</th>
                                                <th>Weight Per Unit</th>
                                                <th>Date</th>
                                                <th>Expiry Date</th>
                                                <th>Price Per Carton in INR (₹)</th>
                                                <th>Price Per Unit in INR (₹)</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {tempKitchenSuppliesList.map(
                                                (List) => (
                                                  <tr>
                                                    <td>{List.brand_name}</td>
                                                    <td>
                                                      {List.total_cartons}
                                                    </td>
                                                    <td>
                                                      {
                                                        List.single_carton_weight
                                                      }{" "}
                                                      {
                                                        List.single_carton_weight_unit
                                                      }
                                                    </td>
                                                    <td>
                                                      {List.wt_per_item}{" "}
                                                      {
                                                        List.single_carton_weight_unit
                                                      }
                                                    </td>
                                                    <td>
                                                      {moment(
                                                        List.created_at
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                      {moment(
                                                        List.expiry_date
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                      ₹ {List.price_per_carton}
                                                    </td>
                                                    <td>
                                                      ₹ {List.price_per_item}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </Grid>
                                    </>
                                  ) : null}
                                </Grid>
                              </Grid>

                              {/*For Append */}
                              {[...Array(count)].map((_, i) => (
                                <AddedElement key={i} />
                              ))}
                              {/* End */}
                            </Grid>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Move to Kitchen */}
      <div>
        <Dialog
          open={DeductInventoryKitchen}
          // onClose={closeDeductInventoryKitchen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isDeductProduct ? <>Move to Kitchen</> : <>Add Items to Kitchen</>}
            <CloseIcon
              className="modalCloseBt"
              onClick={closeDeductInventoryKitchen}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) =>
                              getKitchenSuppliesList(e.target.value)
                            }
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortByKitchenSupplies();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Brand</th>
                              <th>Quantity In Stock</th>
                              {/* <th>Purchase Price in INR (₹)</th> */}
                              {isDeductProduct ? (
                                <th>Quantity To Deduct</th>
                              ) : (
                                <th>Quantity To Add</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {kitchenSuppliesData.map((kitchenObj, index) => {
                              return (
                                <tr key={kitchenObj.inventory_id}>
                                  <td>{kitchenObj.product_name}</td>
                                  <td>{kitchenObj.brand_name}</td>
                                  <td id={index}>
                                    {kitchenObj.category_type == "Carton" ? (
                                      <>{kitchenObj.total_cartons}</>
                                    ) : (
                                      <>{kitchenObj.total_unit_bought}</>
                                    )}
                                  </td>
                                  {/* {kitchenObj.category_type == "Carton" ? (
                                    <td>₹ {kitchenObj.price_per_carton}</td>
                                  ) : (
                                    <td>₹ {kitchenObj.price_per_item}</td>
                                  )} */}
                                  <td>
                                    <input
                                      type="text"
                                      className="qutyInput"
                                      placeholder="Quantity"
                                      onChange={(e) =>
                                        checkQuantity(
                                          e.target.value,
                                          kitchenObj.category_type == "Carton"
                                            ? kitchenObj.total_cartons
                                            : kitchenObj.total_unit_bought,
                                          index
                                        )
                                      }
                                      onBlur={(e) =>
                                        isDeductProduct
                                          ? deductKitchenSupplies(
                                            e.target.value,
                                            kitchenObj.inventory_id,
                                            kitchenObj.category_type ==
                                              "Carton"
                                              ? kitchenObj.total_cartons
                                              : kitchenObj.total_unit_bought,
                                            index,
                                            kitchenObj.category_type
                                          )
                                          : addKitchen(
                                            e.target.value,
                                            kitchenObj.inventory_id,
                                            kitchenObj.category_type ==
                                              "Carton"
                                              ? kitchenObj.total_cartons
                                              : kitchenObj.total_unit_bought,
                                            index,
                                            kitchenObj.category_type
                                          )
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      <span id={`span` + index}> </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Move Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add to Kitchen */}
      <div>
        <Dialog
          open={AddToKitchen}
          // onClose={closeAddToKitchen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add to Kitchen
            <CloseIcon className="modalCloseBt" onClick={closeAddToKitchen} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) =>
                              getKitchenSuppliesList(e.target.value)
                            }
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortByKitchenSupplies();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Brand</th>
                              <th>Quantity In Stock</th>
                              <th>Purchase Price in INR (₹)</th>
                              <th>Quantity To Deduct</th>
                            </tr>
                          </thead>
                          <tbody>
                            {kitchenSuppliesData.map((kitchenObj) => {
                              return (
                                <tr>
                                  <td>{kitchenObj.product_name}</td>
                                  <td>{kitchenObj.brand_name}</td>
                                  <td>{kitchenObj.total_cartons}</td>
                                  <td>₹ {kitchenObj.price_per_carton}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="qutyInput"
                                      placeholder="Quantity"
                                      onChange={(e) =>
                                        checkQuantity(
                                          e,
                                          kitchenObj.total_cartons
                                        )
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      {errorMessage}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid> */}
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Add Item</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Open Carton Or Single */}
      <div>
        <Dialog
          open={CartonOrSingle}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Select Carton Or Single Unit
            <CloseIcon className="modalCloseBt" onClick={closeCartonOrSingle} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="cartonOrSinleSelect">
                        <div
                          className="typeSelectionDiv"
                          onClick={handleClickOpenAddKitchen("body")}
                        >
                          <div className="typeSelectionImg">
                            <img src={deliveryTruck} />
                          </div>
                          <h1>Carton</h1>
                        </div>

                        <div
                          className="typeSelectionDiv"
                          onClick={handleClickSingleUnitPop("body")}
                        >
                          <div className="typeSelectionImg">
                            <img src={packageIm} />
                          </div>
                          <h1>Single Unit</h1>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* For Single Unit Only */}
      <div>
        <Dialog
          open={SingleUnitPop}
          // onClose={closeSingleUnitPop}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditKitchenSupplies ? (
              <>Edit Kitchen Supplies</>
            ) : (
              <>Add Kitchen Supplies (Single Unit)</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeSingleUnitPop} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={
                      formValuesAddKitchenSupplies ||
                      initialValuesKitchenSuppliesSingleUnit
                    }
                    validationSchema={validationSchemaKitchenSuppliesSingleUnit}
                    onSubmit={addKitchenSupplies}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <div>
                          <Grid container spacing={3}>
                            {/* <Grid item xs={12} md={12}>
                              <h4 className="selectedTypeTx">
                                <span>Selected Single Unit</span>
                              </h4>
                            </Grid> */}
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Item Name"
                                placeholder="Item Name"
                                labelClass="pageLabel"
                                name="product_name"
                                className="prodSearchInpt"
                              />
                              <span className="errorSt">{duplicateErrMsg}</span>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Low Stock Limit"
                                placeholder="Stock Limit"
                                labelClass="pageLabel"
                                name="low_stock_limit"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="select"
                                type="selectcompany"
                                label="Product Categories"
                                labelClass="pageLabel"
                                name="category_id"
                                isRequired="true"
                                options={categoriesDropDown}
                                className="prodSearchInpt"
                              />
                              <span className="errorSt">
                                {categoryListErrMsg}
                              </span>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <div className="addRemoveImagesBox">
                                    {profilePic ? (
                                      <>
                                        {profilePic.map((img, index) => (
                                          <div
                                            className="imageAddedBox"
                                            key={index}
                                          >
                                            <img src={img} />
                                            <div className="cloIm">
                                              <CloseIcon
                                                onClick={() => deleteImg(index)}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    ) : null}
                                    {profilePic.length < 4 ? (
                                      <div className="uploadImBox">
                                        <h1 className="">Upload Image</h1>
                                        <Button
                                          className="uploadImBt"
                                          component="label"
                                          variant="contained"
                                          startIcon={<CloudUploadIcon />}
                                        >
                                          Upload
                                          <VisuallyHiddenInput
                                            type="file"
                                            onChange={(event) =>
                                              uploadProfilePic(event)
                                            }
                                          />
                                        </Button>
                                        {/* <span>or drag file in here</span> */}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Brand Name"
                                    placeholder="Brand Name"
                                    labelClass="pageLabel"
                                    name="brand_name"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <label class="reggistraLabel">Total Units Bought<span className="requiredStar">*</span></label>
                                  <input
                                    type="text"
                                    placeholder="Total Units Bought"
                                    value={total_unit_bought}
                                    className="prodSearchInpt"
                                    isRequired="true"
                                    disabled
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <label class="reggistraLabel">New Units <span className="requiredStar">*</span></label>
                                  <input
                                    type="text"
                                    placeholder="New Units"
                                    value={new_quantity}
                                    onChange={(e) => setNewQuantity(e.target.value)}
                                    onBlur={(e) => handleChangeNewQuantity(e.target.value)}
                                    className="prodSearchInpt"
                                    isRequired="true"
                                  />
                                </Grid>

                                <Grid item xs={12} md={5}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Weight Per Unit"
                                    placeholder="Weight"
                                    labelClass="pageLabel"
                                    name="wt_per_item"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={7}>
                                  <label className="pageLabel">
                                    Select Weight Per Unit
                                  </label>
                                  <div className="unitDivMain uniTopBotttomSpace">
                                    <div className="detailsTableFilterSec">
                                      <ToggleButtonGroup
                                        value={weightUnit}
                                        exclusive
                                        onChange={handleWeightPerUnit}
                                        className="detailsTableFilterSecActionInr"
                                      >
                                        <ToggleButton
                                          value="Kilograms"
                                          className="filterBtn"
                                        >
                                          Kilograms
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Gram"
                                          className="filterBtn"
                                        >
                                          Gram
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Miligram"
                                          className="filterBtn"
                                        >
                                          Miligram
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Liter"
                                          className="filterBtn"
                                        >
                                          Liter
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Mililiter"
                                          className="filterBtn"
                                        >
                                          Mililiter
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Price Per Unit in INR (₹)"
                                    placeholder="Price Per Unit in INR (₹)"
                                    labelClass="pageLabel"
                                    name="price_per_item"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="date"
                                    type="date"
                                    label="Enter Expiry Date"
                                    placeholder="Enter Expiry Date"
                                    labelClass="pageLabel"
                                    name="expiry_date"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Origin"
                                    placeholder="Country Origin"
                                    labelClass="pageLabel"
                                    name="country_of_origin"
                                    className="prodSearchInpt"
                                  />
                                </Grid>

                                <Grid item xs={12} md={12} className="lineBtns">
                                  <Button
                                    className="cancelEditBtn"
                                    onClick={() => closeSingleUnitPop()}
                                  >
                                    <>Cancel</>
                                  </Button>

                                  <Button
                                    type="submit"
                                    className="modalBtSmallWidth"
                                  >
                                    {isEditKitchenSupplies ? (
                                      <>Update Kitchen Supplies</>
                                    ) : (
                                      <>Save Kitchen Supplies</>
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Kitchen Inventory */}
      <div>
        <Dialog
          open={ScanPop}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Kitchen Inventory
            <CloseIcon className="modalCloseBt" onClick={closeScanPop} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            id="scanText"
                            className="prodSearchInpt"
                            placeholder="Scan SKU"
                            onKeyUp={handleKeyUp}
                            onChange={handleKeyPress}

                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr >
                              <th>SKU No.</th>
                              <th>Product Name</th>
                              <th>Existing Quantity</th>
                              <th>New Quantity</th>
                              <th>Total Quantity</th>
                              <th>Manage</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                             productItems.map((elem, index) => (
                                <tr key={index}
                                className={index == 0 ? "newAddedTr" : ""}>
                                  <td>{elem.sku}</td>
                                  <td>{elem.product_name}</td>
                                  <td>{elem.current_qty}</td>
                                  <td><input
                                    type="text"
                                    id="skuqty"
                                    className="qutyInput"
                                    value={elem.new_qty}
                                    onChange={(event) => handleqrqty(event, index)}
                                  /></td>
                                  <td>{elem.total_unit_bought}</td>
                                  <td className="manageActIcons">
                                    <TaskAltIcon className="managMarkCheck" onClick={(event) => updateQty(elem.sku, elem.current_qty, elem.new_qty)} />

                                  </td>
                                </tr>


                              ))

                            }


                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Add Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>


        {/* Edit Scanned */}
        <div>
        <Dialog
          open={EditScanned}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Kitchen Supplies
            <CloseIcon className="modalCloseBt" onClick={closeEditScanned} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <label className="pageLabel">
                            Enter Product Name
                          </label>
                          <input
                            type="text"
                            className="qutyInput"
                            value={productName}
                            onChange={(event) =>
                              setProductName(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <span onClick={closeEditScanned}>
                        <h5
                          className="moreInoAdd"
                          onClick={handleClickCartonOrSingle("body")}
                        >
                          Would you like to add all information?
                        </h5>
                      </span>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button
                        className="modalBtSmallWidth"
                        onClick={() => updateQty()}
                      >
                        Ok
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default KitchenSuppliesTab;
