import React, { useEffect } from "react";
import BottomTabsPage from "../../MobileScreens/componentsMobile/BottomTabsPage/BottomTabsPage";
import starMarket from "../assets/images/starMarket.svg";
import Sports from "../assets/images/Sports.svg";
import { Button, Grid } from "@mui/material";
import barberShop from "../assets/images/barberShop.svg";
import starCafe from "../assets/images/starCafe.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import dashLogo from "../assets/images/dashLogo.svg";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import cup from "../assets/images/cup.svg";
import market from "../assets/images/market.svg";
import { useNavigate } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import IconButton from "@mui/material/IconButton";
import { makeGetRequest, makePostRequest } from "../../utils/utils";
import MobileHeader from "../componentsMobile/MobileHeader/MobileHeader";
import moment from "moment";
import noData from "../../MobileScreens/assets/images/noData.webp";
import scanQR from "../../MobileScreens/assets/images/scanQR.svg";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

import swal from "sweetalert";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ActiveOrders() {
  const navigate = useNavigate();

  const [orderList, setOrderList] = React.useState([]);
  const [orderListMarket, setOrderListMarket] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [ordercancellation, setOrdercancellation] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getEmployeeActiveOrder() {
    var userid = parseInt(localStorage.getItem("userId"));
    await makeGetRequest(
      "starcafe/getorderemployee/null/Active/" + userid + "/0/null/null"
    )
      .then((response) => {
        if (response.data.data.groupedData) {
          console.log("Groupeddata");
          console.log(response.data.data.groupedData);
          const sortedOrders = processOrders(response.data.data.groupedData);
          setOrderList(sortedOrders);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        setOrderList([]);
      });
  }

  const processOrders = (orders) => {
    return (
      orders
        .map((order) => {
          // Add 1 day to each orderdate
          const nextDate = moment(order.orderdate).add(1, "days").toISOString();
          return { ...order, nextOrderDate: nextDate };
        })
        // Sort by orderdate in ascending order
        .sort((a, b) => new Date(a.orderdate) - new Date(b.orderdate))
    );
  };

  async function getEmployeeActiveOrderMarket() {
    var userid = parseInt(localStorage.getItem("userId"));
    await makeGetRequest(
      "starmarket/getorderemployee/null/Active/" + userid + "/0"
    )
      .then((response) => {
        if (response.data.data) {
          setOrderListMarket(response.data.data);
        } else {
          setOrderListMarket([]);
        }
      })
      .catch((err) => {
        setOrderListMarket([]);
      });
  }

  const sampleOrder = {
    order_id: 0,
    orderstatus: "",
    is_deleted: false,
  };

  async function deductOrder(data) {
    // try {
    //   const willUpdate = await swal({
    //     title: "Are you sure?",
    //     text: "Do you really want to Cancell the order?",
    //     icon: "warning",
    //     buttons: ["No", "Yes"],
    //   });
    //   if (willUpdate) {
    //     sampleOrder.order_id = parseInt(data.order_id);
    //     sampleOrder.orderstatus = "Cancelled";
    //     sampleOrder.is_deleted = true;

    //     makePostRequest("starcafe/updateorder", sampleOrder)
    //       .then((response) => {
    //         if (response.data) {
    //           localStorage.setItem("orderid", response.data.data.order_id);
    //           makePostRequest(
    //             "starcafe/updatecancelledsubcategorycount",
    //             data.orderdetails
    //           )
    //             .then((response) => {
    //               if (response.data) {
    //                 setOrdercancellation(true);
    //                 // let userName = localStorage.getItem("username");
    //                 // let id = localStorage.getItem("userId");
    //                 // notificationobj.resident_id = id;
    //                 // notificationobj.title = "New Order Placed";
    //                 // notificationobj.message =
    //                 //   userName +
    //                 //   " has Cancelled a  order. Order id is" +
    //                 //   response.data.data.order_id;
    //                 // makePostRequest("employee/notificationtoapp", notificationobj)
    //                 //   .then((response) => {
    //                 //     console.log("Notification Placed");
    //                 //   })
    //                 //   .catch((err) => {
    //                 //     console.log(err);
    //                 //   });
    //               }
    //             })
    //             .catch((err) => {
    //               console.log(err);
    //             });
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // } catch {
    //   console.log("error");
    // }
    swal({
      title: "Contact Admin",
      text: "Please contact admin to cancel your order",
      icon: "warning",
      timer:5000
      // buttons: ["No", "Yes"],
    })
  }

  async function deductOrderMarket(data) {

    try {

      const willUpdate = await swal({
        title: "Are you sure?",
        text: "Do you really want to Cancell the order?",
        icon: "warning",
        buttons: ["No", "Yes"],
      });
      if (willUpdate) {
        sampleOrder.order_id = parseInt(data.order_id);
        sampleOrder.orderstatus = "Cancelled";
        sampleOrder.is_deleted = true;

        console.log(sampleOrder);

        makePostRequest("starmarket/updateorder", sampleOrder)
          .then((response) => {
            if (response.data) {
              localStorage.setItem("orderid", response.data.data.order_id);
              makePostRequest(
                "starmarket/updatecancelledproductcount",
                data.orderdetails
              )
                .then((response) => {
                  if (response.data) {
                    setOrdercancellation(true);
                    // let userName = localStorage.getItem("username");
                    // let id = localStorage.getItem("userId");
                    // notificationobj.resident_id = id;
                    // notificationobj.title = "New Order Placed";
                    // notificationobj.message =
                    //   userName +
                    //   " has Cancelled a  order. Order id is" +
                    //   response.data.data.order_id;
                    // makePostRequest("employee/notificationtoapp", notificationobj)
                    //   .then((response) => {
                    //     console.log("Notification Placed");
                    //   })
                    //   .catch((err) => {
                    //     console.log(err);
                    //   });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch {
      console.log("error");
    }
  }

  useEffect(() => {
    getEmployeeActiveOrder();
    getEmployeeActiveOrderMarket();
    if (ordercancellation == true) {
      setOrdercancellation(false);
    }
  }, [ordercancellation]);

  return (
    <div className="mobileCssMain">
      <div className="dashMain forBottomTabOnly">
        <MobileHeader />

        {/* top tabs code */}
        <div className="tabMain contentTopSpacing activeOrdersTab">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={
                    <div className="tabsImgOuter">
                      <img src={starMarket} />
                    </div>
                  }
                  label="Star Cafe"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={
                    <div className="tabsImgOuter">
                      <img src={starCafe} />
                    </div>
                  }
                  label="Star Market"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={
                    <div className="tabsImgOuter">
                      <img src={barberShop} />
                    </div>
                  }
                  label="Barber Shop"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={
                    <div className="tabsImgOuter">
                      <img src={Sports} />
                    </div>
                  }
                  label="Sports"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={1}>
              <>
                {orderListMarket.length > 0 ? (
                  <div className="activeOrdersList">
                    <div className="orderMenuList">
                      {orderListMarket.map((item, index) => (
                        <div
                          key={index}
                          className="orderNotiDiv specialMenu orderNotiDivNewAdOut"
                        >
                          <div
                            className="orderNotiDivNewAd"
                            onClick={() =>
                              navigate("/ActiveOrderDetailsMarket", {
                                state: { id: item.order_id },
                              })
                            }
                          >
                            <div className="orderListLeftData">
                              <div className="ordIcoDiv orderListImgOuter">
                                <img src={cup} />
                              </div>
                              <div className="orderListOrderIdData">
                                <h3>Order #{item.order_id}</h3>
                                <p>Star Market</p>
                                <div className="starPrice">
                                  <StarIcon className="starIcon" />
                                  <h5> {item.totalstarspend} </h5>
                                </div>
                              </div>
                            </div>
                            <div className="orderListRightData">
                              <IconButton className="TouchableIconBtn TouchableORBtn ">
                                <Button className="loginBt QRBtn">
                                  QR Code
                                </Button>
                              </IconButton>
                              <p className="lightTextcolor">
                                {moment(item.created_at)
                                  .utc()
                                  .format("YYYY-MM-DD")}
                              </p>
                            </div>
                          </div>

                          <PlaylistRemoveIcon
                            onClick={() => deductOrderMarket(item)}
                            style={{ color: "#c82d33" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="nodatDiv">
                    <img src={noData} />
                  </div>
                )}
              </>
            </CustomTabPanel>
            {/* Active order tab */}

            {/* starcafe */}
            <CustomTabPanel value={value} index={0}>
              <>
                {orderList.length > 0 ? (
                  <div className="activeOrdersList">
                    <div className="orderMenuList">
                      {orderList.map((item, index) => (
                        <div className="orderNotiDiv newActiveOrderLayout">
                          <p className="cateStMain">{item.ordertype}</p>
                          <div
                            key={index}
                            className={
                              item.ordertype === "Lunch"
                                ? "orderNotiDiv specialMenu mobilelunchOrderColor newActiveOrderLayoutInside"
                                : item.ordertype === "Dinner"
                                  ? "orderNotiDiv specialMenu mobiledinnerOrderColor newActiveOrderLayoutInside"
                                  : item.ordertype === "Breakfast"
                                    ? "orderNotiDiv specialMenu mobilebreakfastOrderColor newActiveOrderLayoutInside"
                                    : "orderNotiDiv specialMenu newActiveOrderLayoutInside"
                            }
                            onClick={() =>
                              navigate("/ActiveOrderDetails", {
                                state: { id: item.order_id },
                              })
                            }
                          >
                            <div className="orderListLeftData">
                              <div className="ordIcoDiv orderListImgOuter cafeCupIcon">
                                <img src={cup} />
                              </div>
                              <div className="orderListOrderIdData">
                                <h3>Order #{item.order_id}</h3>
                                <div className="starPrice">
                                  <StarIcon className="starIcon" />
                                  <h5> {item.totalstarspend} </h5>
                                </div>
                              </div>
                            </div>
                            <div className="orderListRightData">
                              <div className="orderListRightData">
                                {/* <p>{item.orderstatus}</p> */}
                              </div>
                              {/* <Button className="loginBt QRBtn">QR Code</Button> */}
                              <div className="scanQROuter">
                                <img src={scanQR} />
                              </div>
                            </div>
                          </div>
                          <div className="orderPickedDate">
                            <div className="orderPickedDateLeft">
                              <p className="lightTextcolor">Pick Up Date</p>
                              <h3>
                                {moment(item.orderdate).format("YYYY-MM-DD")}
                              </h3>
                            </div>
                            <div className="orderPickedDateRight">
                              <p className="lightTextcolor">Order Date</p>
                              <h3>
                                {moment(item.orderdate)
                                  .add(-1, "days")
                                  .format("YYYY-MM-DD")}
                              </h3>
                            </div>
                            <PlaylistRemoveIcon
                              onClick={() => deductOrder(item)}
                              style={{ color: "#c82d33" }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  // old code vj
                  // <div className="activeOrdersList">
                  // <div className="orderMenuList">
                  //   {orderList.map((item, index) => (
                  //     <div
                  //       key={index}
                  //       className={item.ordertype === 'Lunch' ? "orderNotiDiv specialMenu mobilelunchOrderColor" : item.ordertype === "Dinner" ? "orderNotiDiv specialMenu mobiledinnerOrderColor" : item.ordertype === "Breakfast" ? "orderNotiDiv specialMenu mobilebreakfastOrderColor" : "orderNotiDiv specialMenu"}
                  //       onClick={() =>
                  //         navigate("/ActiveOrderDetails", {
                  //           state: { id: item.order_id },
                  //         })
                  //       }
                  //     >
                  //       <div className="orderListLeftData">
                  //         <div className="ordIcoDiv orderListImgOuter">
                  //           <img src={cup} />
                  //         </div>
                  //         <div className="orderListOrderIdData">
                  //           <h3>Order #{item.order_id}</h3>
                  //           <p className="cateStMain">
                  //             {item.ordertype}
                  //           </p>
                  //           <div className="starPrice">
                  //             <StarIcon className="starIcon" />
                  //             <h5> {item.totalstarspend} </h5>
                  //           </div>
                  //         </div>
                  //       </div>
                  //       <div className="orderListRightData">
                  //         <div className="orderListRightData">
                  //           <p>{item.orderstatus}</p>
                  //         </div>
                  //         <Button className="loginBt QRBtn">QR Code</Button>
                  //         <p className="lightTextcolor"> Pick Up Date {" "}
                  //           {moment(item.orderdate).format(
                  //             "YYYY-MM-DD HH:mm"
                  //           )}
                  //         </p>
                  //         <p className="lightTextcolor"> Order Date {" "}
                  //           {moment(item.orderdate).add(-1, 'days').format(
                  //             "YYYY-MM-DD HH:mm"
                  //           )}
                  //         </p>
                  //       </div>

                  //     </div>
                  //   ))}

                  // </div>

                  // </div>
                  // old code vj end
                  <div className="nodatDiv">
                    <img src={noData} />
                  </div>
                )}
              </>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <div className="nodatDiv">
                <img src={noData} />
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
              <div className="nodatDiv">
                <img src={noData} />
              </div>
            </CustomTabPanel>
          </Box>
        </div>
        <BottomTabsPage />
      </div>
    </div>
  );
}

export default ActiveOrders;
