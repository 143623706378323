import { useEffect, useRef } from 'react';

function SilentPrint ({ content }) {
  const printContent = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/v1/sendprint/receipt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });
      
      const result = await response.json();
      if (!result.success) {
        throw new Error('Printing failed');
      }
    } catch (error) {
      console.error('Error printing:', error);
    }
  };

  return (
    <button onClick={printContent} className="p-2 bg-blue-500 text-white rounded">
      Print Directly
    </button>
  );
};

export default SilentPrint;