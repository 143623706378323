import React, { useState, useEffect, useRef } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import WestIcon from "@mui/icons-material/West";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import lockimg from "../../../assets/images/lock-outline.svg";
import productImg from "../../../assets/images/productImg.svg";
import ProductSlider from "./ProductSlider";
import ProductChart from "./ProductChart";
import Switch from "@mui/material/Switch";
import ProductCarousel from "./ProductCarousel";
import Surprise from "../../../assets/images/Surprise.svg";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import UpgradeIcon from "@mui/icons-material/Upgrade";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarCafeTabs from "./StarCafeTabs";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
// End
import moment from "moment";
import swal from "sweetalert";

function GroceryProductDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [groceryImage, setGroceryImage] = useState([]);
  const [groceryObj, setGroceryObj] = useState([]);
  function handleChange(index) {
    setCurrentIndex(index);
  }

  const renderSlides = groceryImage.map((image, index) => (
    <div key={image.alt}>
      <img src={image} alt="img" />
      {/* <p className="legend">{image.label}</p> */}
    </div>
  ));

  // get Grocery Start
  const getGroceryData = () => {
    setShowLoader(true);
    makeGetRequest("starcafe/getgrocerybyid/" + id)
      .then((response) => {
        if (response.data) {
          console.log("Grocery Data", response.data.data[0]);
          setGroceryImage(response.data.data[0].Grocery_image);
          setGroceryObj(response.data.data[0]);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // get Grocery Data End
  // Status Change
  const handleStatusChange = (event, grocery_id) => {
    let inputdata = {
      grocery_id: grocery_id,
      status: event.target.checked,
    };
    makePostRequest("starcafe/updategrocery", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getGroceryData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Change
  // Delete Vegetable Start
  const deleteGrocery = async (grocery_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Grocery Deleted!",
          text: "Grocery is successfully deleted!",
          icon: "success",
        }).then(function () {
          let inputdata = {
            grocery_id: grocery_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starcafe/updategrocery", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                navigate("/ServicesConfiguration");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Vegetable End

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // Modal Main
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Modal Main
  const [open3, setOpen3] = useState(false);

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  // End

  // Modal Main
  const [open4, setOpen4] = useState(false);

  const handleClickOpen4 = (scrollType) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  useEffect(() => {
    getGroceryData();
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open4) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open4, open3, open]);
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => navigate("/ServicesConfiguration")} />
                Grocery Product Details
              </h1>
              {/* <Button className="pageTopMainBt">Demo Button</Button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="productDetailsTop">
              <div className="productDetailsHeading">
                <div className="productDetailsHeadLeft">
                  <h1>
                    {groceryObj.grocery_name}
                    <span>
                      <b>+8%</b> from Yesterday
                    </span>
                  </h1>
                  <h4 className="productId">
                    Product ID #{groceryObj.grocery_id}
                  </h4>
                </div>
                <div className="productDetailsHeadRight">
                  <div className="actionBtnOut">
                    {/* <Tooltip title="Edit" arrow placement="top">
                      <IconButton onClick={handleClickOpen4("body")}>
                        <img src={editIco} />
                      </IconButton>
                    </Tooltip> */}

                    <Switch
                      {...label}
                      checked={groceryObj.status}
                      className="swicthMain"
                      onChange={(event) =>
                        handleStatusChange(event, groceryObj.grocery_id)
                      }
                    />

                    <Tooltip title="Delete" arrow placement="top">
                      <IconButton
                        onClick={() => deleteGrocery(groceryObj.grocery_id)}
                      >
                        <img src={deletIco} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* <div>
                <div className="mainListDiv">
                  <div className="mainListCont mainListInrCont">
                    <label>Last Added Date</label>
                    <span className="mainListSubTx">06/04/2023</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Category</label>
                    <span className="mainListSubTx">Groceries</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Available Quantity</label>
                    <span className="mainListSubTx">
                      <b>500</b>
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Sold Quantity</label>
                    <span className="mainListSubTx">12000</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Price</label>
                    <span className="mainListSubTx">$15/Item</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Price In Stars</label>
                    <span className="mainListSubTx">
                      <b className="listMainStar">
                        <StarIcon /> 150
                      </b>
                    </span>
                  </div>
                </div>
              </div> */}

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="productImgDiv">
                      {groceryImage?
                      <Carousel
                        showArrows={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        selectedItem={groceryImage[currentIndex]}
                        onChange={handleChange}
                        className="carousel-container"
                      >
                        {renderSlides}
                      </Carousel>:<></>}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="salesOt">
                      <p>Sales</p>
                      <select>
                        <option>Months</option>
                      </select>
                    </div>
                    <div className="chartAmountMain">
                      <b>4512</b>
                      <p>
                      ₹ 1524.42{" "}
                        <span>
                          46% <UpgradeIcon />
                        </span>
                      </p>
                    </div>
                    <ProductChart />
                    {/* Chart Here... */}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="settingDevider"></div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="proDetailasCont">
                      <div className="proDetailasContDiv">
                        <span>
                          <p>Added Date</p>
                          <b>
                            :{" "}
                            {moment(groceryObj.created_at).format("DD/MM/YYYY")}
                          </b>
                        </span>

                        <span>
                          <p>Brand Name</p>
                          <b>: {groceryObj.brand_name}</b>
                        </span>

                        <span>
                          <p>Product In Stock</p>
                          <b>
                            :{" "}
                            {groceryObj.isCarton
                              ? groceryObj.total_cartons
                              : groceryObj.total_unit_bought}
                          </b>
                        </span>

                        <span>
                          <p>Low Stock Limit</p>
                          <b>: {groceryObj.low_stock_limit}</b>
                        </span>

                        <span>
                          <p>Weight Per Unit</p>
                          <b>
                            : {groceryObj.single_unit_weight}{" "}
                            {groceryObj.unit_of_single_wt}
                          </b>
                        </span>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="proDetailasCont">
                      <div className="proDetailasContDiv">
                        <span>
                          <p>Expiry Date</p>
                          <b>
                            :{" "}
                            {moment(groceryObj.expiry_date).format(
                              "DD/MM/YYYY"
                            )}
                          </b>
                        </span>

                        <span>
                              <p>Updated Quantity</p>
                              <b>: {groceryObj.new_quantity}
                              </b>
                            </span>
                            <span>
                              <p>Updated Date</p>
                              <b>: {moment(groceryObj.quantity_updated_dt).format(
                              "DD/MM/YYYY"
                            )}
                              </b>
                            </span>

                        <span>
                          <p>Purchase Price in INR (₹)</p>
                          <b>
                            : ₹ {" "}
                            {groceryObj.isCarton
                              ? groceryObj.price_per_carton
                              : groceryObj.price_per_unit}
                          </b>
                        </span>

                        <span>
                          <p>Price In Stars</p>
                          <b className="listMainStar">
                            :
                            {groceryObj.isCarton?
                              <>
                              <StarIcon /> {groceryObj.price_per_carton/parseInt(localStorage.getItem("masterdollarvalue"))}</>
                              : <><StarIcon /> {groceryObj.price_per_unit/parseInt(localStorage.getItem("masterdollarvalue"))}</>
                            }
                          </b>
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          {/* <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Other Trending Products</h1>
            </div>
            <div className="productSliderOuter">
              <ProductSlider />
            </div>
          </Grid> */}
        </Grid>
      </div>

      {/* Add Category */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Category
            <CloseIcon className="modalCloseBt" onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Enter Category Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Category Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleClose}
                      >
                        Add Category
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Product */}
      <div>
        <Dialog
          open={open4}
          // onClose={handleClose4}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Product
            <CloseIcon className="modalCloseBt" onClick={handleClose4} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="uploadImBox">
                        <h1 className="">Upload Image</h1>
                        <Button
                          className="uploadImBt"
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <span>or drag file in here</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Product Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">
                        Select Categories Name
                      </label>
                      <div className="addContentOut">
                        <select className="prodSearchInpt">
                          <option>Product Category</option>
                          <option>Select 1</option>
                          <option>Select 2</option>
                          <option>Select 3</option>
                        </select>
                        <Button
                          className="addContentBtn"
                          onClick={handleClickOpen("body")}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter New Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Weight Per Item</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Weight"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Price in INR (₹)</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Dollars"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Price In Stars</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Stars"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span onClick={handleClose4}>
                        <Button
                          className="modalBtSmallWidth"
                          // onClick={handleClickOpen("body")}

                          onClick={handleClickOpen3("body")}
                        >
                          Add Product
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Added Successfull */}
      <div>
        <Dialog
          open={open3}
          // onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Product
            <CloseIcon className="modalCloseBt" onClick={handleClose3} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="modalSuccessDiv">
                        <img src={Surprise} />
                        <div className="modalCenterContent">
                          <p>
                            <b>Product Name</b>
                            <span> is added successfully</span>
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Commodo amet
                            sed sed non nisl lacus cursus penatibus faucibus.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtOutline">Add Product</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default GroceryProductDetails;
