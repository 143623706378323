import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import dashLogo from "../assets/images/logo.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import SettingsPowerOutlinedIcon from "@mui/icons-material/SettingsPowerOutlined";
import { useNavigate } from "react-router-dom";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PieChartIcon from "@mui/icons-material/PieChart";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

function Left() {
  const navigate = useNavigate();

  // For Open SubMenu
  const [OpenDrop, setOpenOpenDrop] = React.useState(false);
  const handleClickOpenDrop = () => {
    setOpenOpenDrop(!OpenDrop);
  };
  // End

  // For Local Employee Sub Drop
  const [LocalEmployeOpenDrop, setOpenLocalEmployeOpenDrop] =
    React.useState(false);
  const handleClickLocalEmployeOpenDrop = () => {
    setOpenLocalEmployeOpenDrop(!LocalEmployeOpenDrop);
  };
  // End

  const [open, setOpen] = React.useState(true);
  const [userType, setUserType] = React.useState("");
  const [selectedMenu, setSelectedMenu] = React.useState("");

  const handleClick = () => {
    setOpen(!open);
  };

  function logOutFunction() {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }

  React.useEffect(() => {
    setUserType(localStorage.getItem("userType"));
  }, []);

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      className="leftNavMain"
    >
      <div className="leftTopSec">
        {localStorage.getItem("useType") == "SuperAdmin" ? (
          <span>Super Admin</span>
        ) : (
          <>
            <div className="webLogoOut">
              {localStorage.getItem("companyLogo") ? (
                <img src={localStorage.getItem("companyLogo")} />
              ) : (
                <img src={dashLogo} />
              )}
            </div>
          </>
        )}
        <b>{sessionStorage.getItem("companyName")}</b>
      </div>

      {/* <span className="useAdminDiv">{localStorage.getItem("userType")}</span> */}
      {userType == "SuperAdmin" ? (
        <>
          <ListItemButton onClick={() => navigate("/SuperAdminDashboard")}>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText>Companies</ListItemText>
          </ListItemButton>

          <ListItemButton onClick={() => navigate("/CompanyAdmins")}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText>Company Admins</ListItemText>
          </ListItemButton>
        </>
      ) : (
        <>
          {JSON.parse(localStorage.getItem("accessRights")) != null ? (
            <>
              {JSON.parse(localStorage.getItem("accessRights")).map(
                (module) => (
                  <>
                    {module.moduleName == "Dashboard" &&
                    module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") == "Dashboard"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/Dashboard")}
                      >
                        <ListItemIcon>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText>Dashboard</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}

                    {module.moduleName == "Inventory" &&
                    module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") ==
                          "ServiceProvider"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/ServicesConfiguration")}
                      >
                        <ListItemIcon>
                          <SupportAgentIcon />
                        </ListItemIcon>
                        <ListItemText>Services Configuration</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}

                    {module.moduleName == "Orders" && module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") == "Orders"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/Orders")}
                      >
                        <ListItemIcon>
                          <LocalShippingOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>Orders</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}

                    {  (module.moduleName == "Employee" &&
                      module.status == true) ? (
                      <>
                        <ListItemButton onClick={handleClickOpenDrop}>
                          <ListItemIcon>
                            <BadgeOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText>Manage HR</ListItemText>
                          {OpenDrop ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={OpenDrop} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {(module.moduleName == "Employee" &&
                            module.status == true) ? (
                              <ListItemButton
                                className={
                                  localStorage.getItem("selectedModule") ==
                                  "Employees"
                                    ? "activeMenu"
                                    : ""
                                }
                                onClick={() => navigate("/Employees")}
                              >
                                <ListItemIcon>
                                  <BadgeOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Employees" />
                              </ListItemButton>
                            ) : (
                              <></>
                            )}

                            {/* {module.moduleName == "Warning" && module.status == true ? ( */}
                            <ListItemButton
                              className={
                                localStorage.getItem("selectedModule") ==
                                "Warningtab"
                                  ? "activeMenu"
                                  : ""
                              }
                              onClick={() => navigate("/WarningsTb")}
                            >
                              <ListItemIcon>
                                <BadgeOutlinedIcon />
                              </ListItemIcon>

                              <ListItemText primary="Warnings" />
                            </ListItemButton>
                            {/* ):<></>}  */}

                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon>
                                <BadgeOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Leaves" />
                            </ListItemButton>

                            </List>
                        </Collapse>
                      </>
                    ) : (
                      <></>
                    )}

                    {module.moduleName == "Finance" && module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") == "Finance"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/FinancePage")}
                      >
                        <ListItemIcon>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText>Finance</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}

                    {module.moduleName == "Reports" && module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") == "Reports"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/ReportsPage")}
                      >
                        <ListItemIcon>
                          <PieChartIcon />
                        </ListItemIcon>
                        <ListItemText>Reports</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}

                    {module.moduleName == "SupportTicket" &&
                    module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") ==
                          "SupportTicket"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/SupportTickets")}
                      >
                        <ListItemIcon>
                          <LocalActivityIcon />
                        </ListItemIcon>
                        <ListItemText>Support Tickets</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}

                    {module.moduleName == "Settings" &&
                    module.status == true ? (
                      <ListItemButton
                        className={
                          localStorage.getItem("selectedModule") == "Settings"
                            ? "activeMenu"
                            : ""
                        }
                        onClick={() => navigate("/Settings")}
                      >
                        <ListItemIcon>
                          <SettingsSuggestOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                      </ListItemButton>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}
            </>
          ) : (
            <></>
          )}
          <ListItemButton onClick={handleClickLocalEmployeOpenDrop}>
            <ListItemIcon>
              <BadgeOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Local Employee</ListItemText>
            {LocalEmployeOpenDrop ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={LocalEmployeOpenDrop} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                className={
                  localStorage.getItem("selectedModule") == "Employees"
                    ? "activeMenu"
                    : ""
                }
                onClick={() => navigate("/LocalEmployees")}
              >
                <ListItemIcon>
                  <BadgeOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Employees" />
              </ListItemButton>

              <ListItemButton onClick={() => navigate("/EmployeeOrderScan")}>
                <ListItemIcon>
                  <QrCodeScannerIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            className={
              localStorage.getItem("selectedModule") == "Help"
                ? "activeMenu"
                : ""
            }
            onClick={() => navigate("/Help")}
          >
            <ListItemIcon>
              <LiveHelpOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Help</ListItemText>
          </ListItemButton>
        </>
      )}

      <ListItemButton onClick={() => logOutFunction()}>
        <ListItemIcon>
          <SettingsPowerOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </ListItemButton>
      {/* <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Inbox" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItemButton>
        </List>
      </Collapse> */}
    </List>
  );
}

export default Left;
