import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import Pagination from "@mui/material/Pagination";
import { makePostRequest, makeGetRequest } from "../../../utils/utils";

import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import swal from "sweetalert";
import loaderImg from "../../../assets/images/logo.png";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import exchaneBlack from "../../../assets/images/exchaneBlack.png";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import moment from "moment";

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import qrIm from "../../../assets/images/qrCode.svg";
import printIcon from "../../../assets/images/printIcon.svg";

// reload and refund stars
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Employees() {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // reload and refund stars
  const [value, setValue] = React.useState(0);

  const handleChangeStars = (event, newValue) => {
    setValue(newValue);
  };

  const [showLoader, setShowLoader] = React.useState(false);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(1);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");

  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [employeeObj, setEmployeeObj] = React.useState({});

  async function handleChangeSearch(searchString) {
    await getAllEmployeeList(searchString);
  }

  async function getAllEmployeeList(searchString) {
    if (!searchString) searchString = null;
    await makeGetRequest(
      "employee/employeesearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage +
        "/null"
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setEmployeeList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log(response.data.data.res);
          } else setEmployeeList([]);
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function insertOrUpdateRefundHistory(values) {
    console.log(values)
    let isRefundObj;
    await swal({
      title: "Do you really want to refund stars?",
      text: "This prodess can not be undone",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        isRefundObj = isConfirm;
      }
    });

    if (isRefundObj) {
      setShowLoader(true);

      let inputData = {
        employee_id: employeeObj.employee_id,
        order_id: 0,
        star_refunded: parseFloat(purchase_star_quantity),
        order_type: "Refunded By Admin",
        comment:values.comment
      };
      await makePostRequest("employee/insertrefundhist", inputData)
        .then((response) => {
          if (response.data.data !== undefined) {
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });

      let starInputData = {
        employee_id: employeeObj.employee_id,
        total_stars:
          parseInt(employeeObj.total_stars) + parseInt(purchase_star_quantity),
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });

      setShowLoader(false);
    }
  }

  async function insertOrUpdateDeductHistory(values) {
    let isDeductObj;
    await swal({
      title: "Do you really want to deduct stars?",
      text: "This prodess can not be undone",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        isDeductObj = isConfirm;
      }
    });

    if (isDeductObj) {
      setShowLoader(true);

      let inputData = {
        employee_id: employeeObj.employee_id,
        star_deducted: parseFloat(purchase_star_quantity),
        reason: values.comment,
      };
      await makePostRequest("employee/insertdeducthist", inputData)
        .then((response) => {
          if (response.data.data !== undefined) {
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });

      let starInputData = {
        employee_id: employeeObj.employee_id,
        total_stars:
          parseInt(employeeObj.total_stars) - parseInt(purchase_star_quantity),
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });

      setShowLoader(false);
    }
  }

  const handlePageChange = (e, page) => {
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  function handleChangeSortBy() {
    // alert("In ASC")
    setSortColumnName("first_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllEmployeeList(null);
  }

  const handleChange = (event, employee_id) => {
    // setChecked(event.target.checked);
    setShowLoader(true);

    let inputdata = {
      employee_id: employee_id,
      status: event.target.checked,
    };

    makePostRequest("employee/updateemployeestatus", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getAllEmployeeList(null);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  function editEmployee(employeeId) {
    navigate("/EditEmployee/" + employeeId);
  }

  async function deleteEmployee(emplpoyeeId) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        employee_id: emplpoyeeId,
        is_deleted: true,
      };
      await makePostRequest("employee/updateemployeestatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllEmployeeList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const initialValues = {
    purchase_star_dollar_Amount: 0,
    purchase_star_quantity: 0,
    comment:""
  };

  const validationSchema = Yup.object({
    purchase_star_dollar_Amount: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid Amount"),
    purchase_star_quantity: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid Quantity"),
      comment:Yup.string().min(10).max(50).required("Required")
  });

  const [spendStars, setSpendStars] = React.useState(0);
  const [availableStars, setAvailableStars] = React.useState(0);
  const [starAllowenceHistory, setStarAllowenceHistory] = React.useState([]);
  const [starReloadHistory, setStarRealodHistory] = React.useState([]);
  const [starSpendHistory, setStarSpendHistory] = React.useState([]);
  const [salaryDetails, setSalaryDetails] = React.useState({});
  const [masterStarAmount, setMasterStarAmount] = React.useState(0);
  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);

  // Star Activity Tabs
  const [priceErrMsg, setPriceErrMsg] = React.useState("");
  const [purchase_star_dollar_Amount, setPurchaseStarDollarAmount] =
    React.useState(0);
  const [purchase_star_quantity, setPurchaseStarQuantity] = React.useState(0);
  // End

  function getStarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          if (response.data.data[0] == undefined) {
            swal(
              "Set Exchange Stars",
              "First set master star rate with dollar",
              "warning",
              {
                timer: 4000,
                buttons: false,
              }
            );
            navigate("/Dashboard");
          } else {
            setMasterStarAmount(response.data.data[0].start_count);
            setMasterDollarAmount(response.data.data[0].dollar_amount);
            setUpload(!upload);
          }
          // console.log(response.data.data[0]);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  function handleChangeDollarAmount(value) {
    // alert(value*masterStarAmount)
    setPriceErrMsg("");
    setPurchaseStarQuantity(value);
    // setPurchaseStarDollarAmount(value);

    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      if (value * masterDollarAmount <= employeeObj.balance_salary)
        setPurchaseStarDollarAmount(value * masterDollarAmount);
      else {
        setPurchaseStarDollarAmount(value * masterDollarAmount);
        setPriceErrMsg("You don't have enough balance");
      }
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPurchaseStarDollarAmount(0);
      }
    }
  }

  function handleChangeRefundDollarAmount(value) {
    // alert(value*masterStarAmount)
    setPriceErrMsg("");
    setPurchaseStarQuantity(value);

    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      setPurchaseStarDollarAmount(value * masterDollarAmount);
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPurchaseStarDollarAmount(0);
      }
    }
  }

  function handleChangeDeductDollarAmount(value) {
    // alert(value*masterStarAmount)
    setPriceErrMsg("");
    setPurchaseStarQuantity(value);

    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      setPurchaseStarDollarAmount(value * masterDollarAmount);
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPurchaseStarDollarAmount(0);
      }
    }
  }

  async function reloadStars() {
    if (priceErrMsg == "") {
      setShowLoader(true);
      let totalStars =
        parseInt(purchase_star_quantity) + parseInt(availableStars);
      let starInputData = {
        employee_id: employeeObj.employee_id,
        total_stars: totalStars,
        total_star_purchase: parseInt(purchase_star_quantity),
        total_star_spent: 0,
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
      let inputdata = {
        employee_id: employeeObj.employee_id,
        star_reloaded: parseInt(purchase_star_quantity),
        amount_in_dollars: parseInt(purchase_star_dollar_Amount),
        reloaded_date: moment(),
      };
      await makePostRequest("employee/insertreloadhist/", inputdata)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });

      let balanceSalary =
        parseFloat(employeeObj.balance_salary) -
        parseInt(purchase_star_dollar_Amount);

      await makePostRequest("employee/updateemployeestatus", {
        employee_id: employeeObj.employee_id,
        balance_salary: balanceSalary,
      })
        .then((response) => {
          if (response.data) {
            setPurchaseStarDollarAmount(0);
            setPurchaseStarQuantity(0);
            setPriceErrMsg("");
            swal("Success", "Star Reloaded Successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            setOpen3(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
      await sendNotification(
        "Stars Reloaded",
        "Hi " +
          employeeObj.first_name +
          " " +
          purchase_star_quantity +
          " stars reloaded by admin in your account ",
        employeeObj.employee_id
      );
      //getStarActivityDetails();
      //getStarSpendHistory();
      setShowLoader(false);
    }
  }

  const [open3, setOpen3] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen3 = (scrollType, empObj) => () => {
    setOpen3(true);
    setScroll(scrollType);
    setEmployeeObj(empObj);
    makeGetRequest("employee/getstarcount/" + empObj.employee_id)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setSpendStars(response.data.data.total_star_spent);
          setAvailableStars(response.data.data.total_stars);
          setUpload(!upload);
        }
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
    getStarAmount();
  };

  const descriptionElementRef = React.useRef(null);
  const handleClose3 = () => {
    setOpen3(false);
    setPurchaseStarDollarAmount(0);
    setPurchaseStarQuantity(0);
    setPriceErrMsg("");
  };

  async function sendNotification(title, message, employee_id) {
    let inputData = {
      title: title,
      message: message,
      resident_id: employee_id,
    };

    makePostRequest("employee/notificationtoadmin", inputData)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }

  async function getEmployeesListOnLoad() {
    setShowLoader(true);
    await getAllEmployeeList(null);
    setShowLoader(false);
  }

  React.useEffect(() => {
    localStorage.setItem("selectedModule", "Employees");
    localStorage.removeItem("selectedusertype");
    getEmployeesListOnLoad();
  }, [pageNo]);

  // Generate Qr Code
  const [GenerateQr, setOpenGenerateQr] = React.useState(false);

  const handleClickGenerateQr = (scrollType) => () => {
    setOpenGenerateQr(true);
    setScroll(scrollType);
  };

  const closeGenerateQr = () => {
    setOpenGenerateQr(false);
  };
  // End

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Employees List</h1>
              <div className="employeeFilter">
                <div className="prodFilter">
                  {/* <input
                    className="prodSearchInpt"
                    placeholder="Search"
                    onChange={(e) => getAllEmployeeList(e.target.value)}
                  /> */}
                </div>
                {JSON.parse(localStorage.getItem("accessRights") != null) ? (
                  <>
                    {JSON.parse(localStorage.getItem("accessRights")).map(
                      (module) => (
                        <>
                          {module.moduleName == "AddEmployee" &&
                          module.status == true ? (
                            <Button
                              className="pageTopMainBt"
                              onClick={() => navigate("/AddEmployee")}
                            >
                              Add Employee
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="visitorSearch">
              <div className="prodFilter">
                <input
                  className="prodSearchInpt"
                  placeholder="Search"
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <div className="sortingIcoOut">
                <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    handleChangeSortBy();
                  }}
                >
                  <ImportExportIcon
                    className="visitorFilterIco"
                    aria-haspopup="true"
                    // onChange={()=>handleChangeSortBy()}
                  />
                </ToggleButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="serviceistOuter">
              {employeeList ? (
                <>
                  {employeeList.map((empObj) => (
                    <div className="mainListDiv">
                      <div className="mainListCont">
                        <h1
                          onClick={() =>
                            navigate("/EmployeesDetails/" + empObj.employee_id)
                          }
                        >
                          <Tooltip
                            title="Employee Full name here"
                            arrow
                            placement="top"
                          >
                            {empObj.first_name} {empObj.last_name}
                          </Tooltip>
                        </h1>
                        <p>{empObj.email_id}</p>
                        <span className="mainListContact">
                          {empObj.contact_no}
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <label>Employee Code</label>
                        <span className="mainListSubTx">
                          {empObj.employee_code}
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <label>Designation</label>
                        <span className="mainListSubTx">
                          {empObj.designation_name}
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <label>Department</label>
                        <span className="mainListSubTx">
                          {empObj.department_name}
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <label>Monthly Star Allowance</label>
                        <span className="mainListSubTx">
                          <b className="listMainStar">
                            <StarIcon />
                            {empObj.monthly_allowance}
                          </b>
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <div className="actionBtnOut">
                          <div style={{ display: "flex" }}>
                            <Tooltip
                              title="Manage Stars"
                              arrow
                              placement="top"
                              onClick={handleClickOpen3("body", empObj)}
                            >
                              <img src={exchaneBlack} className="reLIm" />
                            </Tooltip>
                            {JSON.parse(
                              localStorage.getItem("accessRights") != null
                            ) ? (
                              <>
                                {JSON.parse(
                                  localStorage.getItem("accessRights")
                                ).map((module) => (
                                  <>
                                    {module.moduleName ==
                                      "EmployeeModification" &&
                                    module.status == true ? (
                                      <>
                                        <Switch
                                          {...label}
                                          checked={empObj.status}
                                          className="swicthMain"
                                          onChange={(event) =>
                                            handleChange(
                                              event,
                                              empObj.employee_id
                                            )
                                          }
                                        />

                                        <Tooltip
                                          title="Edit"
                                          arrow
                                          placement="top"
                                        >
                                          {/* <span className="mainListSubTx">
                              {empObj.department_name}
                            </span> */}
                                          <IconButton
                                            onClick={() =>
                                              editEmployee(empObj.employee_id)
                                            }
                                          >
                                            <img src={editIco} />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip
                                          title="Delete"
                                          arrow
                                          placement="top"
                                        >
                                          <IconButton
                                            onClick={() =>
                                              deleteEmployee(empObj.employee_id)
                                            }
                                          >
                                            <img src={deletIco} />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}

                            <Tooltip
                              title="Generate QR Code"
                              arrow
                              placement="top"
                            >
                              <Button
                                className="emploQrBt"
                                onClick={handleClickGenerateQr("body")}
                              >
                                <QrCodeScannerIcon />
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={pageNo}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Dialog
          open={open3}
          // onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Manage Stars
            <CloseIcon className="modalCloseBt" onClick={handleClose3} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <div class="popUpExchImgOuter">
                      <div class="setExchImgDiv">
                        <img src={exchaneBlack} />
                      </div>
                      <h2>
                        Exchange Star Rate{" "}
                        <b style={{ color: "#c82d33", fontSize: 30 }}>
                          1 Star=₹ {masterDollarAmount}
                        </b>
                      </h2>
                    </div>
                    <Grid item xs={12} md={6}>
                      <div className="addStarContD">
                        <b className="startCount">
                          <StarIcon /> {spendStars}
                        </b>
                        <p>Spent Stars</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="addStarContD">
                        <b className="startCount">
                          <StarIcon /> {availableStars}
                        </b>
                        <p>Available Stars</p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="starCafeTabMain">
                <Box className="newReloadRefundTab" sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChangeStars}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Reload Stars"
                        {...a11yProps(0)}
                        onClick={() => {
                          setPriceErrMsg("");
                          setPurchaseStarDollarAmount(0);
                          setPurchaseStarQuantity(0);
                        }}
                      />
                      <Tab
                        label="Refund Stars"
                        {...a11yProps(1)}
                        onClick={() => {
                          setPriceErrMsg("");
                          setPurchaseStarDollarAmount(0);
                          setPurchaseStarQuantity(0);
                        }}
                      />

                      <Tab
                        label="Deduct Stars"
                        {...a11yProps(2)}
                        onClick={() => {
                          setPriceErrMsg("");
                          setPurchaseStarDollarAmount(0);
                          setPurchaseStarQuantity(0);
                        }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div className="forModalSpace">
                      <div className="modalSinlInputDiv">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          enableReinitialize
                          onSubmit={reloadStars}
                        >
                          {(formik) => (
                            <Form>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Enter Star Amount (*)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Star Quantity"
                                    value={purchase_star_quantity}
                                    onChange={(e) =>
                                      handleChangeDollarAmount(e.target.value)
                                    }
                                    className="prodSearchInpt"
                                  />
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    {priceErrMsg}
                                  </span>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Enter Rupees Amount in INR (₹)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Rupees Amount in INR (₹)"
                                    value={purchase_star_dollar_Amount}
                                    className="prodSearchInpt"
                                    disabled
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <span>
                                    <Button
                                      className="modalBtSmallWidth"
                                      type="submit"
                                    >
                                      Reload Stars
                                    </Button>
                                  </span>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={1}>
                    <div className="forModalSpace">
                      <div className="modalSinlInputDiv">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          enableReinitialize
                          onSubmit={insertOrUpdateRefundHistory}
                        >
                          {(formik) => (
                            <Form>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Enter Star Amount (*)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Star Quantity"
                                    className="prodSearchInpt"
                                    value={purchase_star_quantity}
                                    onChange={(e) =>
                                      handleChangeRefundDollarAmount(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    {priceErrMsg}
                                  </span>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Enter Rupees Amount in INR (₹)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Rupees Amount in INR (₹)"
                                    value={purchase_star_dollar_Amount}
                                    className="prodSearchInpt"
                                    disabled
                                  />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                  <FormikControl
                                    control="input"
                                    type="textarea"
                                    label="Comment"
                                    placeholder="Enter comment here"
                                    labelClass="pageLabel"
                                    name="comment"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                    
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <span>
                                    <Button
                                      className="modalBtSmallWidth"
                                      type="submit"
                                    >
                                      Refund Stars
                                    </Button>
                                  </span>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={2}>
                    <div className="forModalSpace">
                      <div className="modalSinlInputDiv">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          enableReinitialize
                          onSubmit={insertOrUpdateDeductHistory}
                        >
                          {(formik) => (
                            <Form>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Enter Star Amount (*)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Star Quantity"
                                    className="prodSearchInpt"
                                    value={purchase_star_quantity}
                                    onChange={(e) =>
                                      handleChangeDeductDollarAmount(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    {priceErrMsg}
                                  </span>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Enter Rupees Amount in INR (₹)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Rupees Amount in INR (₹)"
                                    value={purchase_star_dollar_Amount}
                                    className="prodSearchInpt"
                                    disabled
                                  />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                <FormikControl
                                    control="input"
                                    type="textarea"
                                    label="Comment"
                                    placeholder="Enter comment here"
                                    labelClass="pageLabel"
                                    name="comment"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                  </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <span>
                                    <Button
                                      className="modalBtSmallWidth"
                                      type="submit"
                                    >
                                      Deduct Stars
                                    </Button>
                                  </span>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={GenerateQr}
          // onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Generated QR Code
            <CloseIcon className="modalCloseBt" onClick={closeGenerateQr} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            >
              <div className="forModalSpace">
                <div
                  className="modalSinlInputDiv"
                  style={{ position: "relative" }}
                >
                  <img src={printIcon} className="printQrC" />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="genrtQrMain">
                        <img src={qrIm} />
                      </div>

                      <div className="genrtQrCon">
                        <p>Kishor Mahajan</p>
                        <span>reena@gmail.com</span>
                        <b>5656</b>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span>
                        <Button className="modalBtSmallWidth" type="submit">
                          Generated QR Code
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Employees;
