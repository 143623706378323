import React, { useEffect, useState } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { Button, Grid,Box, Zoom } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Tooltip from "@mui/material/Tooltip";
import deletIco from "../../../assets/images/delete-icon.svg";
import eye from "../../../assets/images/eye.svg";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import moment from "moment";
import swal from "sweetalert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import prodImg1 from "../../../assets/images/recipt.jpg";
import prodImg2 from "../../../assets/images/recipt.jpg";
import prodImg3 from "../../../assets/images/recipt.jpg";
import editIco from "../../../assets/images/edit-icon.svg";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

// Inner Tabs
function CustomTabPanelInnerTabs(props) {
  const { children, InnerTabs, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={InnerTabs !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {InnerTabs === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelInnerTabs.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsInnerTabs(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function FinancePage() {

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [uploadImgOrFile, setUploadImgOrFile] = React.useState([]);
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [formValues, setFormValues] = useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [billDate, setBillDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [sortTable, setSortTable] = useState("today");
  const [categoryList, setCategoryList] = React.useState([]);
  const [yearList, setYearList] = React.useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedWeekOrMonth, setSelectedWeekOrMonth] = useState();

  // Inner Tabs
    const [InnerTabs, setValueInnerTabs] = React.useState(0);
  
    const handleChangeInnerTabs = (event, newValueInnerTabs) => {
      setValueInnerTabs(newValueInnerTabs);
    };
    // End

  const statusChangeFun = (status) => {
    setSortTable(status.target.value);
  };
  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|docx|DOCX|doc|DOC|pdf|PDF|xls|XLS|txt|TXT|xlsx|XLSX)$/i
      )
    )
      return true;
    else return false;
  }

  async function uploadImageAndFile(e) {
    setImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;

    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadImgOrFile([...uploadImgOrFile, imageUrl]);
      console.log("img arr", uploadImgOrFile);
    } else {
      setImageErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End
  const deleteImage = async (index) => {
    await uploadImgOrFile.splice(index, 1);
    setUploadImgOrFile([...uploadImgOrFile]);
  };
  //delete Image End

  //   For Validation
  const initialValues = {
    category: "",
    amount: "",
  };

  const validationSchema = Yup.object({
    category: Yup.string().required("Required"),
    amount: Yup.number().typeError("Invalid Number").required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    if (uploadImgOrFile.length != 0) {
      values.category = values.category.split("|||")[0];
      values.documents = uploadImgOrFile;
      values.amount = parseInt(values.amount);
      values.billdate=billDate;
      console.log("Form Data", values);
      makePostRequest("finance/insert", values)
        .then((response) => {
          if (response.data) {
            console.log(response);
            getFinanceList(null,startDate,endDate,sortTable);
          }
          setUploadImgOrFile([]);
          resetForm();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setImageErrMsg("Please Upload Document");
    }
  };

  const dropdownOptions = [
    { key: "Select an option", value: "" },
    { key: "Grocery", value: "Grocery" },
    { key: "Vegitables", value: "Vegitables" },
    { key: "Kitchen_Supply", value: "KitchenSupply" },
    { key: "Cafe_Inventory", value: "CafeInventory" },
  ];

  const [financeData, setFinanceData] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);

  function handleChangeStartDate(e) {
    console.log(e.target.value);
    setSortTable("");
    setStartDate(e.target.value);
    if (!endDate) {
      setEndDate(new Date().toJSON().slice(0, 10));
      getFinanceList(null, e.target.value, new Date().toJSON().slice(0, 10),sortTable);
    } else {
      getFinanceList(null, e.target.value, endDate,sortTable);
    }
  }

  function handleChangeEndDate(e) {
    console.log(e.target.value);
    if (startDate == "") setStartDate(new Date());

    setEndDate(e.target.value);
    getFinanceList(null, startDate, e.target.value,sortTable);
  }

  const getFinanceList = async (searchstr, startdate, enddate,sortBy,weekormonth,selyear) => {
    let StartDate;
    let EndDate;
    console.log(startDate+" "+endDate)
    setSortTable(sortBy);
    if (startdate || enddate) {
      if (startdate) 
          StartDate = startdate;
      if (enddate) 
          EndDate = enddate;
    } 
    else {
      if (sortBy === "today") {
        StartDate = moment().format("YYYY-MM-DD");
        EndDate = moment().format("YYYY-MM-DD");
      } 
      else if (sortBy === "weekly" && weekormonth) {
        StartDate = moment().year(selyear).week(weekormonth).startOf("week").format("YYYY-MM-DD");
        EndDate = moment().year(selyear).week(weekormonth).endOf("week").format("YYYY-MM-DD");
      }
      else if (sortBy === "weekly" && !weekormonth) {
        StartDate = moment().clone().startOf("week").format("YYYY-MM-DD");
        EndDate = moment().clone().endOf("week").format("YYYY-MM-DD");
       } 
       else if (sortBy === "monthly" && weekormonth) {
        StartDate = moment().year(selyear).month(weekormonth).startOf("month").format("YYYY-MM-DD");
        EndDate = moment().year(selyear).month(weekormonth).endOf("month").format("YYYY-MM-DD");
        console.log(StartDate)

      }
       else if (sortBy === "monthly" && !weekormonth) {
        StartDate = moment().clone().startOf("month").format("YYYY-MM-DD");
        EndDate = moment().clone().endOf("month").format("YYYY-MM-DD");
      }
      else if (sortBy === "yearly" && selyear) {
        StartDate = moment().year(selyear).startOf("year").format("YYYY-MM-DD");
        EndDate = moment().year(selyear).clone().endOf("year").format("YYYY-MM-DD");
     
      }
       else if (sortBy === "yearly" && !selyear) {
        StartDate = moment().clone().startOf("year").format("YYYY-MM-DD");
        EndDate = moment().clone().endOf("year").format("YYYY-MM-DD");
       }
    }
    if (!searchstr) searchstr = null;
    setShowLoader(true);
    await makeGetRequest("finance/getlist/" + StartDate + "/" + EndDate+"/"+sortBy )
      .then((response) => {
        if (response.data) {
          //console.log("Data", response.data);
          setFinanceData(response.data.data.orderData);
          if((sortBy=='weekly'||sortBy=="monthly") && response.data.data.dateArr.length>0)
          {  setCategoryList(response.data.data.dateArr.map(({ temp_name }) => temp_name))
          if(!selectedWeekOrMonth)
          setSelectedWeekOrMonth(response.data.data.dateArr.map(({ temp_name }) => temp_name)[0])
          }
          setYearList(response.data.data.yearArr.map(({ temp_name }) => temp_name))
          if(!selectedYear)
          setSelectedYear(response.data.data.yearArr.map(({ temp_name }) => temp_name)[0])
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  const deleteFinance = async (id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Finance Data Deleted!",
          text: "Finance Data successfully deleted!",
          icon: "success",
        }).then(function () {
          const formData = {
            finance_id: id,
            is_deleted: true,
          };
          makePostRequest("finance/update", formData)
            .then((response) => {
              if (response.data) {
                console.log(response);
                getFinanceList(null,startDate,endDate,sortTable);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };

  useEffect(() => {
    localStorage.setItem("selectedModule", "Finance");
    getFinanceList(null,null,null,sortTable);
  }, [sortTable]);

  // Slider Modal
  const [ReceiptModal, setOpenReceiptModal] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const [eyeViewDocumentsList, setEyeViewDocumentsList] = React.useState([]);
  const handleClickReceiptModal = (scrollType, documentsList) => () => {
    setEyeViewDocumentsList(documentsList);

    setOpenReceiptModal(true);
    setScroll(scrollType);
  };

  const handleCloseReceiptModal = () => {
    setOpenReceiptModal(false);
  };

  const descriptionElementRef = React.useRef(null);
  // End

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Finance</h1>
              <div className="employeeFilter">
                <div className="prodFilter">
                  {/* <input
                    className="prodSearchInpt"
                    placeholder="Search"
                    onChange={(e) => getAllEmployeeList(e.target.value)}
                  /> */}
                </div>
                {/* <Button
                  className="pageTopMainBt"
                  onClick={() => navigate("/")}
                >
                  Add Employee
                </Button> */}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <div className="financeLeftMain">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="addReceiptMain">
                    <Formik
                      initialValues={initialValues || formValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <h1 className="reciptHeading">Add Receipt</h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="forWhiteDrop">
                                <FormikControl
                                  control="select"
                                  type="text"
                                  label="Select Category"
                                  placeholder="First Name"
                                  labelClass="reggistraLabel"
                                  name="category"
                                  options={dropdownOptions}
                                  className="prodSearchInpt"
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Amount"
                                placeholder="Enter Amount"
                                labelClass="reggistraLabel"
                                name="amount"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Tooltip title="From Date" arrow placement="top">
                            <label class="reggistraLabel">Bill Date</label>
                            <input
                              type="date"
                              className="prodSearchInpt"
                              onChange={(e)=>setBillDate(e.target.value)}
                              value={billDate}
                            />
                          </Tooltip>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              onChange={(e) => uploadImageAndFile(e)}
                            >
                              <label className="reggistraLabel">
                                Upload Receipt
                              </label>
                              <div className="uploReciptBt">
                                <UploadFileIcon />
                                Click Here To Upload
                                <input type="file" />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="uploadedReciptMain">
                                {uploadImgOrFile ? (
                                  <>
                                    {uploadImgOrFile.map((img, index) => (
                                      <div className="uploadedReciptInr">
                                        <h1>
                                          {img
                                            .split("/")
                                            .pop()
                                            .replaceAll("%", "")}
                                        </h1>
                                        <span></span>
                                        <CloseIcon
                                          onClick={() => deleteImage(index)}
                                        />
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div className="uploadedReciptInr">
                                      <span>No File Uploaded</span>
                                    </div>
                                  </>
                                )}
                                <span className="errorSt">{imageErrMsg}</span>
                                {/* <div className="uploadedReciptInr">
                                  <h1>File name Two</h1>
                                  <span></span>
                                  <CloseIcon />
                                </div>
                                <div className="uploadedReciptInr">
                                  <h1>File name Three</h1>
                                  <span></span>
                                  <CloseIcon />
                                </div> */}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <Button type="submit" className="pageTopMainBt">
                                Add Data
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className="financeRightMain">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="amountSpendMain">                    
                  
                    
                    <div className="amuntSpndLft">
                      {financeData.length > 0 ? (
                        <h1>
                          ₹ {parseFloat(financeData[0].total_amount).toFixed(2)}
                        </h1>
                      ) : (
                        <h1>₹ 0.0</h1>
                      )}
                      <p>Amount Spend</p>
                    </div>

                    <div className="innerTabsMain innerTabsMainFinance">
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={InnerTabs}
                              onChange={handleChangeInnerTabs}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Today" {...a11yPropsInnerTabs(0)} onClick={()=>getFinanceList(null, startDate, endDate,'today',selectedWeekOrMonth,selectedYear)}/>
                              <Tab label="Weekly" {...a11yPropsInnerTabs(1)} onClick={()=>getFinanceList(null, startDate, endDate,'weekly',selectedWeekOrMonth,selectedYear)}/>
                              <Tab label="Monthly" {...a11yPropsInnerTabs(2)} onClick={()=>getFinanceList(null, startDate, endDate,'monthly',selectedWeekOrMonth,selectedYear)} />
                              <Tab label="Yearly" {...a11yPropsInnerTabs(3)} onClick={()=>getFinanceList(null, startDate, endDate,'yearly',selectedWeekOrMonth,selectedYear)}/>
                              <Tab label="Custom" {...a11yPropsInnerTabs(4)} />
                            </Tabs>                            
                          </Box>  
                          <CustomTabPanel value={InnerTabs} index={0}>
                              <h1 className="financeDrowpDown">
                                Today, {moment().format("dddd DD MMM YYYY")}
                              </h1>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={1}>
                          <div className="financeDrowpDownSelect">
                                <select value={selectedYear} onChange={(e)=>{setSelectedYear(e.target.value);getFinanceList(null,startDate,endDate,sortTable,selectedWeekOrMonth,e.target.value)}}>
                                  <option>Select Year</option>
                                  {yearList.map((option)=>(
                                  <option key={option} value={option}>{option}</option>
                                ))}
                                </select>
                              </div>
                              <div className="financeDrowpDownSelect">
                                <select value={selectedWeekOrMonth} onChange={(e)=>{setSelectedWeekOrMonth(e.target.value);getFinanceList(null,startDate,endDate,sortTable,e.target.value,selectedYear)}}>
                                <option>Select Week</option>
                                {categoryList.map((option)=>(
                                  <option key={option} value={option}>{option}</option>
                                ))}
                                </select>
                              </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={2}>
                          <div className="financeDrowpDownSelect">
                                <select value={selectedYear} onChange={(e)=>{setSelectedYear(e.target.value);getFinanceList(null,startDate,endDate,sortTable,selectedWeekOrMonth,e.target.value)}}>
                                  <option>Select Year</option>
                                  {yearList.map((option)=>(
                                  <option key={option} value={option}>{option}</option>
                                ))}
                                </select>
                              </div>
                              <div className="financeDrowpDownSelect">
                              <select value={selectedWeekOrMonth} onChange={(e)=>{setSelectedWeekOrMonth(e.target.value);getFinanceList(null,startDate,endDate,sortTable,e.target.value,selectedYear)}}>
                              <option>Select Month</option>
                                  {categoryList.map((option)=>(
                                  <option key={option} value={option}>{option}</option>
                                ))}
                                </select>
                              </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={3}>
                              <div className="financeDrowpDownSelect">
                              <select value={selectedYear} onChange={(e)=>{setSelectedYear(e.target.value);getFinanceList(null,startDate,endDate,sortTable,selectedWeekOrMonth,e.target.value)}}>
                              <option>Select Year</option>
                                  {yearList.map((option)=>(
                                  <option key={option} value={option}>{option}</option>
                                ))}
                                </select>
                              </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={4}>
                              <div className="financeDrowpDownInput">
                                <input
                              type="date"
                              onChange={handleChangeStartDate}
                              value={startDate}
                            />
                                <b>To</b>

                                <input
                              type="date"
                              onChange={handleChangeEndDate}
                              value={endDate}
                            />
                              </div>
                          </CustomTabPanel>                        
                        </Box>
                      </div>

                    {/* <div className="newAmnttSpendiv">
                      <Grid container spacing={2}>
                        
                        <Grid item xs={12} md={4}>
                          <Select
                            className="prodSearchInpt bgTr"
                            value={sortTable}
                            onChange={statusChangeFun}
                            // label="Ticket"
                          >
                            <MenuItem value="null">Select</MenuItem>
                            <MenuItem value={"today"}>Today</MenuItem>
                            <MenuItem value={"weekly"}>Weekly</MenuItem>
                            <MenuItem value={"monthly"}>Monthly</MenuItem>
                            <MenuItem value={"yearly"}>Yearly</MenuItem>
                          </Select>
                        </Grid>

                        {sortTable!="today"?
                        <>
                        {sortTable=="weekly"?
                        <Grid item xs={12} md={4}>
                          <Select
                            className="prodSearchInpt bgTr"
                            value={selectedWeekOrMonth}
                            // onChange={statusChangeFun}
                            // label="Ticket"
                          >
                            <MenuItem value="null">Select Week</MenuItem>
                            {categoryList.map((opt)=>(
                            <MenuItem value={"today"}><>Week </>{opt} {moment().year(2024).week(opt).startOf('week').format('YYYY-MM-DD')} to {moment().year(2024).week(opt).endOf('week').format('YYYY-MM-DD')}</MenuItem>
                          ))}
                            </Select>
                        </Grid>:
                        <>
                        <Grid item xs={12} md={4}>
                          <Select
                            className="prodSearchInpt bgTr"
                            value={selectedWeekOrMonth}
                            // onChange={statusChangeFun}
                            // label="Ticket"
                          >
                            <MenuItem value="">Select Month</MenuItem>
                            {categoryList.map((opt)=>(
                            <MenuItem value={opt}>{opt}</MenuItem>
                          ))}
                          </Select>
                        </Grid>
                        </>
                        }
                           <Grid item xs={12} md={4}>
                          <Select
                            className="prodSearchInpt bgTr"
                            value={selectedYear}
                            // onChange={statusChangeFun}
                            // label="Ticket"
                          >
                            <MenuItem value="null">Select Year</MenuItem>
                            {yearList.map((opt)=>(
                            <MenuItem value={opt}>{opt}</MenuItem>
                          ))}
                          </Select>
                        </Grid>
                        </>:<></>}
                      </Grid>
                      <span>Or</span>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Tooltip title="From Date" arrow placement="top">
                            <input
                              type="date"
                              className="prodSearchInpt"
                              onChange={handleChangeStartDate}
                              value={startDate}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Tooltip title="To Date" arrow placement="top">
                            <input
                              type="date"
                              className="prodSearchInpt"
                              onChange={handleChangeEndDate}
                              value={endDate}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div> */}
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div
                    className="productDetails groceryTableMain tblScrl"
                    style={{ maxHeight: 500 }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="smalShortingOuter finaShort">
                              Bill Date{" "}
                              <ToggleButton value="check" className="smalSho">
                                <ImportExportIcon
                                  className="smalShorting"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </th>
                          <th>
                            <div className="smalShortingOuter finaShort">
                              Category Name{" "}
                              <ToggleButton value="check" className="smalSho">
                                <ImportExportIcon
                                  className="smalShorting"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </th>
                          <th>
                            <div className="smalShortingOuter finaShort">
                              Document Name
                              <ToggleButton value="check" className="smalSho">
                                <ImportExportIcon
                                  className="smalShorting"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </th>
                          <th>
                            <div className="smalShortingOuter finaShort">
                              Amount
                              <ToggleButton value="check" className="smalSho">
                                <ImportExportIcon
                                  className="smalShorting"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </th>
                          <th>
                            <div className="smalShortingOuter finaShort">
                              Actions
                              <ToggleButton value="check" className="smalSho">
                                <ImportExportIcon
                                  className="smalShorting"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {financeData.map((financeObj, index) => (
                          <tr key={index}>
                            <td>
                              {moment(financeObj.billdate).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>{financeObj.category}</td>
                            <td>
                              {financeObj.documents.map((opt) => (
                                <>{opt.split("/").pop().replaceAll("%", "")} </>
                              ))}
                            </td>
                            <td>
                              <b>
                                ₹ {parseFloat(financeObj.amount).toFixed(2)}
                              </b>
                            </td>
                            <td>
                              <div className="actionBtnOut">
                                <Tooltip title="View" arrow placement="top">
                                  <IconButton
                                    onClick={handleClickReceiptModal(
                                      "body",
                                      financeObj.documents
                                    )}
                                  >
                                    <img src={eye} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit" arrow placement="top">
                                  <IconButton>
                                    <img src={editIco} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" arrow placement="top">
                                  <IconButton
                                    onClick={() =>
                                      deleteFinance(financeObj.finance_id)
                                    }
                                  >
                                    <img src={deletIco} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  {financeData.length > 0 ? (
                    <div className="finaTotlAmunt">
                      Total Amount{" "}
                      <b>
                        ₹ {parseFloat(financeData[0].total_amount).toFixed(2)}
                      </b>
                    </div>
                  ) : (
                    <div className="finaTotlAmunt">
                      Total Amount <b>₹ 0.0</b>
                    </div>
                  )}
                </Grid>
                {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                  <Pagination count={10} variant="outlined" shape="rounded" />
                </Grid> */}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Slider Modal */}
      <div>
        <Dialog
          open={ReceiptModal}
          // onClose={handleCloseReceiptModal}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Heading Text Here
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseReceiptModal}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="modalSinlInputDiv">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <div className="productImgDiv reciptSlider">
                      <Carousel>
                        {eyeViewDocumentsList.map((option) => (
                          <div>
                            <img src={option} />

                            <p className="legend">
                              {option.split("/").pop().replaceAll("%", "")}
                            </p>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default FinancePage;
