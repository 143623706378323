// import "./App.css";
// import React, { useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Login from "./components/pages/Login/Login";
// import Home from "./components/pages/Home/Home";
// import Dashboard from "./components/pages/Dashboard/Dashboard";
// import Orders from "./components/pages/Orders/Orders";
// import ScannedOrder from "./components/pages/Orders/ScannedOrder";
// import Employees from "./components/pages/Employees/Employees";
// import EmployeesDetails from "./components/pages/Employees/EmployeesDetails";
// import ServicesConfiguration from "./components/pages/Services/ServicesConfiguration";
// import ProductDetails from "./components/pages/Services/ProductDetails";
// import NoticePrintFormat from "./components/pages/Employees/NoticePrintFormat";
// import EditEmployee from "./components/pages/Employees/EditEmployee";
// import ForgotPass from "./components/pages/Login/ForgotPass";
// import MaharajAfterOpeningWindow from "./components/pages/MaharajAfterOpeningWindow/MaharajAfterOpeningWindow";
// import SuperAdminDashboard from "./components/pages/SuperAdmin/SuperAdminDashboard";
// import CompanyAdmins from "./components/pages/SuperAdmin/CompanyAdmins";
// import ProfileDetails from "./components/pages/Dashboard/ProfileDetails";
// import EnterOtp from "./components/pages/Login/EnterOtp";
// import ChangePassword from "./components/pages/Login/ChangePassword";
// import Settings from "./components/pages/Settings/Settings";
// import AddCompanyAdmin from "./components/pages/SuperAdmin/AddCompanyAdmin";
// import EditCompanyAdmin from "./components/pages/SuperAdmin/EditCompanyAdmin";
// import AddEditCompany from "./components/pages/SuperAdmin/AddEditCompany";
// import AddCompany from "./components/pages/SuperAdmin/AddCompany";
// import EditCompany from "./components/pages/SuperAdmin/EditCompany";
// import AddEditEmployee from "./components/pages/Employees/AddEditEmployee";
// import AddEmployee from "./components/pages/Employees/AddEmployee";
// import Help from "./components/pages/Help/Help";
// import GroceryProductDetails from "./components/pages/Services/GroceryProductDetails";
// import VegetableProduuctDetails from "./components/pages/Services/VegetableProduuctDetails";
// import FoodProductDetails from "./components/pages/Services/FoodProductDetails";

// // for Mobile Screens
// import Splash from "./MobileScreens/Screens/Splash/Splash";
// import LoginMobile from "./MobileScreens/Screens/LoginMobile/LoginMobile";
// import DashboardPage from "./MobileScreens/Screens/Dashboard/DashboardPage";
// import BreakfastOrder from "./MobileScreens/Screens/BreakfastOrder";
// import OrderSummary from "./MobileScreens/Screens/OrderSummary";
// import OrderSuccess from "./MobileScreens/Screens/OrderSuccess";
// import ActiveOrders from "./MobileScreens/Screens/ActiveOrders";
// import ActiveOrderDetails from "./MobileScreens/Screens/ActiveOrderDetails";
// import ReorderTab from "./MobileScreens/Screens/ReorderTab";
// // import { Category } from "@mui/icons-material";

// import Lunch from "./MobileScreens/Screens/Lunch";
// import StarMarketHome from "./MobileScreens/Screens/Star Market/StarMarketHome";
// import ProductDetailsMobile from "./MobileScreens/Screens/ProductDetailsMobile";
// import SearchPage from "./MobileScreens/Screens/SearchPage";
// import EmptyCart from "./MobileScreens/Screens/EmptyCart";
// import Profile from "./MobileScreens/Screens/Profile/Profile";
// import ProfileDetailsMobile from "./MobileScreens/Screens/Profile/ProfileDetailsMobile";
// import WarningDetails from "./MobileScreens/Screens/Profile/WarningDetails";
// import StarCafeHome from "./MobileScreens/Screens/Star Cafe/StarCafeHome";
// import CafeProductDetails from "./MobileScreens/Screens/CafeProductDetails";
// import Category from "./MobileScreens/Screens/Star Market/Category";
// import { CartProvider } from "./MobileScreens/Screens/CartContext";
// import Dinner from "./MobileScreens/Screens/Dinner";
// import ReadyOrderDetails from "./MobileScreens/Screens/ReadyOrderDetails";
// import ProductDetailsStarMarket from "./components/pages/Services/ProductDetailsStarMarket";
// import ResetPassword from "./MobileScreens/Screens/ResetPassword";
// import OtpScreens from "./MobileScreens/Screens/OtpScreen";
// import { NotificationProvider } from "./MobileScreens/componentsMobile/NotificationContext";
// import Notifications from "./MobileScreens/Screens/Notifications";
// import NotificationIcon from "./MobileScreens/componentsMobile/NotificationIcon";
// function App() {

//   React.useEffect(() => {

//   }, []);

//   return (
//     <NotificationProvider>

//     <CartProvider>
//       <Router>

//         <Routes>
//           <Route path="/" element={<Login />} />
//           <Route path="Home" element={<Home />} />
//           <Route path="Dashboard" element={<Dashboard />} />
//           <Route path="Orders" element={<Orders />} />
//           <Route path="ScannedOrder/:id" element={<ScannedOrder />} />
//           <Route path="Employees" element={<Employees />} />
//           <Route
//             path="EmployeesDetails/:empId"
//             element={<EmployeesDetails />}
//           />
//           <Route
//             path="ServicesConfiguration"
//             element={<ServicesConfiguration />}
//           />
//           <Route path="ProductDetails/:id" element={<ProductDetails />} />
//           <Route path="NoticePrintFormat" element={<NoticePrintFormat />} />
//           <Route path="AddEditEmployee" element={<AddEditEmployee />} />
//           <Route path="AddEmployee" element={<AddEmployee />} />
//           <Route path="EditEmployee/:empId" element={<EditEmployee />} />
//           <Route path="ForgotPass" element={<ForgotPass />} />
//           <Route
//             path="MaharajAfterOpeningWindow"
//             element={<MaharajAfterOpeningWindow />}
//           />
//           <Route path="SuperAdminDashboard" element={<SuperAdminDashboard />} />
//           <Route path="CompanyAdmins" element={<CompanyAdmins />} />
//           <Route path="ProfileDetails" element={<ProfileDetails />} />
//           <Route path="EnterOtp" element={<EnterOtp />} />
//           <Route path="ChangePassword" element={<ChangePassword />} />
//           <Route path="Settings" element={<Settings />} />
//           <Route path="AddCompanyAdmin" element={<AddCompanyAdmin />} />
//           <Route
//             path="EditCompanyAdmin/:adminId"
//             element={<EditCompanyAdmin />}
//           />
//           <Route path="AddEditCompany" element={<AddEditCompany />} />
//           <Route path="AddCompany" element={<AddCompany />} />
//           <Route path="EditCompany/:companyId" element={<EditCompany />} />
//           <Route path="Help" element={<Help />} />
//           <Route
//             path="GroceryProductDetails/:id"
//             element={<GroceryProductDetails />}
//           />
//           <Route
//             path="VegetableProduuctDetails/:vegitable_id"
//             element={<VegetableProduuctDetails />}
//           />
//           <Route
//             path="FoodProductDetails/:id"
//             element={<FoodProductDetails />}
//           />

//           {/* For Mobile Screens */}
//           <Route path="Splash" element={<Splash />} />
//           <Route path="LoginMobile" element={<LoginMobile />} />
//           <Route path="DashboardPage" element={<DashboardPage />} />
//           <Route path="BreakfastOrder" element={<BreakfastOrder />} />
//           <Route path="OrderSummary" element={<OrderSummary />} />
//           <Route path="OrderSuccess" element={<OrderSuccess />} />
//           <Route path="ActiveOrders" element={<ActiveOrders />} />
//           <Route path="ActiveOrderDetails" element={<ActiveOrderDetails />} />
//           <Route path="ReorderTab" element={<ReorderTab />} />
//           <Route path="Category" element={<Category />} />
//           <Route path="Lunch" element={<Lunch />} />
//           <Route path="StarMarketHome" element={<StarMarketHome />} />
//           <Route path="ResetPassword" element={<ResetPassword />} />
//           <Route path="OtpScreen" element={<OtpScreens />} />
//           <Route path="Notifications" element={<Notifications />} />
//           <Route
//             path="ProductDetailsMobile"
//             element={<ProductDetailsMobile />}
//           />
//           <Route path="SearchPage" element={<SearchPage />} />
//           <Route path="EmptyCart" element={<EmptyCart />} />
//           <Route path="Profile" element={<Profile />} />
//           <Route
//             path="ProfileDetailsMobile"
//             element={<ProfileDetailsMobile />}
//           />
//           <Route path="WarningDetails" element={<WarningDetails />} />
//           <Route path="StarCafeHome" element={<StarCafeHome />} />
//           <Route path="CafeProductDetails" element={<CafeProductDetails />} />
//           <Route path="Dinner" element={<Dinner />} />
//           <Route path="ReadyOrderDetails" element={<ReadyOrderDetails />} />
//           <Route
//             path="ProductDetailsStarMarket/:id"
//             element={<ProductDetailsStarMarket />}
//           />
//         </Routes>
//         <LoginMobile/>
//       <Notifications/>
//       <NotificationIcon/>
//       </Router>

//     </CartProvider>

//     </NotificationProvider>
//   );
// }

// export default App;

import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NotificationProvider } from "./MobileScreens/componentsMobile/NotificationContext";
import MobileLayout from "./MobileLayout";

import Login from "./components/pages/Login/Login";
import Home from "./components/pages/Home/Home";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Orders from "./components/pages/Orders/Orders";
import ScannedOrder from "./components/pages/Orders/ScannedOrder";
import QRCodeScanner from "./components/pages/Orders/QRCodeScanner";
import QrReaderApp from "./components/pages/Orders/QrReaderApp";

import Employees from "./components/pages/Employees/Employees";
import EmployeesDetails from "./components/pages/Employees/EmployeesDetails";
import ServicesConfiguration from "./components/pages/Services/ServicesConfiguration";
import ProductDetails from "./components/pages/Services/ProductDetails";
import NoticePrintFormat from "./components/pages/Employees/NoticePrintFormat";
import EditEmployee from "./components/pages/Employees/EditEmployee";
import ForgotPass from "./components/pages/Login/ForgotPass";
import MaharajAfterOpeningWindow from "./components/pages/MaharajAfterOpeningWindow/MaharajAfterOpeningWindow";
import SuperAdminDashboard from "./components/pages/SuperAdmin/SuperAdminDashboard";
import CompanyAdmins from "./components/pages/SuperAdmin/CompanyAdmins";
import ProfileDetails from "./components/pages/Dashboard/ProfileDetails";
import EnterOtp from "./components/pages/Login/EnterOtp";
import ChangePassword from "./components/pages/Login/ChangePassword";
import Settings from "./components/pages/Settings/Settings";
import AddCompanyAdmin from "./components/pages/SuperAdmin/AddCompanyAdmin";
import EditCompanyAdmin from "./components/pages/SuperAdmin/EditCompanyAdmin";
import AddEditCompany from "./components/pages/SuperAdmin/AddEditCompany";
import AddCompany from "./components/pages/SuperAdmin/AddCompany";
import EditCompany from "./components/pages/SuperAdmin/EditCompany";
import AddEditEmployee from "./components/pages/Employees/AddEditEmployee";
import AddEmployee from "./components/pages/Employees/AddEmployee";
import Help from "./components/pages/Help/Help";
import GroceryProductDetails from "./components/pages/Services/GroceryProductDetails";
import VegetableProduuctDetails from "./components/pages/Services/VegetableProduuctDetails";
import FoodProductDetails from "./components/pages/Services/FoodProductDetails";

// for Mobile Screens
import Splash from "./MobileScreens/Screens/Splash/Splash";
import LoginMobile from "./MobileScreens/Screens/LoginMobile/LoginMobile";
import DashboardPage from "./MobileScreens/Screens/Dashboard/DashboardPage";
import BreakfastOrder from "./MobileScreens/Screens/BreakfastOrder";
import OrderSummary from "./MobileScreens/Screens/OrderSummary";
import OrderSuccess from "./MobileScreens/Screens/OrderSuccess";
import ActiveOrders from "./MobileScreens/Screens/ActiveOrders";
import ActiveOrderDetails from "./MobileScreens/Screens/ActiveOrderDetails";
import ActiveOrderDetailsMarket from "./MobileScreens/Screens/ActiveOrderDetailsMarket";
import ReorderTab from "./MobileScreens/Screens/ReorderTab";
import Lunch from "./MobileScreens/Screens/Lunch";
import StarMarketHome from "./MobileScreens/Screens/Star Market/StarMarketHome";
import ProductDetailsMobile from "./MobileScreens/Screens/ProductDetailsMobile";
import SearchPage from "./MobileScreens/Screens/SearchPage";
import EmptyCart from "./MobileScreens/Screens/EmptyCart";
import Profile from "./MobileScreens/Screens/Profile/Profile";
import ProfileDetailsMobile from "./MobileScreens/Screens/Profile/ProfileDetailsMobile";
import WarningDetails from "./MobileScreens/Screens/Profile/WarningDetails";
import StarCafeHome from "./MobileScreens/Screens/Star Cafe/StarCafeHome";
import CafeProductDetails from "./MobileScreens/Screens/CafeProductDetails";
// import MarketProductDetails from "./MobileScreens/Screens/MarketProductDetails";

import Category from "./MobileScreens/Screens/Star Market/Category";
import { CartProvider } from "./MobileScreens/Screens/CartContext";
import Dinner from "./MobileScreens/Screens/Dinner";
import ReadyOrderDetails from "./MobileScreens/Screens/ReadyOrderDetails";
import ProductDetailsStarMarket from "./components/pages/Services/ProductDetailsStarMarket";
import ResetPassword from "./MobileScreens/Screens/ResetPassword";
import OtpScreens from "./MobileScreens/Screens/OtpScreen";
import Notifications from "./MobileScreens/Screens/Notifications";
import NotificationIcon from "./MobileScreens/componentsMobile/NotificationIcon";
import SupportTickets from "./components/pages/SupportTickets/SupportTickets";
import OrderSummaryMarket from "./MobileScreens/Screens/OrderSummaryMarket";
import OrderSuccessMarket from "./MobileScreens/Screens/OrderSuccessMarket";
import MarketProductDetails from "./MobileScreens/Screens/MarketProductDetails";

import SwipeDemo from "./MobileScreens/Screens/SwipeDemo";
import SupportTicketsMobile from "./MobileScreens/Screens/SupportTicketMobile";
import SupportTicketDetails from "./MobileScreens/Screens/SupportTicketDetails";
import EmpClockInClockOut from "./ClockInClockOut/SystemPages/EmpClockInClockOut";
import TopHeader from "./ClockInClockOut/SyatemComponents/TopHeader";
import ClockInThankYou from "./ClockInClockOut/SystemPages/ClockInThankYou";
import ClockOutThankYou from "./ClockInClockOut/SystemPages/ClockOutThankYou";
import ClockOutReasons from "./ClockInClockOut/SystemPages/ClockOutReasons";
import ManagerTab from "./MobileScreens/Screens/ManagerFlow/ManagerTab";
import ManagerLeaveDetails from "./MobileScreens/Screens/ManagerFlow/ManagerLeaveDetails";
import ManagerEmpProfDetails from "./MobileScreens/Screens/ManagerFlow/ManagerEmpProfDetails";
import ManagerEmpProfPersonalDetails from "./MobileScreens/Screens/ManagerFlow/ManagerEmpProfPersonalDetails";
import ManagerEmpProfLeaveDetails from "./MobileScreens/Screens/ManagerFlow/ManagerEmpProfLeaveDetails";
import CFOWarningList from "./MobileScreens/Screens/CFO/CFOWarningList";
import ManagerEmpWarnDetails from "./MobileScreens/Screens/ManagerFlow/ManagerEmpWarnDetails";
import CFOWarningDetails from "./MobileScreens/Screens/CFO/CFOWarningDetails";
import OrderStatus from "./components/pages/OrderStatus/OrderStatus";
import FinancePage from "./components/pages/Finance/FinancePage";
import ReportsPage from "./components/pages/Reports/ReportsPage";
import ResetPasswordAdmin from "./components/pages/Login/ResetPasswordAdmin";
import ResetNewPassword from "./MobileScreens/Screens/ResetNewPassword";
import LeaveDetails from "./MobileScreens/Screens/Profile/LeaveDetails";
import WarningsTb from "./components/pages/Employees/WarningsTb";
import EmployeeOrderScan from "./components/pages/LocalEmployees/EmployeeOrderScan";
import LocalEmployees from "./components/pages/LocalEmployees/LocalEmployees";
import LineChartExample from "./components/pages/Reports/LineChartExample";
import SilentPrint from "./components/pages/Orders/SilentPrint";

function App() {
  useEffect(() => {
    // generateToken();
    // onMessage(messaging, (payload)=>{
    //     console.log("Payload===>"+payload)
    // })
  }, []);
  return (
    <NotificationProvider>
      <CartProvider>
        <Router>
          <MobileLayout>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="Home" element={<Home />} />
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="Orders" element={<Orders />} />
              <Route path="ScannedOrder/:id" element={<ScannedOrder />} />
              <Route path="Employees" element={<Employees />} />
              <Route path="LocalEmployees" element={<LocalEmployees />} />
              <Route path="LineChartExample" element={<LineChartExample />} />
              <Route path="SilentPrint" element={<SilentPrint />} />

              <Route
                path="EmployeesDetails/:empId"
                element={<EmployeesDetails />}
              />
              <Route
                path="ServicesConfiguration"
                element={<ServicesConfiguration />}
              />
              <Route path="ProductDetails/:id" element={<ProductDetails />} />
              <Route path="NoticePrintFormat" element={<NoticePrintFormat />} />
              <Route path="AddEditEmployee" element={<AddEditEmployee />} />
              <Route path="AddEmployee" element={<AddEmployee />} />
              <Route path="EditEmployee/:empId" element={<EditEmployee />} />
              <Route path="ForgotPass" element={<ForgotPass />} />
              <Route
                path="MaharajAfterOpeningWindow"
                element={<MaharajAfterOpeningWindow />}
              />
              <Route
                path="SuperAdminDashboard"
                element={<SuperAdminDashboard />}
              />
              <Route path="CompanyAdmins" element={<CompanyAdmins />} />
              <Route path="ProfileDetails" element={<ProfileDetails />} />
              <Route path="EnterOtp" element={<EnterOtp />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="Settings" element={<Settings />} />
              <Route path="AddCompanyAdmin" element={<AddCompanyAdmin />} />
              <Route
                path="EditCompanyAdmin/:adminId"
                element={<EditCompanyAdmin />}
              />
              <Route path="AddEditCompany" element={<AddEditCompany />} />
              <Route path="AddCompany" element={<AddCompany />} />
              <Route path="EditCompany/:companyId" element={<EditCompany />} />
              <Route path="Help" element={<Help />} />
              <Route
                path="GroceryProductDetails/:id"
                element={<GroceryProductDetails />}
              />
              <Route
                path="VegetableProduuctDetails/:vegitable_id"
                element={<VegetableProduuctDetails />}
              />
              <Route
                path="FoodProductDetails/:id"
                element={<FoodProductDetails />}
              />

              <Route path="OrderStatus" element={<OrderStatus />} />
              <Route path="FinancePage" element={<FinancePage />} />
              <Route path="ReportsPage" element={<ReportsPage />} />
              <Route
                path="ResetPasswordAdmin"
                element={<ResetPasswordAdmin />}
              />
              <Route path="WarningsTb" element={<WarningsTb />} />
              <Route path="EmployeeOrderScan" element={<EmployeeOrderScan />} />

              {/* For Mobile Screens */}
              <Route path="Splash" element={<Splash />} />
              <Route path="LoginMobile" element={<LoginMobile />} />
              <Route path="DashboardPage" element={<DashboardPage />} />
              <Route path="BreakfastOrder" element={<BreakfastOrder />} />
              <Route path="OrderSummary" element={<OrderSummary />} />
              <Route path="OrderSuccess" element={<OrderSuccess />} />
              <Route path="ActiveOrders" element={<ActiveOrders />} />
              <Route
                path="ActiveOrderDetails"
                element={<ActiveOrderDetails />}
              />
              <Route
                path="ActiveOrderDetailsMarket"
                element={<ActiveOrderDetailsMarket />}
              />
              <Route path="ReorderTab" element={<ReorderTab />} />
              <Route path="Category" element={<Category />} />
              <Route path="Lunch" element={<Lunch />} />
              <Route path="StarMarketHome" element={<StarMarketHome />} />
              <Route path="ResetPassword" element={<ResetPassword />} />
              <Route path="OtpScreen" element={<OtpScreens />} />
              <Route path="Notifications" element={<Notifications />} />
              <Route
                path="ProductDetailsMobile"
                element={<ProductDetailsMobile />}
              />
              <Route path="SearchPage" element={<SearchPage />} />
              <Route path="EmptyCart" element={<EmptyCart />} />
              <Route path="Profile" element={<Profile />} />
              <Route
                path="ProfileDetailsMobile"
                element={<ProfileDetailsMobile />}
              />
              <Route path="WarningDetails" element={<WarningDetails />} />
              <Route path="LeaveDetails" element={<LeaveDetails />} />
              <Route path="StarCafeHome" element={<StarCafeHome />} />
              <Route
                path="CafeProductDetails"
                element={<CafeProductDetails />}
              />
              <Route path="Dinner" element={<Dinner />} />
              <Route path="ReadyOrderDetails" element={<ReadyOrderDetails />} />
              <Route
                path="ProductDetailsStarMarket/:id"
                element={<ProductDetailsStarMarket />}
              />
              <Route
                path="MarketProductDetails"
                element={<MarketProductDetails />}
              />
              <Route path="SupportTickets" element={<SupportTickets />} />
              <Route path="SwipeDemo" element={<SwipeDemo />} />
              <Route
                path="OrderSummaryMarket"
                element={<OrderSummaryMarket />}
              />
              <Route
                path="OrderSuccessMarket"
                element={<OrderSuccessMarket />}
              />
              <Route path="QRCodeScanner" element={<QRCodeScanner />} />
              <Route path="QrReaderApp" element={<QrReaderApp />} />
              <Route
                path="SupportTicketsMobile"
                element={<SupportTicketsMobile />}
              />
              <Route
                path="SupportTicketDetails"
                element={<SupportTicketDetails />}
              />

              <Route path="ManagerTab" element={<ManagerTab />} />
              <Route
                path="ManagerLeaveDetails"
                element={<ManagerLeaveDetails />}
              />

              <Route
                path="ManagerEmpProfDetails"
                element={<ManagerEmpProfDetails />}
              />
              <Route
                path="ManagerEmpProfPersonalDetails"
                element={<ManagerEmpProfPersonalDetails />}
              />
              <Route
                path="ManagerEmpProfLeaveDetails"
                element={<ManagerEmpProfLeaveDetails />}
              />
              <Route
                path="ManagerEmpWarnDetails"
                element={<ManagerEmpWarnDetails />}
              />
              <Route path="CFOWarningList" element={<CFOWarningList />} />
              <Route path="CFOWarningDetails" element={<CFOWarningDetails />} />

              {/* For Clock In Clock Out Mobile Screens */}
              <Route path="TopHeader" element={<TopHeader />} />
              <Route
                path="EmpClockInClockOut"
                element={<EmpClockInClockOut />}
              />
              <Route path="ClockInThankYou" element={<ClockInThankYou />} />
              <Route path="ClockOutReasons" element={<ClockOutReasons />} />
              <Route path="ClockOutThankYou" element={<ClockOutThankYou />} />
              <Route path="ResetNewPassword" element={<ResetNewPassword />} />
            </Routes>
          </MobileLayout>
        </Router>
      </CartProvider>
    </NotificationProvider>
  );
}

export default App;
