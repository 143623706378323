import React,{useState} from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import avtar from "../../../assets/images/avtarPic.png";
import editIco from "../../../assets/images/edit-icon.svg";
import loaderImg from "../../../assets/images/logo.png";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makePostRequest,
  makeGetRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import swal from "sweetalert";
import { Upload } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddEditCompanyAdmin(props) {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [companyDropDown, setcompanyDropDown] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [profilePic, setProfilePic] = React.useState("");
  const [companyErrMsg, setCompanyErrMsg] = React.useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [duplicateEmailErrMsg, setDuplicateEmailErrMsg] = React.useState("");
  const [duplicateContactErrMsg, setDuplicateContactErrMsg] = React.useState("");
  const [employeeCodeErrMsg, setEmployeeCodeErrMsg] = React.useState("");


  //   For Validation
  const initialValues = {
    first_name: "",
    last_name: "",
    email_id: "",
    contact_no: "",
    date_of_joining: "",
    employee_code: "",
    // selectcompany: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    last_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    email_id: Yup.string()
      .matches(
        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email Id"
      )
      .required("Required"),
    contact_no: Yup.string().required("Required"),
    date_of_joining: Yup.string().required("Required"),
    employee_code: Yup.string().required("Required"),
  });

  const getAllCompanyList = () => {
    setShowLoader(true);
    setcompanyDropDown([]);
    companyDropDown.length = 0;
    makeGetRequest("/superadmin/getcompanylist")
      .then((response) => {
        if (response.data.data) {
          let companyObj;
          companyDropDown.push({ key: "Select Company", value: "" });
          if (response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              companyObj = { key: "", value: "" };
              companyObj.key = response.data.data[i].company_name;
              companyObj.value = response.data.data[i].company_id;
              // console.log(companyObj)
              companyDropDown.push(companyObj);
            }
            // console.log(companyDropDown)
            setcompanyDropDown(companyDropDown);
          } else setcompanyDropDown(companyDropDown);
          setUpload(!upload);
        }

        if (companyDropDown.length == 0) {
          swal(
            "Warning",
            "Company list is empty. Please add company first",
            "warning",
            { buttons: false, timer: 3000 }
          );
          navigate("/SuperAdminDashboard");
        }
        //
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  // const onSubmit = (values) => console.log("Form Data", values);

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;

    await uploadFileRequest("superadmin/upload", formData).then((response) => {
      fileUrl = response.data.imagepath;
    });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setProfileErrMsg("");

    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setProfilePic(logoUrl);
    } else {
      setProfileErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

    const [password, setPassword] = useState("");
    const [passwordLength, setPasswordLength] = useState(8);
    const [useSymbols, setUseSymbols] = useState(true);
    const [useNumbers, setUseNumbers] = useState(true);
    const [useLowerCase, setUseLowerCase] = useState(true);
    const [useUpperCase, setUseUpperCase] = useState(true);
    
  const generatePassword = () => {
      
        let charset = "";
        let newPassword = "";
    
        if (useSymbols) charset += "!@#$%^&*()";
        if (useNumbers) charset += "0123456789";
        if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
        if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    
        for (let i = 0; i < passwordLength; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }
    
        return newPassword;
    };

  const addOrUpdateAdmin = (values) => {
    setDuplicateEmailErrMsg("");
    setDuplicateContactErrMsg("");
    setEmployeeCodeErrMsg("");
    console.log(values);
    let tempSchema=values.company_id;
    if (values.company_id) {
      if (props.name) {
        
        makePostRequest("employee/updateemployee", values)
          .then((response) => {
            if (response.data) {
              swal("Success", "Information updated successfully", "success", {
                timer: 3000,
                buttons: false,
              });
              navigate("/CompanyAdmins");
            }
          })
          .catch((err) => {
            let errMsg = err.response.data.message;
            console.log(err.response.data.message);
            let errMsgArray = errMsg.split(" ");
            setDuplicateEmailErrMsg("");
            setDuplicateContactErrMsg("");

            if (errMsgArray.includes("emailid:"))
              setDuplicateEmailErrMsg("Email already exist");
            else if (errMsgArray.includes("contactno:"))
              setDuplicateContactErrMsg("Already exist");
          });
      } else {
        values.user_type = "Admin";
        values.status = true;
        values.password =generatePassword();
          // values.first_name + "@" + values.contact_no.slice(0, 4);
        values.schemaname = tempSchema.split("|||")[1]
          .replaceAll(" ", "")
          .toLowerCase();
        values.company_id = tempSchema.split("|||")[0];
        values.reporting_to=null;
        values.access_rights=[{ moduleName: 'AccessRights', status: true }, { moduleName: 'Employee', status: true }];
        console.log(values)
        makePostRequest("employee/insertemployee", values, values.schemaname)
          .then((response) => {
            if (response.data) {
              navigate("/CompanyAdmins");
            }
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
            let errMsgArray = errMsg.split(" ");
          
            if (errMsgArray.includes("emailid:"))
              setDuplicateEmailErrMsg("Email already exist");
            else if (errMsgArray.includes("contactno:"))
              setDuplicateContactErrMsg("Already exist");
            else if (errMsgArray.includes("Code:"))
              setEmployeeCodeErrMsg("Already exist");
          });
      }
    } else {
      setCompanyErrMsg("Please select company");
    }

    setDuplicateEmailErrMsg("");
    setDuplicateContactErrMsg("");
  };

  React.useEffect(() => {
    if (props.name) {
      makeGetRequest("employee/getemployee/" + props.name)
        .then((response) => {
          console.log(response);
          if (response.data) {
            console.log(response.data.data[0]);
            setFormValues(response.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          swal("Warning", err.data.message, "warning", {
            timer: 3000,
            buttons: false,
          });
        });
    } else getAllCompanyList();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className="employeeAddMain">
            <div className="addEdiProfDivOut">
              <div className="addEdiProfDivInnrIconAtt">
                <div className="addEdiProfDivInnr">
                  {profilePic ? <img src={profilePic} /> : <img src={avtar} />}
                </div>
                <Button
                  className="profEdiICoDiv"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                >
                  <img src={editIco} />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => uploadProfilePic(e)}
                  />
                </Button>
              </div>
              <span className="errorSt">{profileErrMsg}</span>
            </div>

            <div className="addEditformMain">
              <Formik
                initialValues={formValues || initialValues}
                validationSchema={validationSchema}
                onSubmit={addOrUpdateAdmin}
                enableReinitialize
              >
                {(formik) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          label="First Name"
                          placeholder="First Name"
                          labelClass="reggistraLabel"
                          name="first_name"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          type="lastname"
                          label="Last Name"
                          placeholder="Last Name"
                          labelClass="reggistraLabel"
                          name="last_name"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          type="email"
                          label="Email ID"
                          placeholder="Email ID"
                          labelClass="reggistraLabel"
                          name="email_id"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                        <span className="errorSt">{duplicateEmailErrMsg}</span>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          type="mobilenumber"
                          label="Mobile Number"
                          placeholder="Mobile Number"
                          labelClass="reggistraLabel"
                          name="contact_no"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                        <span className="errorSt">
                          {duplicateContactErrMsg}
                        </span>
                      </Grid>
                      {/* <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          type="jobtitle"
                          label="Job Title/Position"
                          placeholder="Job Title/Position"
                          labelClass="reggistraLabel"
                          name="designation_name"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                      </Grid> */}
                      <Grid item xs={12} md={4}>
                        {/* <FormikControl
                            control="input"
                            type="datejoining"
                            label="Date Of Joining"
                            placeholder="Date Of Joining"
                            labelClass="reggistraLabel"
                            name="date_of_joining"
                            className="prodSearchInpt"
                          /> */}
                        <FormikControl
                          control="date"
                          type="date"
                          label="Date Of Joining"
                          labelClass="reggistraLabel"
                          name="date_of_joining"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          type="department"
                          label="Department"
                          placeholder="Department"
                          labelClass="reggistraLabel"
                          name="department_name"
                          className="prodSearchInpt"
                        />
                      </Grid> */}
                      <Grid item xs={12} md={4}>
                        <FormikControl
                          control="input"
                          type="employeecode"
                          label="Employee Code"
                          placeholder="Employee Code"
                          labelClass="reggistraLabel"
                          name="employee_code"
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                        <span className="errorSt">{employeeCodeErrMsg}</span>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormikControl
                          control="select"
                          type="selectcompany"
                          label="Select Company"
                          labelClass="reggistraLabel"
                          name="company_id"
                          options={companyDropDown}
                          className="prodSearchInpt"
                          isRequired="true"
                        />
                        <span className="errorSt">{companyErrMsg}</span>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          type="submit"
                          className="modalBtSmallWidth formLongBtTopSpace"
                        >
                          {props.name ? <span>Update</span> : <span>Add</span>}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddEditCompanyAdmin;
