import React, { useState } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box, Divider, colors, setRef } from "@mui/material";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import WestIcon from "@mui/icons-material/West";
import avtarPic from "../../../assets/images/avtarPic.png";
import Pagination from "@mui/material/Pagination";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import exchaneBlack from "../../../assets/images/exchaneBlack.png";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import DataTable from "react-data-table-component";
// import editIco from "../../../assets/images/edit-icon.svg";
import PrintIcon from "@mui/icons-material/Print";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import pdf from "../../../assets/images/pdf.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import calender from "../../../assets/images/calender.svg";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import moment from "moment";
import swal from "sweetalert";
import { Flare, Print, Subject } from "@mui/icons-material";
import printLogo from "../../../assets/images/logo.png";
import loaderImg from "../../../assets/images/logo.png";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ImportExportIcon from "@mui/icons-material/ImportExport";

// Inner Tabs
function CustomTabPanelInnerTabs(props) {
  const { children, InnerTabs, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={InnerTabs !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {InnerTabs === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelInnerTabs.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsInnerTabs(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

// Star Activity Tabs
function CustomTabPanelStarActivity(props) {
  const { children, valueStarActivity, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueStarActivity !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {valueStarActivity === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelStarActivity.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsStarActivity(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// For Leave History Tab kkk
function CustomTabPanelLeaveHistory(props) {
  const { children, valueLeaveHistory, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueLeaveHistory !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {valueLeaveHistory === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanelLeaveHistory.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsLeaveHistory(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function EmployeesDetails(props) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // For Leave History Tab
  const [valueLeaveHistory, setValueLeaveHistory] = React.useState(0);

  const handleChangeLeaveHistory = (event, newValue) => {
    setValueLeaveHistory(newValue);
  };
  // End

  const navigate = useNavigate();
  const { empId } = useParams();
  const [employeeObj, setEmployeeObj] = React.useState({});
  const [upload, setUpload] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [spendStars, setSpendStars] = React.useState(0);
  const [availableStars, setAvailableStars] = React.useState(0);
  const [starAllowenceHistory, setStarAllowenceHistory] = React.useState([]);
  const [starReloadHistory, setStarRealodHistory] = React.useState([]);
  const [starRefundHistory, setStarRefundHistory] = React.useState([]);

  const [starSpendHistory, setStarSpendHistory] = React.useState([]);
  const [salaryDetails, setSalaryDetails] = React.useState({});
  const [masterStarAmount, setMasterStarAmount] = React.useState(0);
  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);

  const [isEditWarning, setIsEditWarning] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [warningData, setWarningData] = useState([]);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [expandDescription, setExpandDescription] = useState([]);

  const initialValues = {
    purchase_star_dollar_Amount: 0,
    purchase_star_quantity: 0,
  };

  const validationSchema = Yup.object({
    purchase_star_dollar_Amount: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid Amount"),
    purchase_star_quantity: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid Quantity"),
  });

  // Add or Edit Warning Start
  const initialValuesWarning = {
    subject: "",
    description: "",
    warningtype: "",
  };

  const validationSchemaWarning = Yup.object({
    subject: Yup.string()
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    description: Yup.string()
      .required("Required")
      .min(10, "Minimum 10 characters required!"),
    warningtype: Yup.string().required("Required"),
  });

  // Check Duplicate Value Start

  const handleCheckValue = (value) => {
    console.log("Value", value);
    setSubject(value);
    setDescription(value);
    console.log(value);
  };
  // Check Duplicate Value End

  const MAX_LENGTH = 100;
  const CompanyLogo = localStorage.getItem("companyLogo");
  // print page
  const handlePrint = (warnObj) => {
    // window.print();
    const printnotice = window.open("", "_blank");
    const cont = `<html>
    <head>
    <title >Warning For Employee</title>
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
    *{
      font-family: 'Poppins', sans-serif;
    }
    p{
      line-height: 24px;
    }
    @media print {
      .common-header {
        position: fixed;
        top: 0;
        width: 100%;
        padding: 20px;
        background: #f2f2f2;
        text-align: center;
      }
      .notice-content {
        margin-top: 150px;
      }
    }
    </style>
    </head>
      <body>
      
        <h2 style="text-align:center;color: #333333;letter-spacing: 2px;font-weight: 600;font-size: 30px;margin-bottom: 10px;">Warning Alert</h2>
        <div style="width: 100%; float: left; text-align:center;">
         <img style="width: 150px;" src= ${CompanyLogo} />
          
        </div>
        <div class="notice-content" 
              style="
              position: relative;
              width: "80%";
              padding: 150px 4%;
              border-radius: 15px;
              margin: 0 auto;
              text-align: left;
              box-shadow: 0px 0px 30px rgba(0,0,0,0.08);
              ">            
              <span style=" 
              width: 100%;
              float: left;
              font-size: 18px;
              color: #333333;
              font-weight: 700;
              margin-bottom: 5px;
              ">To,</span>
              <b style="height:24px;font-weight:500;margin-bottom:0;padding-bottom:0;text-transform: capitalize;margin-bottom: 0;float: left;width: 100%;">${employeeObj.first_name
      } ${employeeObj.last_name}</b>
              <span style="font-size:13px;margin:0;padding:0;text-transform: capitalize;">${employeeObj.department_name
      }</span>
        <span style="           
            font-size: 13px;
            width: 100%;
            float: left;
            font-weight: 600;
            letter-spacing: 1px;
            
            ">
            Warning ${"No." + warnObj.warning_id}
        </span>
        </p> <h4 style="
            font-size: 11px;
            margin: 5px 0 20px;
            display: block;
            letter-spacing: 1px;
            color: #4d4d4d;
            font-weight: 400;
            border-radius: 4px;    
            width: 100%;
            float: left;">
            ${moment(warnObj.created_at).format("DD-MM-YYYY hh:mm A")}</h4>
            
        <p style=" width: 100%;
        float: left;
        font-size: 15px;
        color: #000;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        margin: 20px 0 0;">
        <span style="
        font-size: 14px;
        color: #333;
        font-weight: 600;
        min-width: 65px;
        ">Subject:</span>
        <b style=" font-size: 14px;
        color: #333333;
        font-weight: 300;text-transform: capitalize;"> ${warnObj.subject} </b>
      
            <p style="width: 100%;
            float: left;
            font-size: 14px;
            color: #878787;
            font-weight: 300;
            line-height: 24px;
            margin-bottom: 15px;">
            ${warnObj.description}
            </p>
            <div style="width: 100%;
            float: left;
            margin: 20px 0;">
            <span style="margin-bottom: 8px;padding-bottom:0;float: left;width: 100%;">Regards,</span> <br>
            <b style="  margin-bottom: 0;">Office Management</b>
            <div style =" width: 100%;
                    float: left;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 5px;">
                            <span style=" font-size: 12px;
                            color: #878787;
                            font-weight: 300;">Warning letter for verbal Altertation</span>
                            <b style="font-size: 14px;
                            color: #333333;
                            font-weight: 500;">Authority Person</b>
                          </div>
            </div>
           
        </div>     
      </body>
    </html>`;
    printnotice.document.write(cont);
    printnotice.document.close();
    printnotice.print();
  };

  // print page end

  // Add / Edit warning Api Start

  const addWarning = async (values) => {
    console.log(values);

    if (isEditWarning) {
      //console.log("Status", values)
      makePostRequest("employee/updateemployeewarning", values)
        .then((response) => {
          if (response.data) {
            //console.log(response)
            setFormValues(null);
            setIsEditWarning(false);
            getWarningList();
          }
          setIsEditWarning(false);
          values.employee_id = parseInt(empId);
          values.subject = "";
          values.description = "";
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          swal("Warning", err.data.message, "warning", {
            timer: 3000,
            buttons: false,
          });
        });
    } else {
      console.log(values);
      values.employee_id = parseInt(empId);
      makePostRequest("employee/insertemployeewarning", values)
        .then((response) => {
          if (response.data) {
            console.log(response);
            getWarningList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // Add / Edit warning Api End

  //Edit Warning Api start
  const editWarning = (warnObj, scrollType) => {
    console.log("data", warnObj);
    setFormValues(warnObj);
    setIsEditWarning(true);
    setOpen2(true);
    setScroll(scrollType);
  };
  // Edit Warning End

  // Delete Warning Api Start
  const deleteWarning = async (warnObj) => {
    // console.log("success" +warning_id);
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
      timer: 3000,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Warning Deleted!",
          text: "Warning is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          //  let employee_id = parseInt(empId);
          let inputdata = {
            employee_id: warnObj.employee_id,
            warning_id: warnObj.warning_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);

          makePostRequest("employee/updateemployeewarning", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getWarningList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Warning Api End

  // get warning Api Start
  const [totalDamageCost, setTotalDamageCost] = React.useState(0);
  const [warningSearch, setWarningSearch] = React.useState(null);
  const [warningStatus, setWarningStatus] = React.useState(null);
  const [warningSort, setWarningSort] = React.useState("desc");
  const [selected, setSelected] = React.useState(false);

  function handleChangeSortBy() {
    setSelected(!selected);
    if (warningSort == "desc") {
      setWarningSort("asc");
      getWarningList(warningSearch, warningStatus, "asc");
    } else {
      setWarningSort("desc");
      getWarningList(warningSearch, warningStatus, "desc");
    }
  }

  const getWarningList = async (search, status, sort) => {
    if (!search) search = null;
    else setWarningSearch(search);
    if (!status) status = null;
    if (!sort) sort = null;

    console.log("success get warning");
    makeGetRequest(
      "/employee/getallemployeewarning/" +
      search +
      "/" +
      status +
      "/" +
      sort +
      "/" +
      empId
    )
      .then((response) => {
        if (response.data) {
          console.log("Data", response.data.data);
          // alert(response.data.data[0].total)
          setTotalDamageCost(response.data.data[0].total);
          setWarningData(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // get Warning Api End

  let clkindex = null;
  const [warningDescription, setWarningDescription] = useState();

  const viewDocs = (nId, index, description) => {
    const btn = document.getElementById("noticeSlideID" + index);
    if (btn) {
      if (clkindex !== null) {
        const prevDivindex = document.getElementById(
          "noticeSlideID" + clkindex
        );
        if (prevDivindex && prevDivindex === btn) {
          prevDivindex.classList.remove("expandfilesDiv");
        } else {
          prevDivindex.classList.add("expandfilesDiv");
        }
      }
      btn.classList.toggle("expandfilesDiv");
      clkindex = index;
    }
    setWarningDescription(description);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // Add or Create Warning End

  // Modal Main
  const [editWarningOpen, setEditWarningOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen2 = (scrollType) => () => {
    setOpen2(true);
    setScroll(scrollType);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const descriptionElementRef = React.useRef(null);
  // End

  // Modal Main
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);

    makeGetRequest("employee/getstarcount/" + empId)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setSpendStars(response.data.data.total_star_spent);
          setAvailableStars(response.data.data.total_stars);
          setUpload(!upload);
        }
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
    // getStarAmount();
  };

  const handleClose3 = () => {
    setOpen3(false);
    setPurchaseStarDollarAmount(0);
    setPurchaseStarQuantity(0);
    setPriceErrMsg("");
  };

  // End

  // Modal Main
  const [openMonthlyAllowance, setOpenMonthlyAllowance] = React.useState(false);

  const handleClickMonthlyAllowance = (scrollType) => () => {
    getStarAllowenceHistory();
    setOpenMonthlyAllowance(true);
    setScroll(scrollType);
  };

  const handleCloseMonthlyAllowance = () => {
    setOpenMonthlyAllowance(false);
    setPurchaseStarDollarAmount(0);
    setPurchaseStarQuantity(0);
    setPriceErrMsg("");
  };

  // Endss

  function myFunction(description) {
    console.log("my function call", description);
    setExpandDescription(description);
    let dots = document.getElementById("warningExpand");
    // var dots = document.getElementById("dots");
    // var moreText = document.getElementById("more");
    // var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      // btnText.innerHTML = "Read more";
      // moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      // btnText.innerHTML = "Read less";
      // moreText.style.display = "inline";
    }
  }

  function myFunctionLeave(description, index) {
    console.log("my function call", description);
    setExpandDescription(description);
    var dots = document.getElementById("dots" + index);
    var moreText = document.getElementById("more" + index);
    var btnText = document.getElementById("myBtn" + index);

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  // End

  const handleDownload = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [alignment, setAlignment] = React.useState("Monthly");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // Inner Tabs
  const [InnerTabs, setValueInnerTabs] = React.useState(0);

  const handleChangeInnerTabs = (event, newValueInnerTabs) => {
    setValueInnerTabs(newValueInnerTabs);
  };
  // End

  // Star Activity Tabs
  const [valueStarActivity, setValueStarActivity] = React.useState(0);
  const [priceErrMsg, setPriceErrMsg] = React.useState("");
  const [purchase_star_dollar_Amount, setPurchaseStarDollarAmount] =
    React.useState(0);
  const [purchase_star_quantity, setPurchaseStarQuantity] = React.useState(0);

  const handleChangeStarActivity = (event, newValue) => {
    setValueStarActivity(newValue);
  };
  // End

  function getStarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          if (response.data.data[0] == undefined) {
            swal(
              "Set Exchange Stars",
              "First set master star rate with dollar",
              "warning",
              {
                timer: 4000,
                buttons: false,
              }
            );
            navigate("/Dashboard");
          } else {
            setMasterStarAmount(response.data.data[0].start_count);
            setMasterDollarAmount(response.data.data[0].dollar_amount);
            setUpload(!upload);
          }
          // console.log(response.data.data[0]);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  function handleChangeDollarAmount(value) {
    setPriceErrMsg("");
    setPurchaseStarQuantity(value);

    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      if (value * masterDollarAmount <= employeeObj.balance_salary)
        setPurchaseStarDollarAmount(value * masterDollarAmount);
      else {
        setPurchaseStarDollarAmount(value * masterDollarAmount);
        setPriceErrMsg("You don't have enough balance");
      }
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPurchaseStarDollarAmount(0);
      }
    }
  }

  async function reloadStars() {
    if (priceErrMsg == "") {
      setShowLoader(true);
      let totalStars =
        parseInt(purchase_star_quantity) + parseInt(availableStars);
      let starInputData = {
        employee_id: employeeObj.employee_id,
        total_stars: totalStars,
        total_star_purchase: purchase_star_quantity,
        total_star_spent: spendStars,
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => { })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
      let inputdata = {
        employee_id: employeeObj.employee_id,
        star_reloaded: parseInt(purchase_star_quantity),
        amount_in_dollars: parseInt(purchase_star_dollar_Amount),
        reloaded_date: moment(),
      };
      await makePostRequest("employee/insertreloadhist/", inputdata)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });

      let balanceSalary =
        parseFloat(employeeObj.balance_salary) -
        parseInt(purchase_star_dollar_Amount);

      await makePostRequest("employee/updateemployeestatus", {
        employee_id: employeeObj.employee_id,
        balance_salary: balanceSalary,
      })
        .then((response) => {
          if (response.data) {
            setPurchaseStarDollarAmount(0);
            setPurchaseStarQuantity(0);
            setPriceErrMsg("");
            swal("Success", "Star Reloaded Successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            setOpen3(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
      await sendNotification(
        "Stars Reloaded",
        "Hi " +
        employeeObj.first_name +
        " " +
        purchase_star_quantity +
        " stars reloaded by admin in your account ",
        employeeObj.employee_id
      );
      getStarActivityDetails();
      getStarSpendHistory(selectedOrderType, startDate, endDate);
      setShowLoader(false);
    }
  }

  function getStarActivityDetails() {
    setShowLoader(true);
    makeGetRequest("employee/getemployeesalarydetails/" + empId)
      .then((response) => {
        if (response) {
          console.log("Start Activity Details");
          console.log(response);
          setSalaryDetails(response.data.data[0]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  const [basicStarAllowence, setBasicStarAllowence] = React.useState(0);
  const [additionalAllowence, setAdditionalAllowence] = React.useState(0);

  async function getStarAllowenceHistory() {
    setShowLoader(true);
    await makeGetRequest("employee/getallowancehist/" + empId)
      .then((response) => {
        console.log(response);
        if (response.data.data.groupedData.length > 0) {
          console.log("Start allowance Details");
          setStarAllowenceHistory(response.data.data);
          setBasicStarAllowence(
            response.data.data.groupedData[0].star_allocated
          );
          setAdditionalAllowence(
            response.data.data.totalSum -
            response.data.data.groupedData[0].star_allocated
          );
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  async function getStarReloadHistory(fromDate, endDate) {
    setShowLoader(true);
    if (!fromDate) fromDate = null;
    if (!endDate) fromDate = null;
    await makeGetRequest(
      "employee/getreloadhist/" + empId + "/" + fromDate + "/" + endDate
    )
      .then((response) => {
        if (response.data.data.groupedData.length > 0) {
          console.log("Start Reload Details");
          setStarRealodHistory(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  const [selectedOrderType, setSelectedOrdertype] = React.useState(null);
  async function getStarSpendHistory(orderType, fromDate, endDate) {
    setShowLoader(true);
    if (!orderType) orderType = null;
    else setSelectedOrdertype(orderType);
    if (!fromDate) fromDate = null;
    if (!endDate) fromDate = null;
    await makeGetRequest(
      "starcafe/getorderemployee/" +
      orderType +
      "/null/" +
      empId +
      "/0/" +
      fromDate +
      "/" +
      endDate
    )
      .then((response) => {
        if (response.data.data) {
          console.log("Start Spend History");
          console.log(response.data.data);
          setStarSpendHistory(response.data.data);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  async function handleChangeStartDate(newDate) {
    setStartDate(newDate.format("YYYY-MM-DD"));
    getStarSpendHistory(
      selectedOrderType,
      newDate.format("YYYY-MM-DD"),
      endDate
    );
  }

  async function handleChangeEndDate(date) {
    setEndDate(date.format("YYYY-MM-DD"));
    getStarSpendHistory(
      selectedOrderType,
      startDate,
      date.format("YYYY-MM-DD")
    );
  }

  async function handleChangeReloadStartDate(newDate) {
    setStartDate(newDate.format("YYYY-MM-DD"));
    getStarReloadHistory(newDate.format("YYYY-MM-DD"), endDate);
  }

  async function handleChangeReloadEndDate(date) {
    setEndDate(date.format("YYYY-MM-DD"));
    getStarReloadHistory(startDate, date.format("YYYY-MM-DD"));
  }

  async function editMonthlyAllowence() {
    if (priceErrMsg == "") {
      let starInputData = {
        employee_id: employeeObj.employee_id,
        monthly_allowance:
          parseInt(salaryDetails.monthly_allowance) +
          parseInt(purchase_star_quantity),
        total_stars:
          parseInt(employeeObj.total_stars) + parseInt(purchase_star_quantity),
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => {
          let inputdata = {
            employee_id: employeeObj.employee_id,
            star_allocated: parseInt(purchase_star_quantity),
            amount_in_dollars: parseInt(purchase_star_dollar_Amount),
            allowence_date: moment(),
          };
          makePostRequest("employee/insertallowancehist/", inputdata).then(
            (response) => {
              if (response.data.data) {
                setPurchaseStarDollarAmount(0);
                setPurchaseStarQuantity(0);
                setPriceErrMsg("");
                setOpenMonthlyAllowance(false);
              }
            }
          );
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
        });
      getStarActivityDetails();
      getStarSpendHistory(selectedOrderType, startDate, endDate);
    }
  }

  const [leaveApproveRejectList, setLeaveApproveRejectList] = useState([]);
  const [leavePending, setLeavePending] = useState([]);

  const getLeaveList = () => {
    //getstarcount/:employeeid
    setShowLoader(true);
    makeGetRequest("employee/getemployeeleave/" + empId)
      .then((response) => {
        if (response.data) {
          console.log("LeaveList", response.data.data);
          setLeaveApproveRejectList(response.data.data);
          setLeavePending(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
        let errMsg = err.response.data.message;
      });
  };

  const [commentErrorMsg, setCommentErrorMsg] = useState("");
  const [comment, setComment] = useState();

  const approveRejectFunction = async (status, id, empid) => {
    setShowLoader(true);
    setCommentErrorMsg("");
    if (comment === undefined && status === "Rejected") {
      setCommentErrorMsg("Comment is Required");
      setShowLoader(false);
    } else {
      console.log(comment);
      const formData = {
        employee_id: empid,
        leave_history_id: id,
        status: status,
        comments: comment,
      };
      console.log("Leave ApproveReject", formData);
      await makePostRequest("employee/updateemployeeleave/", formData)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
            getLeaveList();
          }
          setShowLoader(false);
          setComment();
          setCommentErrorMsg("");
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }
  };

  const [documentErrorMsg, setDocumentErrMsg] = useState("");
  const [documentsList, setDocumentsList] = useState([]);

  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImageOrDocument(fileData) {
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|docx|DOCX|doc|DOC|pdf|PDF|xls|XLS|txt|TXT|xlsx|XLSX)$/i
      )
    )
      return true;
    else return false;
  }

  async function uploadDocuments(e, type) {
    setDocumentErrMsg("");
    setShowLoader(true);
    let documentObj = { date: "", file: "", type: "" };
    const file = e.target.files[0];
    console.log(e.target.files[0].name);
    let validateRes = false;
    if (await validateImageOrDocument(file)) {
      var imageUrl = await uploadImageOrFile(file);
      // setProfilePic(imageUrl);
      if (imageUrl) {
        let dateTime =
          new Date().toLocaleDateString() +
          " " +
          new Date().toLocaleTimeString();
        documentObj.date = dateTime;
        documentObj.file = imageUrl;
        documentObj.type = type;
        documentObj.name = e.target.files[0].name;
        // const newDocument =  { "type": type, "date": moment().format('YYYY-MM-DD'), "file": imageUrl }
        documentsList.push(documentObj);
        setDocumentsList(documentsList);

        await makePostRequest("employee/updateemployeestatus", {
          employee_id: employeeObj.employee_id,
          documents: documentsList,
        })
          .then((response) => {
            if (response.data.data) {
              // swal("Success", "Document Uploaded", "success", {
              //   timer: 3000,
              //   buttons: false,
              // });
              setDocumentsList(response.data.data.documents);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setDocumentErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

  async function deleteDocument(index) {
    console.log(index + " ");
    documentsList.splice(index, 1);
    console.log(documentsList);
    setShowLoader(true);
    await makePostRequest("employee/updateemployeestatus", {
      employee_id: employeeObj.employee_id,
      documents: documentsList,
    })
      .then((response) => {
        if (response.data) {
          // swal("Success", "Document Deleted", "success", {
          //   timer: 3000,
          //   buttons: false,
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setShowLoader(false);
  }

  async function sendNotification(title, message, employee_id) {
    let inputData = {
      title: title,
      message: message,
      resident_id: employee_id,
    };

    makePostRequest("employee/notificationtoadmin", inputData)
      .then((response) => { })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }

  React.useEffect(() => { }, [documentsList]);

  React.useEffect(() => {
    console.log(empId);
    setShowLoader(true);
    makeGetRequest("employee/getemployee/" + empId)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data[0]);
          if (response.data.data[0].department_id) {
            setEmployeeObj(response.data.data[0]);
            if (response.data.data[0].documents)
              setDocumentsList(response.data.data[0].documents);

            console.log(response.data.data[0].documents);
            setUpload(!upload);
          } else {
            swal(
              "Update basic profile",
              "Please update basic profile info",
              "warning",
              { timer: 4000, buttons: false }
            );
            navigate("/EditEmployee/" + response.data.data[0].employee_id);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });

    getStarAmount();
    getStarAllowenceHistory();

    if (open2) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (openMonthlyAllowance) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    // getWarningList();
  }, [open2, openMonthlyAllowance, open3]);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => navigate("/Employees")} /> Employee
                Details
              </h1>
              {/* <Button className="pageTopMainBt">Demo Button</Button> */}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <div className="employeeProfileLeft">
              <div className="employeeTopDiv">
                {JSON.parse(localStorage.getItem("accessRights") != null) ? (
                  <>
                    {JSON.parse(localStorage.getItem("accessRights")).map(
                      (module) => (
                        <>
                          {module.moduleName == "EmployeeModification" &&
                            module.status == true ? (
                            <div className="profEditIco">
                              <Tooltip title="Edit" arrow placement="top">
                                <IconButton
                                  onClick={() =>
                                    navigate("/EditEmployee/" + empId)
                                  }
                                >
                                  <img src={editIco} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    )}
                  </>
                ) : (
                  <></>
                )}
                <div className="employeePro">
                  <div className="employeePicDiv">
                    {employeeObj.profile_pic ? (
                      <img src={employeeObj.profile_pic} />
                    ) : (
                      <img src={avtarPic} />
                    )}
                  </div>
                </div>
                <div className="showDetailsEmployee">
                  <h1>
                    {employeeObj.first_name} {employeeObj.last_name}
                  </h1>
                  <span>{employeeObj.designation_name}</span>
                  <p>{employeeObj.department_name}</p>
                  <label className="labSpaceBot">
                    Monthly Star Allowance{" "}
                    <b className="listMainStar">
                      <StarIcon /> {employeeObj.monthly_allowance}
                    </b>
                  </label>
                  <label>
                    Available stars{" "}
                    <b className="listMainStar">
                      <StarIcon /> {employeeObj.total_stars}
                    </b>
                  </label>
                  <Button
                    className="proBtMain"
                    onClick={handleClickOpen3("body")}
                  >
                    Reload Stars
                  </Button>
                </div>
              </div>

              <div className="addEmploAccordingMain">
                <div className="settingInr">
                  <div className="accordingMain">
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange2("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <h3 class="settheading">
                          <PermContactCalendarIcon /> Basic Information
                        </h3>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="allownceFormOutMain">
                          <h1 className="basicHeading">Basic Details</h1>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>First Name</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.first_name}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.first_name}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Last Name</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.last_name}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.last_name}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="mainListCont mainListInrCont">
                                <label>Email Id</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.email_id}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.email_id}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="mainListCont mainListInrCont">
                                <label>Mobile Number</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.contact_no}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.contact_no}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Divider className="devider" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <h1 className="basicHeading">
                                Employment Details
                              </h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Designation</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.designation_name}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.designation_name}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Department</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.department_name}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.department_name}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>
                                  Date of Joinging / Contract Start Date
                                </label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={moment(
                                      employeeObj.date_of_joining
                                    ).format("DD/MM/YYYY")}
                                    arrow
                                    placement="top"
                                  >
                                    {moment(employeeObj.date_of_joining).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Passport Number</label>
                                {employeeObj.passport_no ? (
                                  <span className="mainListSubTx">
                                    <Tooltip
                                      title={employeeObj.passport_no}
                                      arrow
                                      placement="top"
                                    >
                                      {employeeObj.passport_no}
                                    </Tooltip>
                                  </span>
                                ) : (
                                  <span className="mainListSubTx">-</span>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Employee Code</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.employee_code}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.employee_code}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Reporting To</label>
                                <span className="mainListSubTx">
                                  <Tooltip
                                    title={employeeObj.reporting_to_first_name}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.reporting_to_first_name}{" "}
                                    {employeeObj.reporting_to_last_name}
                                  </Tooltip>
                                </span>
                              </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Fixed Salary (INR)</label>
                                <span className="mainListSubTx">
                                  {/* <Tooltip
                                    title={employeeObj.fixed_salary}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.fixed_salary}{" "}
                                  </Tooltip> */}
                                  ******
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="mainListCont mainListInrCont">
                                <label>Balance Salary (INR)</label>
                                <span className="mainListSubTx">
                                  {/* <Tooltip
                                    title={employeeObj.balance_salary}
                                    arrow
                                    placement="top"
                                  >
                                    {employeeObj.balance_salary}
                                  </Tooltip> */}
                                  ******
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange2("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <h3 class="settheading">
                          <ContactPhoneIcon /> Emergency Contacts
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="allownceFormOutMain">
                          {employeeObj.emergency_contact_1 ? (
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <h1 className="basicHeading">
                                  Emergency Contact 1
                                </h1>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="mainListCont mainListInrCont">
                                  <label>Mobile Number</label>
                                  <span className="mainListSubTx">
                                    <Tooltip
                                      title="Priyanka"
                                      arrow
                                      placement="top"
                                    >
                                      {employeeObj.emergency_contact_1}
                                    </Tooltip>
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="mainListCont mainListInrCont">
                                  <label>Address</label>
                                  <span className="mainListSubTx">
                                    <Tooltip
                                      title="Parab"
                                      arrow
                                      placement="top"
                                    >
                                      {employeeObj.emergency_address_1}
                                    </Tooltip>
                                  </span>
                                </div>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <Divider className="devider" />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <h1 className="basicHeading">
                                  Emergency Contact 2
                                </h1>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="mainListCont mainListInrCont">
                                  <label>Mobile Number</label>
                                  <span className="mainListSubTx">
                                    <Tooltip
                                      title="Priyanka"
                                      arrow
                                      placement="top"
                                    >
                                      {employeeObj.emergency_contact_2}
                                    </Tooltip>
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="mainListCont mainListInrCont">
                                  <label>Address</label>
                                  <span className="mainListSubTx">
                                    <Tooltip
                                      title="Parab"
                                      arrow
                                      placement="top"
                                    >
                                      {employeeObj.emergency_address_2}
                                    </Tooltip>
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <h4 className="notAddedx">
                                  <ErrorOutlineIcon /> Emergency Contact Not
                                  Added
                                </h4>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange2("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <h3 class="settheading">
                          <PeopleAltIcon /> Family Information
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="allownceFormOutMain">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <h1 className="basicHeading">
                                Family member Information{" "}
                                <span className="addedMemCount">
                                  (
                                  <b>
                                    {employeeObj.family_info
                                      ? employeeObj.family_info.length
                                      : 0}
                                  </b>{" "}
                                  Member Added)
                                </span>
                              </h1>
                            </Grid>
                            {employeeObj.family_info ? (
                              <>
                                {employeeObj.family_info.map((famMem) => (
                                  <>
                                    <Grid item xs={12} md={6}>
                                      <div className="mainListCont mainListInrCont">
                                        <label>Name</label>
                                        <span className="mainListSubTx">
                                          <Tooltip
                                            title={famMem.name}
                                            arrow
                                            placement="top"
                                          >
                                            {famMem.name}
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="mainListCont mainListInrCont">
                                        <label>Mobile Number</label>
                                        <span className="mainListSubTx">
                                          <Tooltip
                                            title={famMem.contact_no}
                                            arrow
                                            placement="top"
                                          >
                                            {famMem.contact_no}
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="mainListCont mainListInrCont">
                                        <label>Relation</label>
                                        <span className="mainListSubTx">
                                          <Tooltip
                                            title={famMem.relation}
                                            arrow
                                            placement="top"
                                          >
                                            {famMem.relation}
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="mainListCont mainListInrCont">
                                        <label>Address</label>
                                        <span className="mainListSubTx">
                                          <Tooltip
                                            title={famMem.address}
                                            arrow
                                            placement="top"
                                          >
                                            {famMem.address}
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="mainListCont mainListInrCont">
                                        <label>Date Of Birth</label>
                                        <span className="mainListSubTx">
                                          {/* <Tooltip title="Parab" arrow placement="top"> */}
                                          {moment(famMem.bith_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                          {/* </Tooltip> */}
                                        </span>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="mainListCont mainListInrCont">
                                        <label>Age</label>
                                        <span className="mainListSubTx">
                                          <Tooltip
                                            title={famMem.age}
                                            arrow
                                            placement="top"
                                          >
                                            {famMem.age}
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <Divider className="devider" />
                                    </Grid>
                                  </>
                                ))}
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} md={12}>
                                  <h4 className="notAddedx">
                                    <ErrorOutlineIcon /> Family Information Not
                                    Added
                                  </h4>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={8}>
            <div className="profileTabMain">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    aria-label="basic tabs example"
                  >
                    <Tab label="Timesheet" {...a11yProps(0)} />
                    <Tab
                      label="Warnings"
                      {...a11yProps(1)}
                      onClick={() => getWarningList(null, null, null)}
                    />
                    <Tab label="Documents" {...a11yProps(2)} />
                    <Tab
                      label="Leave History"
                      {...a11yProps(2)}
                      onClick={() => getLeaveList()}
                    />
                    <Tab
                      label="Star Activity"
                      {...a11yProps(2)}
                      onClick={() => {
                        getStarActivityDetails();
                        getStarSpendHistory(
                          selectedOrderType,
                          startDate,
                          endDate
                        );
                      }}
                    />
                  </Tabs>
                </Box>
                {/* CustomPanel For Tmesheet Start  */}
                <CustomTabPanel
                  value={value}
                  index={0}
                  className="tabContentMain"
                >
                  <div className="tabContDiv">
                    <div className="detailsTableMain">
                      <div className="innerTabsMain">
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={InnerTabs}
                              onChange={handleChangeInnerTabs}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Today" {...a11yPropsInnerTabs(0)} />
                              <Tab label="Weekly" {...a11yPropsInnerTabs(1)} />
                              <Tab label="Monthly" {...a11yPropsInnerTabs(2)} />
                              <Tab label="Yearly" {...a11yPropsInnerTabs(3)} />
                              <Tab label="Custom" {...a11yPropsInnerTabs(4)} />
                            </Tabs>
                          </Box>
                          <CustomTabPanel value={InnerTabs} index={0}>
                            <div className="tableMainEmployee groceryTableMain">
                              <h1 className="employeeTbHeading">
                                Today, {moment().format("dddd DD MMM YYYY")}
                              </h1>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Clock-in</th>
                                        <th>Clock-out</th>
                                        <th>Total Hours</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="todayRecord">
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                        <td>
                                          <div>
                                            <img src={editIco} />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={1}>
                            <div className="tableMainEmployee groceryTableMain">
                              <div className="employeeTbHeadingSelect">
                                <select>
                                  <option>January Week 1</option>
                                  <option>January Week 2</option>
                                  <option>January Week 3</option>
                                  <option>January Week 4</option>
                                </select>
                              </div>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Clock-in</th>
                                        <th>Clock-out</th>
                                        <th>Total Hours</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={2}>
                            <div className="tableMainEmployee groceryTableMain">
                              <div className="employeeTbHeadingSelect">
                                <select>
                                  <option>January 2024</option>
                                  <option>December 2023</option>
                                  <option>November 2023</option>
                                  <option>October 2023</option>
                                </select>
                              </div>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Clock-in</th>
                                        <th>Clock-out</th>
                                        <th>Total Hours</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={3}>
                            <div className="tableMainEmployee groceryTableMain">
                              <div className="employeeTbHeadingSelect">
                                <select>
                                  <option>Year 2023 - 2024</option>
                                  <option>Year 2022 - 2023</option>
                                  <option>Year 2021 - 2022</option>
                                  <option>Year 2020 - 2021</option>
                                </select>
                              </div>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Clock-in</th>
                                        <th>Clock-out</th>
                                        <th>Total Hours</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className="paginationOuterMain"
                                >
                                  <Pagination
                                    count={10}
                                    variant="outlined"
                                    shape="rounded"
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={InnerTabs} index={4}>
                            <div className="tableMainEmployee groceryTableMain">
                              <div className="employeeTbHeadingInpu">
                                <input type="date" />
                                <b>To</b>
                                <input type="date" />
                                {/* <select>
                                  <option>Year 2023 - 2024</option>
                                  <option>Year 2022 - 2023</option>
                                  <option>Year 2021 - 2022</option>
                                  <option>Year 2020 - 2021</option>
                                </select> */}
                              </div>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Clock-in</th>
                                        <th>Clock-out</th>
                                        <th>Total Hours</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Monday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Tuesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Wednesday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Thursday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Friday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Saturday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>07/05/2016</td>
                                        <td>08:00 am</td>
                                        <td>01:08 pm</td>
                                        <td>9 Hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className="paginationOuterMain"
                                >
                                  <Pagination
                                    count={10}
                                    variant="outlined"
                                    shape="rounded"
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>
                        </Box>
                      </div>

                      {/* <div className="detailsTableFilterSec">
                        <div className="responsiveActionBtn">
                          <Tooltip title="Edit" arrow placement="top">
                            <IconButton
                              aria-label="delete"
                              size="large"
                              id="basic-button"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Daily</MenuItem>
                            <MenuItem onClick={handleClose}>Weekly</MenuItem>
                            <MenuItem onClick={handleClose}>Monthly</MenuItem>
                            <MenuItem onClick={handleClose}>Yearly</MenuItem>
                            <MenuItem onClick={handleClose}>Custom</MenuItem>
                          </Menu>
                        </div>

                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          className="detailsTableFilterSecActionInr"
                        >
                          <ToggleButton value="Daily" className="filterBtn">
                            Daily
                          </ToggleButton>
                          <ToggleButton value="Weekly" className="filterBtn">
                            Weekly
                          </ToggleButton>
                          <ToggleButton value="Monthly" className="filterBtn">
                            Monthly
                          </ToggleButton>
                          <ToggleButton value="Yearly" className="filterBtn">
                            Yearly
                          </ToggleButton>
                          <ToggleButton value="Custom" className="filterBtn">
                            Custom
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <div>
                          <select className="prodSearchInpt inputBackground">
                            <option>Month</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                          </select>
                        </div>
                      </div>
                      <div className="tableMainEmployee">
                        <h1 className="employeeTbHeading">
                          Today, 16th January 2024
                        </h1>
                        <div>
                          <DataTable pagination columns={columns} data={data} />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </CustomTabPanel>
                {/* CustomPanel For Tmesheet End  */}

                {/* CustomPanel For Warnings Start  */}
                <CustomTabPanel
                  value={value}
                  index={1}
                  className="tabContentMain"
                >
                  <div className="tabContDiv">
                    <div className="detailsTableMain">
                      <div className="detailsTableFilterSec monthlyAllowanceMain warNewDis">
                        <div className="addStarContD allowncWithEdit">
                          <div className="allowncWithEditInner">
                            <b className="startCount">
                              <CurrencyRupeeIcon /> {totalDamageCost}
                            </b>
                            <p>Total Damage Cost</p>
                          </div>
                        </div>

                        {/* <Button
                          className="pageTopMainBt"
                          onClick={handleClickOpen2("body")}
                        >
                          Issue New Warning
                        </Button> */}
                      </div>
                      <div className="tableMainEmployee">
                        <div className="warnNewFilt">
                          <div className="visitorSearch">
                            <div className="prodFilter">
                              <input
                                className="prodSearchInpt"
                                placeholder="Search"
                                onChange={(e) =>
                                  getWarningList(
                                    e.target.value,
                                    warningStatus,
                                    warningSort
                                  )
                                }
                              />
                            </div>
                            <div className="sortingIcoOut">
                              <ToggleButton
                                value="check"
                                selected={selected}
                                onChange={() => {
                                  handleChangeSortBy();
                                }}
                              >
                                <ImportExportIcon
                                  className="visitorFilterIco"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </div>
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker label="From Date" />
                            </DemoContainer>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker label="To Date" />
                            </DemoContainer>
                          </LocalizationProvider> */}
                        </div>

                        <div
                          className="warningMain"
                          style={{ overflowY: "auto", maxHeight: 800 }}
                        >
                          {warningData.map((warnObj, index) => (
                            <div
                              className="mainListDiv"
                              key={warnObj.warning_id}
                            >
                              <Tooltip
                                title="Print Warning"
                                arrow
                                placement="top"
                              >
                                <Button
                                  className="warninPrint"
                                  onClick={() => handlePrint(warnObj)}
                                >
                                  <PrintIcon />
                                </Button>
                              </Tooltip>
                              {/* <Tooltip
                                className="warninEdit"
                                title="Edit"
                                arrow
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => editWarning(warnObj, "body")}
                                >
                                  <img src={editIco} />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                className="warninDelete"
                                title="Delete"
                                arrow
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => deleteWarning(warnObj)}
                                >
                                  <img src={deletIco} />
                                </IconButton>
                              </Tooltip> */}
                              <div className="mainListCont">
                                <div className="typeWarnNew typeWarnNewHeading">
                                  <p>
                                    Type Of Warning :
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        textWrap: "nowrap",
                                        width: "100%",
                                      }}
                                    >
                                      {warnObj.warningtype}
                                    </span>
                                  </p>
                                </div>

                                <div className="warnDetailsNewTop">
                                  <div>
                                    <div className="newWarnSubjectOuter warnDetailsNewRefLayout">
                                      <h1>{warnObj.subject}</h1>
                                      <p className="refNotx">
                                        Reference No. {warnObj.warning_id}
                                      </p>
                                      <label>
                                        {moment(warnObj.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                        ,{" "}
                                        {moment(warnObj.created_at).format(
                                          "h:mm a"
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="dmCost">
                                    <p>Damage Cost</p>
                                    <h3 className="rightTotCos">
                                      <CurrencyRupeeIcon />{" "}
                                      {parseFloat(warnObj.damagecost).toFixed(
                                        2
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                {/* <>{warnObj.description}</> */}

                                <div className="boxActions">
                                  {warnObj?.description?.length > 0 && (
                                    <span
                                      onClick={() =>
                                        viewDocs(
                                          warnObj.warning_id,
                                          index,
                                          warnObj.description
                                        )
                                      }
                                      className="downoadYourFile"
                                    >
                                      <AssignmentIcon />
                                      <em>Show Description</em>
                                    </span>
                                  )}
                                </div>
                                <div
                                  className="expandfilesDiv"
                                  id={"noticeSlideID" + index}
                                >
                                  <div className="doqumentDiv1">
                                    {warnObj?.description?.length > 0 && (
                                      <div
                                        id="documentDiv"
                                        className="doqumentDiv1"
                                      >
                                        <div className="newWarnDescP">
                                          <p>{warningDescription}</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="newWarnComntMain">
                                  <div className="ticketDetailsMain">
                                    <div className="disctionBottomContMain">
                                      <div className="disctionComntScrol ticketChatMain">
                                        {/* chat left side */}
                                        {warnObj.reply ? (
                                          <>
                                            {warnObj.reply.map(
                                              (replyOption) => (
                                                <>
                                                  {replyOption.employee_id !=
                                                    empId ? (
                                                    <div className="ticketChatLeft">
                                                      <div className="parkSocityInner parkSocityInnerTicket">
                                                        <div className="parkSocityTop">
                                                          <div className="parkSocityTopPro">
                                                            <img
                                                              alt="Remy Sharp"
                                                              src={avtarPic}
                                                            />
                                                          </div>
                                                          <h4>
                                                            {
                                                              replyOption.employee_name
                                                            }
                                                          </h4>
                                                        </div>
                                                        <p className="parContTx">
                                                          {replyOption.reply}
                                                        </p>
                                                      </div>
                                                      <p className="parContTx parContTxDate ">
                                                        {moment(
                                                          replyOption.date
                                                        ).format(
                                                          "DD/MM/YYYY HH:MM"
                                                        )}
                                                      </p>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {/* chat right side */}
                                                  {replyOption.employee_id ==
                                                    empId ? (
                                                    <div className="ticketChatRight">
                                                      <div className="parkSocityInner parkSocityInnerTicket">
                                                        <div className="parkSocityTop">
                                                          <div className="parkSocityTopPro">
                                                            <img
                                                              alt="Remy Sharp"
                                                              src={avtarPic}
                                                            />
                                                          </div>
                                                          <h4>
                                                            {
                                                              replyOption.employee_name
                                                            }
                                                          </h4>
                                                        </div>
                                                        <div className="titlNDate">
                                                          <p className="parContTx">
                                                            {replyOption.reply}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <p className="parContTx parContTxDate timeAlignRight">
                                                        {moment(
                                                          replyOption.date
                                                        ).format(
                                                          "DD/MM/YYYY HH:MM"
                                                        )}
                                                      </p>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>

                                      {/* <div className="typeComentOut">
                                                    <div className="typeComentOutIco">
                                                        <div className='bottomTextAreaOut'>
                                                            <textarea
                                                                className="textAriaChat"
                                                                type="text"
                                                                id="mainReply"
                                                                placeholder="Comments"
                                                            />
                                                            <AttachFileIcon />
                                                        </div>
                                                        <SendIcon  />
                                                    </div>
                                                </div> */}
                                    </div>
                                  </div>
                                </div>

                                {/* warn cmmt end  */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
                {/* CustomPanel For Warnings End  */}

                {/* CustomPanel For Documents Start  */}
                <CustomTabPanel
                  value={value}
                  index={2}
                  className="tabContentMain"
                >
                  <div className="tabContDiv">
                    <div className="detailsTableMain">
                      {/* <div className="detailsTableFilterSec">
                        <div className="uploadImBox">
                          <h1 className="">Upload Image</h1>
                          <Button
                            className="uploadImBt"
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload file
                            <VisuallyHiddenInput type="file" />
                          </Button>
                          <span>or drag file in here</span>
                        </div>
                      </div> */}
                      <div className="tableMainEmployee">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <h1 className="basicHeading">All Documents</h1>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <h3 className="headOfDownloadile">
                              Joining Letter
                            </h3>
                            <div className="uplBtOut">
                              <Button
                                component="label"
                                variant="contained"
                                className="uploadImBt"
                              >
                                Upload{" "}
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(event) =>
                                    uploadDocuments(event, "Joining Letter")
                                  }
                                />
                              </Button>{" "}
                              {/* <p>Or Drag File In Here</p> */}
                            </div>
                            <div className="downloadedViewDivOuter">
                              {documentsList ? (
                                <>
                                  {documentsList.map((fileObj, index) => (
                                    <div className="downloadedViewDiv">
                                      {/* {fileObj} */}
                                      {fileObj.type === "Joining Letter" ? (
                                        <>
                                          <div className="donloadLeft">
                                            <div className="uploadedImgDiv">
                                              <img src={pdf} />
                                            </div>
                                            <h1>{fileObj.name}</h1>
                                            <span>
                                              Uploaded on {fileObj.date}
                                            </span>
                                          </div>
                                          <div className="donloadRight">
                                            <Button
                                              className="downloadDataBt"
                                              onClick={() =>
                                                handleDownload(
                                                  fileObj.file,
                                                  fileObj.name
                                                )
                                              }
                                            >
                                              Download
                                            </Button>
                                            <Tooltip
                                              title="View"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <a
                                                  href={fileObj.file}
                                                  target="_blank"
                                                >
                                                  <RemoveRedEyeIcon />
                                                </a>
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title="Delete"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <img
                                                  src={deletIco}
                                                  onClick={() =>
                                                    deleteDocument(index)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <h3 className="headOfDownloadile">
                              Appointment Letter
                            </h3>
                            <div className="uplBtOut">
                              <Button
                                component="label"
                                variant="contained"
                                className="uploadImBt"
                              >
                                Upload{" "}
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(event) =>
                                    uploadDocuments(event, "Appointment Letter")
                                  }
                                />
                              </Button>
                            </div>
                            <div className="downloadedViewDivOuter">
                              {documentsList ? (
                                <>
                                  {documentsList.map((fileObj, index) => (
                                    <div className="downloadedViewDiv">
                                      {/* {fileObj} */}
                                      {fileObj.type === "Appointment Letter" ? (
                                        <>
                                          <div className="donloadLeft">
                                            <div className="uploadedImgDiv">
                                              <img src={pdf} />
                                            </div>
                                            <h1>{fileObj.name}</h1>
                                            <span>
                                              Uploaded on {fileObj.date}
                                            </span>
                                          </div>
                                          <div className="donloadRight">
                                            <Button
                                              className="downloadDataBt"
                                              onClick={() =>
                                                handleDownload(
                                                  fileObj.file,
                                                  fileObj.name
                                                )
                                              }
                                            >
                                              Download
                                            </Button>
                                            <Tooltip
                                              title="View"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <a
                                                  href={fileObj.file}
                                                  target="_blank"
                                                >
                                                  <RemoveRedEyeIcon />
                                                </a>
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title="Delete"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <img
                                                  src={deletIco}
                                                  onClick={() =>
                                                    deleteDocument(index)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <h3 className="headOfDownloadile">Passport</h3>
                            <div className="uplBtOut">
                              <Button
                                component="label"
                                variant="contained"
                                className="uploadImBt"
                              >
                                Upload{" "}
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(event) =>
                                    uploadDocuments(event, "Passport")
                                  }
                                />
                              </Button>{" "}
                              {/* <p>Or Drag File In Here</p> */}
                            </div>
                            <div className="downloadedViewDivOuter">
                              {documentsList ? (
                                <>
                                  {documentsList.map((fileObj, index) => (
                                    <div className="downloadedViewDiv">
                                      {/* {fileObj} */}
                                      {fileObj.type === "Passport" ? (
                                        <>
                                          <div className="donloadLeft">
                                            <div className="uploadedImgDiv">
                                              <img src={pdf} />
                                            </div>
                                            <h1>{fileObj.name}</h1>
                                            <span>
                                              Uploaded on {fileObj.date}
                                            </span>
                                          </div>
                                          <div className="donloadRight">
                                            <Button
                                              className="downloadDataBt"
                                              onClick={() =>
                                                handleDownload(
                                                  fileObj.file,
                                                  fileObj.name
                                                )
                                              }
                                            >
                                              Download
                                            </Button>
                                            <Tooltip
                                              title="View"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <a
                                                  href={fileObj.file}
                                                  target="_blank"
                                                >
                                                  <RemoveRedEyeIcon />
                                                </a>
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title="Delete"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <img
                                                  src={deletIco}
                                                  onClick={() =>
                                                    deleteDocument(index)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <h3 className="headOfDownloadile">Visa</h3>
                            <div className="uplBtOut">
                              <Button
                                component="label"
                                variant="contained"
                                className="uploadImBt"
                              >
                                Upload{" "}
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(event) =>
                                    uploadDocuments(event, "Visa")
                                  }
                                />
                              </Button>{" "}
                              {/* <p>Or Drag File In Here</p> */}
                            </div>
                            <div className="downloadedViewDivOuter">
                              {documentsList ? (
                                <>
                                  {documentsList.map((fileObj, index) => (
                                    <div className="downloadedViewDiv">
                                      {/* {fileObj} */}
                                      {fileObj.type === "Visa" ? (
                                        <>
                                          <div className="donloadLeft">
                                            <div className="uploadedImgDiv">
                                              <img src={pdf} />
                                            </div>
                                            <h1>{fileObj.name}</h1>
                                            <span>
                                              Uploaded on {fileObj.date}
                                            </span>
                                          </div>
                                          <div className="donloadRight">
                                            <Button
                                              className="downloadDataBt"
                                              onClick={() =>
                                                handleDownload(
                                                  fileObj.file,
                                                  fileObj.name
                                                )
                                              }
                                            >
                                              Download
                                            </Button>
                                            <Tooltip
                                              title="View"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <a
                                                  href={fileObj.file}
                                                  target="_blank"
                                                >
                                                  <RemoveRedEyeIcon />
                                                </a>
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title="Delete"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <img
                                                  src={deletIco}
                                                  onClick={() =>
                                                    deleteDocument(index)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            {/* <div className="downloadedViewDivOuter">
                              <p className="noDocTTx">No Documents Uploaded</p>
                            </div> */}
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <h3 className="headOfDownloadile">Other</h3>
                            <div className="uplBtOut">
                              <Button
                                component="label"
                                variant="contained"
                                className="uploadImBt"
                              >
                                Upload{" "}
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(event) =>
                                    uploadDocuments(event, "Other")
                                  }
                                />
                              </Button>{" "}
                              <p>Or Drag File In Here</p>
                            </div>
                            <div className="downloadedViewDivOuter">
                              {documentsList ? (
                                <>
                                  {documentsList.map((fileObj, index) => (
                                    <div className="downloadedViewDiv">
                                      {/* {fileObj} */}
                                      {fileObj.type === "Other" ? (
                                        <>
                                          <div className="donloadLeft">
                                            <div className="uploadedImgDiv">
                                              <img src={pdf} />
                                            </div>
                                            <h1>{fileObj.name}</h1>
                                            <span>
                                              Uploaded on {fileObj.date}
                                            </span>
                                          </div>
                                          <div className="donloadRight">
                                            <Button
                                              className="downloadDataBt"
                                              onClick={() =>
                                                handleDownload(
                                                  fileObj.file,
                                                  fileObj.name
                                                )
                                              }
                                            >
                                              Download
                                            </Button>
                                            <Tooltip
                                              title="View"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <a
                                                  href={fileObj.file}
                                                  target="_blank"
                                                >
                                                  <RemoveRedEyeIcon />
                                                </a>
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title="Delete"
                                              arrow
                                              placement="top"
                                            >
                                              <IconButton>
                                                <img
                                                  src={deletIco}
                                                  onClick={() =>
                                                    deleteDocument(index)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            {/* <div className="downloadedViewDivOuter">
                              <p className="noDocTTx">No Documents Uploaded</p>
                            </div> */}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
                {/* CustomPanel For Documents End  */}

                {/* CustomPanel For Leave History Start  */}
                <CustomTabPanel
                  value={value}
                  index={3}
                  className="tabContentMain"
                >
                  <div className="tabContDiv">
                    <div className="detailsTableMain">
                      <div className="tableMainEmployee">
                        <div className="leaveHistoTabMain">
                          <div className="starCafeTabMain">
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  value={valueLeaveHistory}
                                  onChange={handleChangeLeaveHistory}
                                  aria-label="basic tabs example"
                                >
                                  <Tab
                                    label="Pending"
                                    {...a11yPropsLeaveHistory(0)}
                                  />
                                  <Tab
                                    label="Approved"
                                    {...a11yPropsLeaveHistory(1)}
                                  />
                                  <Tab
                                    label="Rejected"
                                    {...a11yPropsLeaveHistory(2)}
                                  />
                                </Tabs>
                              </Box>
                              {/* Pending Tab */}
                              <CustomTabPanel
                                value={valueLeaveHistory}
                                index={0}
                              >
                                <div className="leaveHisTabCont">
                                  {leavePending.map((leaveRejectObj, index) =>
                                    leaveRejectObj.status === "Pending" ? (
                                      <>
                                        <div className="detailsTableFilterSec">
                                          <div className="downloadedViewDiv">
                                            <div className="donloadLeft">
                                              <div className="uploadedImgDiv">
                                                <img src={calender} />
                                              </div>
                                              <h1>
                                                {leaveRejectObj.leave_reason}{" "}
                                                {moment(
                                                  leaveRejectObj.created_at
                                                ).format("DD/MM/YYYY hh:mm a")}
                                              </h1>
                                            </div>
                                            <div className="donloadRight">
                                              <Button
                                                className="approveBt"
                                                onClick={() =>
                                                  approveRejectFunction(
                                                    "Approve",
                                                    leaveRejectObj.leave_history_id,
                                                    leaveRejectObj.employee_id
                                                  )
                                                }
                                              >
                                                Approve
                                              </Button>
                                              <Button
                                                className="rejectBt"
                                                onClick={() =>
                                                  approveRejectFunction(
                                                    "Rejected",
                                                    leaveRejectObj.leave_history_id,
                                                    leaveRejectObj.employee_id
                                                  )
                                                }
                                              >
                                                Reject
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="aprroveRejMain">
                                          <textarea
                                            className="aprroveRejTextArea"
                                            placeholder="Add Comments"
                                            onChange={(e) =>
                                              setComment(e.target.value)
                                            }
                                          ></textarea>
                                          <div
                                            style={{
                                              color: "red",
                                              fontSize: 12,
                                            }}
                                          >
                                            {commentErrorMsg}
                                          </div>
                                        </div>
                                      </>
                                    ) : null
                                  )}
                                </div>
                              </CustomTabPanel>

                              {/* Approve Tab */}
                              <CustomTabPanel
                                value={valueLeaveHistory}
                                index={1}
                              >
                                <div className="leaveHisTabCont">
                                  {leaveApproveRejectList.map(
                                    (leaveObj, index) => (
                                      <>
                                        {leaveObj.status == "Approve" ? (
                                          <div
                                            className="warningMain"
                                            key={index}
                                          >
                                            <>
                                              <h1>{leaveObj.leave_reason}</h1>
                                              <label>
                                                {moment(
                                                  leaveObj.created_at
                                                ).format("DD/MM/YYYY hh:mm a")}
                                              </label>
                                              {leaveObj.status === "Approve" ? (
                                                <div
                                                  style={{
                                                    color: "green",
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Approved
                                                </div>
                                              ) : leaveObj.status ===
                                                "Rejected" ? (
                                                <div
                                                  style={{
                                                    color: "red",
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Rejected
                                                </div>
                                              ) : null}
                                              <p>
                                                {leaveObj.comments.length >
                                                  10 ? (
                                                  <>
                                                    {leaveObj.comments.substring(
                                                      0,
                                                      10
                                                    )}
                                                    <span
                                                      style={{
                                                        display: "inline",
                                                      }}
                                                      id={`dots` + index}
                                                    >
                                                      ...{" "}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span id={`more` + index}>
                                                    {leaveObj.comments}
                                                  </span>
                                                )}

                                                <span id={`more` + index}>
                                                  {leaveObj.comments}
                                                </span>
                                                {leaveObj.comments.length >
                                                  10 ? (
                                                  <b
                                                    id={`myBtn` + index}
                                                    onClick={() =>
                                                      myFunctionLeave(
                                                        leaveObj.comments,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Read More
                                                  </b>
                                                ) : (
                                                  <></>
                                                )}
                                              </p>
                                            </>
                                          </div>
                                        ) : null}
                                      </>
                                    )
                                  )}
                                </div>
                              </CustomTabPanel>

                              {/* Rejected Tab */}
                              <CustomTabPanel
                                value={valueLeaveHistory}
                                index={2}
                              >
                                <div className="leaveHisTabCont">
                                  {leaveApproveRejectList.map(
                                    (leaveObj, index) => (
                                      <>
                                        {leaveObj.status == "Rejected" ? (
                                          <div
                                            className="warningMain"
                                            key={index}
                                          >
                                            <>
                                              <h1>{leaveObj.leave_reason}</h1>
                                              <label>
                                                {moment(
                                                  leaveObj.created_at
                                                ).format("DD/MM/YYYY hh:mm a")}
                                              </label>
                                              {leaveObj.status === "Approve" ? (
                                                <div
                                                  style={{
                                                    color: "green",
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Approved
                                                </div>
                                              ) : leaveObj.status ===
                                                "Rejected" ? (
                                                <div
                                                  style={{
                                                    color: "red",
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Rejected
                                                </div>
                                              ) : null}
                                              <p>
                                                {leaveObj.comments.length >
                                                  10 ? (
                                                  <>
                                                    {leaveObj.comments.substring(
                                                      0,
                                                      10
                                                    )}
                                                    <span id={`dots` + index}>
                                                      ...{" "}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>{leaveObj.comments}</>
                                                )}
                                                <span id={`more` + index}>
                                                  {leaveObj.comments}
                                                </span>
                                                {leaveObj.comments.length >
                                                  10 ? (
                                                  <b
                                                    id={`myBtn` + index}
                                                    onClick={() =>
                                                      myFunctionLeave(
                                                        leaveObj.comments,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Read More
                                                  </b>
                                                ) : (
                                                  <></>
                                                )}
                                              </p>
                                            </>
                                          </div>
                                        ) : null}
                                      </>
                                    )
                                  )}
                                </div>
                              </CustomTabPanel>
                            </Box>
                          </div>
                        </div>

                        {/* <div className="warningMain">
                          <Button
                            className="warninPrint"
                            onClick={() => navigate("/NoticePrintFormat")}
                          >
                            <PrintIcon />
                          </Button>
                          <h1>Personal Reason</h1>
                          <label>01/11/2022 04:35pm</label>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Phasellus imperdiet, nulla et dictum interdum,
                            nisi lorem egestas vitae scel
                            <span id="dots">...</span>
                            <span id="more">
                              erisque enim ligula venenatis dolor. Maecenas nisl
                              est, ultrices nec congue eget, auctor vitae massa.
                              Fusce luctus vestibulum augue ut aliquet. Nunc
                              sagittis dictum nisi, sed ullamcorper ipsum
                              dignissim ac. In at libero sed nunc venenatis
                              imperdiet sed ornare turpis. Donec vitae dui eget
                              tellus gravida venenatis. Integer fringilla congue
                              eros non fermentum. Sed dapibus pulvinar nibh
                              tempor porta.
                            </span>
                            <b id="myBtn" onClick={() => myFunction(0)}>
                              Read More
                            </b>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
                {/* CustomPanel For Leave History End  */}

                {/* CustomPanel For Star Activity Start  */}
                <CustomTabPanel
                  value={value}
                  index={4}
                  className="tabContentMain"
                >
                  <div className="tabContDiv">
                    <div className="detailsTableMain">
                      <div className="detailsTableFilterSec">
                        <h1 className="starActivityHeading">
                          {new Date().toLocaleDateString("en-EN", {
                            month: "long",
                          })}
                        </h1>
                      </div>
                      <div className="aprroveRejMain monthlyAllowanceMain">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <div className="addStarContD allowncWithEdit">
                              <div className="allowncWithEditInner">
                                <b className="startCount">
                                  <StarIcon /> {salaryDetails.monthly_allowance}
                                </b>
                                <p>Monthly Allowance</p>
                              </div>
                              <div className="allowncEditIco">
                                <Tooltip title="Edit" arrow placement="top">
                                  <IconButton>
                                    <img
                                      src={editIco}
                                      onClick={handleClickMonthlyAllowance(
                                        "body"
                                      )}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <div className="addStarContD allowncWithEdit">
                              <div className="allowncWithEditInner">
                                <b className="startCount">
                                  <StarIcon /> {employeeObj.total_stars}
                                </b>
                                <p>Available stars</p>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <div className="addStarContD">
                              <b className="startCount">
                                {/*₹ {salaryDetails.fixed_salary} */}
                                ******
                              </b>
                              <p>Fixed Salary</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <div className="addStarContD">
                              <b className="startCount">
                                {/* ₹ {salaryDetails.balance_salary} */}
                                ******
                              </b>
                              <p>Balance Salary</p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="tableMainEmployee">
                        <div className="starActiInnerDiv">
                          <div className="innerTabsMain starActivityTbsMain">
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  value={valueStarActivity}
                                  onChange={handleChangeStarActivity}
                                  aria-label="basic tabs example"
                                >
                                  <Tab
                                    label="Spending"
                                    {...a11yPropsStarActivity(0)}
                                    onClick={() =>
                                      getStarSpendHistory(
                                        selectedOrderType,
                                        startDate,
                                        endDate
                                      )
                                    }
                                  />
                                  <Tab
                                    label="Reload History"
                                    {...a11yPropsStarActivity(1)}
                                    onClick={() => getStarReloadHistory()}
                                  />

                                  <Tab
                                    label="Allowance History"
                                    {...a11yPropsStarActivity(2)}
                                    onClick={() => getStarAllowenceHistory()}
                                  />
                                </Tabs>
                              </Box>
                              <CustomTabPanel
                                value={valueStarActivity}
                                index={0}
                              >
                                <div className="tableMainEmployee groceryTableMain">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <div className="datePicOut">
                                        <select
                                          style={{ fontSize: 11 }}
                                          className="prodSearchInpt inputBackground leftRightSpace"
                                          onChange={(e) =>
                                            getStarSpendHistory(
                                              e.target.value,
                                              startDate,
                                              endDate
                                            )
                                          }
                                        >
                                          <option>Select Service Type</option>
                                          <option value="">All</option>
                                          <option value="Breakfast">
                                            Breakfast
                                          </option>
                                          <option value="Lunch">Lunch</option>
                                          <option value="Dinner">Dinner</option>
                                          <option value="Starcafe">
                                            Starcafe
                                          </option>
                                        </select>
                                        <em className="toInDt">-</em>
                                        <div className="newDtPik">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                            >
                                              <DatePicker
                                                onChange={(e) =>
                                                  handleChangeStartDate(e)
                                                }
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                        <em className="toInDt">To</em>
                                        <div className="newDtPik">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                            >
                                              <DatePicker
                                                onChange={(e) =>
                                                  handleChangeEndDate(e)
                                                }
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starSpendHistory.totalSum ? (
                                              <>
                                                {parseInt(
                                                  starSpendHistory.totalSum
                                                ) +
                                                  parseInt(
                                                    starSpendHistory.totalwasteFoodSum
                                                  )}
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Stars Spend</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starSpendHistory.totalrefundSum ? (
                                              <>
                                                {
                                                  starSpendHistory.totalrefundSum
                                                }
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Stars Refunded</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starSpendHistory.totalwasteFoodSum ? (
                                              <>
                                                {
                                                  starSpendHistory.totalwasteFoodSum
                                                }
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Stars Of Waste Food</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starSpendHistory.totaldeductSum ? (
                                              <>
                                                {
                                                  starSpendHistory.totaldeductSum
                                                }
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>

                                          {/* <p>Total Stars Spend in INR (₹)</p> */}
                                          <p>Total Stars Deducted</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <h2 className="tableHeadinNew">
                                        Spend History
                                      </h2>
                                      <table width="100%">
                                        <thead>
                                          <tr>
                                            <th>Date</th>
                                            <th>Order ID</th>
                                            <th>Service</th>
                                            <th>Star Spents</th>
                                            <th>Star Refunded/Deducted</th>
                                            <th>Star Waste</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {starSpendHistory.refundList ? (
                                            <>
                                              {starSpendHistory.refundList.map(
                                                (refundOption) => (
                                                  <tr>
                                                    <td>
                                                      {moment(
                                                        refundOption.created_at
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>Refunded</td>
                                                    <td>
                                                      {refundOption.order_type}
                                                    </td>
                                                    <td>-</td>
                                                    <td
                                                      style={{
                                                        color: "#1fcb1f",
                                                      }}
                                                      className="commeMainTbl"
                                                    >
                                                      +
                                                      {
                                                        refundOption.star_refunded
                                                      }
                                                      {refundOption.comment ?
                                                        <details>
                                                          <summary>
                                                            {refundOption.comment.substring(0, 20)}
                                                            {refundOption.comment.length > 20 ? <>...</> : <></>}
                                                          </summary>
                                                          <p>
                                                            {refundOption.comment}
                                                          </p>
                                                        </details> :
                                                        <>-</>}
                                                    </td>
                                                    <td>-</td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {starSpendHistory.deductList ? (
                                            <>
                                              {starSpendHistory.deductList.map(
                                                (deductOption) => (
                                                  <tr>
                                                    <td>
                                                      {moment(
                                                        deductOption.created_at
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>Deducted</td>
                                                    <td>
                                                      Deducted By Admin
                                                    </td>
                                                    <td>-</td>
                                                    <td
                                                      style={{
                                                        color: "red",
                                                      }}
                                                      className="commeMainTbl"
                                                    >
                                                      -
                                                      {
                                                        deductOption.star_deducted
                                                      }
                                                      {deductOption.reason ?
                                                        <details>
                                                          <summary>
                                                            {deductOption.reason.substring(0, 20)}
                                                            {deductOption.reason.length > 20 ? <>...</> : <></>}
                                                          </summary>
                                                          <p>
                                                            {deductOption.reason}
                                                          </p>
                                                        </details> :
                                                        <>-</>}
                                                    </td>
                                                    <td>-</td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {starSpendHistory.groupedData ? (
                                            <>
                                              {starSpendHistory.groupedData.map(
                                                (spendOption) => (
                                                  <tr>
                                                    <td>
                                                      {moment(
                                                        spendOption.created_at
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                      #{spendOption.order_id}
                                                    </td>
                                                    <td>
                                                      {spendOption.ordertype}
                                                    </td>
                                                    <td>
                                                      {spendOption.orderstatus !=
                                                        "NotPickedUp" ? (
                                                        <>
                                                          {
                                                            spendOption.totalstarspend
                                                          }
                                                        </>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {spendOption.sum ? (
                                                        <>{spendOption.sum}</>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {spendOption.orderstatus ==
                                                        "NotPickedUp" ? (
                                                        <>
                                                          {
                                                            spendOption.totalstarspend
                                                          }
                                                        </>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {starSpendHistory.groupedDataMarket ? (
                                            <>
                                              {starSpendHistory.groupedDataMarket.map(
                                                (spendOption) => (
                                                  <tr>
                                                    <td>
                                                      {moment(
                                                        spendOption.created_at
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                      #{spendOption.order_id}
                                                    </td>
                                                    <td>
                                                      {spendOption.ordertype}
                                                    </td>
                                                    <td>
                                                      {spendOption.orderstatus !=
                                                        "NotPickedUp" ? (
                                                        <>
                                                          {
                                                            spendOption.totalstarspend
                                                          }
                                                        </>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {spendOption.sum ? (
                                                        <>{spendOption.sum}</>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {spendOption.orderstatus ==
                                                        "NotPickedUp" ? (
                                                        <>
                                                          {
                                                            spendOption.totalstarspend
                                                          }
                                                        </>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tbody>
                                      </table>
                                    </Grid>
                                    {/* <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className="paginationOuterMain"
                                    >
                                      <Pagination
                                        count={10}
                                        variant="outlined"
                                        shape="rounded"
                                      />
                                    </Grid> */}
                                  </Grid>
                                </div>
                              </CustomTabPanel>

                              <CustomTabPanel
                                value={valueStarActivity}
                                index={1}
                              >
                                <div className="tableMainEmployee groceryTableMain">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <div className="datePicOut">
                                        <em className="toInDt">-</em>
                                        <div className="newDtPik">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                            >
                                              <DatePicker
                                                onChange={(e) =>
                                                  handleChangeReloadStartDate(e)
                                                }
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                        <em className="toInDt">To</em>
                                        <div className="newDtPik">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["DatePicker"]}
                                            >
                                              <DatePicker
                                                onChange={(e) =>
                                                  handleChangeReloadEndDate(e)
                                                }
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starReloadHistory.totalSum ? (
                                              <>
                                                {starReloadHistory.totalSum /
                                                  masterDollarAmount}
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Stars Reloaded</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            ₹{" "}
                                            {starReloadHistory.totalSum ? (
                                              <>{starReloadHistory.totalSum}</>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Stars Reloaded in INR (₹)</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <h2 className="tableHeadinNew">
                                        Allowance History
                                      </h2>
                                      <table width="100%">
                                        <thead>
                                          <tr>
                                            <th>Date</th>
                                            <th>Amount in INR (₹)</th>
                                            <th>Stars</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {starReloadHistory.groupedData ? (
                                            <>
                                              {starReloadHistory.groupedData.map(
                                                (reloadOption) => (
                                                  <tr>
                                                    <td>
                                                      {moment(
                                                        reloadOption.reloaded_date
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                      ₹{" "}
                                                      {
                                                        reloadOption.amount_in_dollars
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        reloadOption.star_reloaded
                                                      }
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <tr>No Star Reloaded yet</tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Grid>
                                    {/* <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className="paginationOuterMain"
                                    >
                                      <Pagination
                                        count={10}
                                        variant="outlined"
                                        shape="rounded"
                                      />
                                    </Grid> */}
                                  </Grid>
                                </div>
                              </CustomTabPanel>

                              <CustomTabPanel
                                value={valueStarActivity}
                                index={2}
                              >
                                <div className="tableMainEmployee groceryTableMain">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                      <div className="datePicOut">
                                        <Tooltip
                                          title="From Date"
                                          arrow
                                          placement="top"
                                        >
                                          <input
                                            type="date"
                                            className="dateSt leftRightSpace"
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          title="To Date"
                                          arrow
                                          placement="top"
                                        >
                                          <input
                                            type="date"
                                            className="dateSt leftRightSpace"
                                          />
                                        </Tooltip>
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starAllowenceHistory.totalSum ? (
                                              <>
                                                {starAllowenceHistory.totalSum}
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Allowance In Stars</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <div className="innerAllowncTotStar">
                                        <div className="addStarContD">
                                          <b className="startCount">
                                            ₹{" "}
                                            {starAllowenceHistory.totalSum ? (
                                              <>
                                                {starAllowenceHistory.totalSum *
                                                  masterDollarAmount}
                                              </>
                                            ) : (
                                              <>0</>
                                            )}
                                          </b>
                                          <p>Total Allowance in INR (₹)</p>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <h2 className="tableHeadinNew">
                                        Allowance History
                                      </h2>
                                      <table width="100%">
                                        <thead>
                                          <tr>
                                            <th>Date</th>
                                            <th>Allowance in INR (₹)</th>
                                            <th>Allowance In Stars</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {starAllowenceHistory.groupedData ? (
                                            <>
                                              {starAllowenceHistory.groupedData.map(
                                                (allowenceOption) => (
                                                  <tr
                                                    key={
                                                      allowenceOption.allowance_history_id
                                                    }
                                                  >
                                                    <td>
                                                      {moment(
                                                        allowenceOption.allowence_date
                                                      ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                      ₹{" "}
                                                      {
                                                        allowenceOption.amount_in_dollars
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        allowenceOption.star_allocated
                                                      }
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <tr>No Allowance Allocated</tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Grid>

                                    {/* <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className="paginationOuterMain"
                                    >
                                      <Pagination
                                        count={10}
                                        variant="outlined"
                                        shape="rounded"
                                      />
                                    </Grid> */}
                                  </Grid>
                                </div>
                              </CustomTabPanel>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
                {/* CustomPanel For Star Activity End  */}
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Create Warning */}
      <div>
        <Dialog
          open={open2}
          // onClose={handleClose2}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditWarning ? <>Edit Warning</> : <>Add Warning</>}
            <CloseIcon className="modalCloseBt" onClick={handleClose2} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <Formik
                  initialValues={formValues || initialValuesWarning}
                  validationSchema={validationSchemaWarning}
                  onSubmit={addWarning}
                  enableReinitialize
                >
                  {(formik) => (
                    <Grid container spacing={3}>
                      <div className="modalSinlInputDiv">
                        <Form name="warningForm" id="warningForm">
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Subject"
                              placeholder="Subject"
                              labelClass="pageLabel warnLabel"
                              name="subject"
                              // value={subject}
                              // options={dropdownOptions}
                              className="prodSearchInpt warnInput"
                            // onChange={(e) => handleCheckValue(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="textarea"
                              type="textarea"
                              label="Description"
                              placeholder="Write here..."
                              labelClass="pageLabel warnLabel"
                              name="description"
                              // value={description}
                              // options={dropdownOptions}
                              className="prodSearchInpt warnInput warnInputDesc"
                            // onChange={(e) => handleCheckValue(e.target.value)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <span onClick={handleClose2}>
                              <Button
                                type="submit"
                                className="modalBtSmallWidth"
                              >
                                {isEditWarning ? (
                                  <>Update Warning</>
                                ) : (
                                  <>Add Warning</>
                                )}
                              </Button>
                            </span>
                          </Grid>
                        </Form>
                      </div>
                    </Grid>
                  )}
                </Formik>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Stars */}
      <div>
        <Dialog
          open={open3}
          // onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Reload Star
            <CloseIcon className="modalCloseBt" onClick={handleClose3} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={reloadStars}
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <div class="popUpExchImgOuter">
                            <div class="setExchImgDiv">
                              <img src={exchaneBlack} />
                            </div>
                            <h2>
                              Exchange Star Rate{" "}
                              <b style={{ color: "#c82d33", fontSize: 30 }}>
                                1 Star=₹ {masterDollarAmount}
                              </b>
                            </h2>
                          </div>

                          <Grid item xs={12} md={6}>
                            <div className="addStarContD">
                              <b className="startCount">
                                <StarIcon /> {spendStars}
                              </b>
                              <p>Spent Stars</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="addStarContD">
                              <b className="startCount">
                                <StarIcon /> {availableStars}
                              </b>
                              <p>Available Stars</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Enter Star Amount (*)
                            </label>
                            <input
                              type="text"
                              placeholder="Star Quantity"
                              value={purchase_star_quantity}
                              onChange={(e) =>
                                handleChangeDollarAmount(e.target.value)
                              }
                              className="prodSearchInpt"
                            />
                            <span style={{ color: "red", fontSize: 14 }}>
                              {priceErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Enter Rupees Amount in INR (₹)
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Rupees Amount in INR (₹)"
                              value={purchase_star_dollar_Amount}
                              className="prodSearchInpt"
                              disabled
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <span onClick={handleClose2}>
                              <Button
                                className="modalBtSmallWidth"
                                type="submit"
                              >
                                Reload Stars
                              </Button>
                            </span>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Monthly Allowance */}
      <div>
        <Dialog
          open={openMonthlyAllowance}
          // onClose={handleCloseMonthlyAllowance}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Additional Monthly Allowance
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseMonthlyAllowance}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="messOuter">
                        <p>
                          <b> {employeeObj.first_name},</b> your current monthly
                          allowance for department {employeeObj.department_name}{" "}
                          is <b>{basicStarAllowence},</b>
                        </p>
                        <p>
                          You already set additional allowance is{" "}
                          <b>{additionalAllowence}.</b>
                        </p>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">
                        Enter Additional Monthly Allowance In Stars(*)
                      </label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Additional monthly Allowance In Stars(*)"
                        value={purchase_star_quantity}
                        onChange={(e) =>
                          handleChangeDollarAmount(e.target.value)
                        }
                      />
                      <span style={{ color: "red", fontSize: 14 }}>
                        {priceErrMsg}
                      </span>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">
                        Enter Additional Monthly Allowance In INR(₹)
                      </label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Additional monthly Allowance In INR (₹)"
                        value={purchase_star_dollar_Amount}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span>
                        <Button
                          className="modalBtSmallWidth"
                          onClick={() => editMonthlyAllowence()}
                        >
                          Update
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default EmployeesDetails;
