import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box, Alert, colors } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import productImg from "../../../assets/images/productImg.svg";
import mealAcordingIcon2 from "../../../assets/images/mealAcordingIcon2.svg";
import mealAcordingIcon3 from "../../../assets/images/mealAcordingIcon3.svg";
import mealAcordingIcon4 from "../../../assets/images/mealAcordingIcon4.svg";
import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import dataNotFound from "../../../assets/images/dataNotFound.png";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import moment from "moment";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

// Formik and Api Call
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makePostRequest,
  makeGetRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import swal from "sweetalert";

import FormLabel from "@mui/material/FormLabel";
import loaderImg from "../../../assets/images/logo.png";

// For Filter
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// For Filter End

// For Multi Select
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
// For Multi Select End


import TaskAltIcon from '@mui/icons-material/TaskAlt';


// For Multi Select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
// For Multi Select End

function CafeInventoryTab() {
  const navigate = useNavigate();

  // ScanPop
  const [ScanPop, setOpenScanPop] = React.useState(false);

  const handleClickScanPop = (scrollType) => () => {
    setOpenScanPop(true);
    setScroll(scrollType);
  };

  const closeScanPop = () => {
    setOpenScanPop(false);
  };
  // End

  // For Multi Select
  const [categoriesId, setCategoriesId] = React.useState([]);
  const [categoriesName, setCategoriesName] = React.useState([]);

  const handleChange = (event) => {
    //alert("In HandleChange")
    // console.log(event)

    const {
      target: { value },
    } = event;

    setCategoriesId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setCategoryListErrMsg("");
  };
  // Multi Select End

  // For Filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterType, setFilterType] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilter = (value) => {
    if (value == "Food" || value == "Product") setFilterType(value);
    else setFilterType(null);
    getSubCategoryList(null);
    setAnchorEl(null);
  };
  // For Filter End

  const [selected, setSelected] = React.useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [formValues, setFormValues] = useState(null);
  const [formValuesSUbCategory, setFormValuesSUbCategory] = useState(null);
  const [formValuesAddFood, setFormValuesAddFood] = useState(null);

  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isEditSubCategory, setIsEditSubCategory] = useState(false);
  const [isEditFood, setIsEditFood] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [categoryListErrMsg, setCategoryListErrMsg] = useState("");
  const [categoryUnitErrMsg, setCategoryUnitErrMsg] = useState("");

  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(0);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [sortColumnNameCategory, setSortColumnNameCategory] =
    useState("created_at");
  const [category_name, setCategory_name] = useState("");
  const [upload, setUpload] = useState(false);
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [uploadImg, setUploadImg] = React.useState([]);
  const [duplicateErrMsg, setDuplicateErrMsg] = React.useState("");
  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);
  const [price, setPrice] = useState(0);
  const [Star_price, setStarPrice] = useState(0);
  const [priceErrMsg, setPriceErrMsg] = React.useState("");

  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [duplicateFoodErrMsg, setDuplicateFoodErrMsg] = React.useState("");
  // const [wtUnitErrMsg, setWtUnitErrMsg] = React.useState("");

  const [new_quantity, setNewQuantity] = useState(0);
  const [current_quantity, setCurrentQuantity] = useState(0);
  const [updatedQuantity, setUpdatedQuantity] = useState(0);

  //Formik Validation Category Start
  const initialValues = {
    category_name: "",
  };
  const validationSchema = Yup.object({
    category_name: Yup.string().required("Required"),
  });
  //Formik Validation Category End

  //Formik Validation AddProduct Start
  const initialValuesAddProduct = {
    product_name: "",
    // current_qty: 0,
    // current_quantity: "",
    Star_price: 0,
    price: 0,
    weight: "",
    discription: "",
    //new_quantity: ""
  };

  const validationSchemaAddProduct = Yup.object({
    product_name: Yup.string().required("Required"),
    // current_qty: Yup.number().typeError("Invalid Number").moreThan(Yup.ref("low_stock_limit"), "value must be greater than low stock limit").required("Required"),

    // current_quantity: Yup.string()
    //   .matches(/^\d+$/, "Invalid value")
    //   .required("Required"),
    Star_price: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    price: Yup.string().matches(/^\d+$/, "Invalid value").required("Required"),
    weight: Yup.string().matches(/^\d+$/, "Invalid value").required("Required"),
    discription: Yup.string().required("Required"),
    //new_quantity: Yup.string().required("Required"),
  });
  //Formik Validation AddProduct end

    // Food Formik
    const initialValuesAddFood = {
      product_name: "",
      Star_price: 0,
      price: 0,
      discription: "",
    };
  
    const validationSchemaAddFood = Yup.object({
      product_name: Yup.string().required("Required"),
      Star_price: Yup.string().required("Required"),
      price: Yup.string().required("Required"),
      discription: Yup.string().required("Required"),
    });
    // Food Formik

  function getMasterDollarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          setMasterDollarAmount(response.data.data[0].dollar_amount);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  function handleChangeDollarAmount(value) {
    setPriceErrMsg("");
    setStarPrice(value);
    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      setPrice(value * masterDollarAmount);
    } else {
      if (value)
        setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPrice(0);

      }
    }
  }

  // function handleChangeStarAmount(value) {
  //   setStarPrice(value);
  //   setPrice(value / masterStarAmount);
  // }

  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|jfif|JFIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;

    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadImg([...uploadImg, imageUrl]);
      console.log("img arr", uploadImg);
    } else {
      setImageErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End

  // Delete Image Start
  const deleteImg = (index) => {
    setShowLoader(true);
    uploadImg.splice(index, 1);
    setUploadImg(uploadImg);
    setUpload(!upload);

    setShowLoader(false);
  };
  // Delete Image End

  // Check Duplicate Value Start

  const handleCheckValue = (value) => {
    console.log("Value", value);
    setCategory_name(value);
    setUpload(!upload);
    console.log(value);
  };
  // Check Duplicate Value End
  const [priorityCheck, setPriorityCheck] = useState("no");
  const [priority, setPriority] = useState(false);

  const handleChangePriority = (event, category_id) => {
    if (event.target.checked == true) {
      let priorityObj = categoriesList.find((o) => o.isoncafepriority === true);
      console.log(categoriesList.find((o) => o.isoncafepriority === true));

      if (priorityObj) {
        let editData = {
          category_id: priorityObj.category_id,
          isoncafepriority: false,
        };
        makePostRequest("starcafe/updatecategory", editData)
          .then((response) => {
            if (response.data) {
              //console.log(response)
              getCategoryList(null);
            }
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
          });
      }
    }

    let inputData = {
      category_id: category_id,
      isoncafepriority: event.target.checked,
    };
    makePostRequest("starcafe/updatecategory", inputData)
      .then((response) => {
        if (response.data) {
          getCategoryList(null);
        }
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  };
  // Add / Edit Category Api Start
  const addOrUpdateCategory = async (values, { resetForm }) => {
    let updateCategory = true;
    let dupRes = categoriesList.find(
      (o) =>
        o.category_name.toLowerCase() === values.category_name.toLowerCase()
    );

    if (isEditCategory) {
      if (dupRes) {
        if (values.category_id == dupRes.category_id) {
          updateCategory = true;
        } else {
          updateCategory = false;
        }
      } else {
        updateCategory = true;
      }

      if (updateCategory) {
        makePostRequest("starcafe/updatecategory", values)
          .then((response) => {
            if (response.data) {
              //console.log(response)
              getCategoryList(null);
            }
            setIsEditCategory(false);
            values.category_name = "";
            values.category_id = "";
            setDuplicateErrMsg("");
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
          });
      } else setDuplicateErrMsg("Duplicate Value");
    } else {
      if (dupRes) {
        setDuplicateErrMsg("Duplicate Value");
      } else {
        values.status = true;
        makePostRequest("starcafe/insertcategory", values)
          .then((response) => {
            if (response.data) {
              getCategoryList(null);
            }
            values.category_name = "";
            resetForm();
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
            swal("Warning", err.data.message, "warning", {
              timer: 3000,
              buttons: false,
            });
          });
        setDuplicateErrMsg("");
      }
    }
  };
  // Add / Edit Category Api End

  //Edit Category Api start
  const editCategory = (categoryObj) => {
    console.log("data", categoryObj);
    setFormValues(categoryObj);
    setIsEditCategory(true);
  };

  // Edit Category End

  const cancelCategory = () => {
    setFormValues(null);
    setIsEditCategory(false);
    setPriority(false);
    setDuplicateErrMsg("");
  };

  // Status Category Start
  const handleStatusChange = (event, category_id) => {
    let inputdata = {
      category_id: category_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);
    makePostRequest("starcafe/updatecategory", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getCategoryList(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Category end

  // Delete Category Api Start
  const deleteCategory = async (category_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Category Deleted!",
          text: "Category is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            category_id: category_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starcafe/updatecategory", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                cancelCategory();
                getCategoryList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Category Api End

  // get Category Api Start
  const getCategoryList = async (SearchStr) => {
    if (!SearchStr) SearchStr = null;
    setShowLoader(true);
    makeGetRequest(
      "starcafe/categorysearch/" +
      sortColumnNameCategory +
      "/" +
      sortBy +
      "/" +
      SearchStr +
      "/" +
      pageNo
    )
      .then((response) => {
        if (response.data) {
          console.log("Data", response.data.data);
          setCategoriesList(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // get Category Api End

  // get Category Api for dropdown  Start
  const getCategoryDropDown = async (SearchStr) => {
    if (!SearchStr) SearchStr = null;
    setShowLoader(true);
    // sortColumnNameCategory +"/"
    makeGetRequest("starcafe/getcategorylist/" + SearchStr + "/true")
      .then((response) => {
        if (response.data) {
          console.log("Data", response.data.data);
          setCategoryData(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        /* let errMsg = err.response.data.message;
          swal("Warning", err.data.message, "warning", {
            timer: 3000,
            buttons: false,
          }); */
        setShowLoader(false);
      });
  };
  // get Category Api End

  // Sort Category Start
  function handleChangeSortByCategory() {
    //alert("In ASC")
    setSortColumnNameCategory("category_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getCategoryList(null);
  }
  // Sort Category End

  async function handleChangeNewQuantity(qty) {
    if (isEditSubCategory == true)
      setUpdatedQuantity(parseInt(qty) + parseInt(current_quantity))
    else
      setCurrentQuantity(qty)

  }

  //Add Product Sub Category Start
  const addOrEditSubCategory = (values, { resetForm }) => {
    setCategoryListErrMsg("");
    setCategoryUnitErrMsg("");
    setDuplicateFoodErrMsg("");

    if (alignment) {
      if (priceErrMsg == "") {
        if (categoriesId.length > 0) {
          if (isEditSubCategory) {
            let updateCategory = true;
            let dupRes = subcategoryData.find(
              (o) =>
                o.product_name.toLowerCase() ===
                values.product_name.toLowerCase()
            );

            if (dupRes) {
              if (values.subcategory_id == dupRes.subcategory_id) {
                updateCategory = true;
              } else updateCategory = false;
            } else updateCategory = true;

            if (updateCategory) {
              values.Star_price = parseInt(Star_price);
              values.item_image = uploadImg;
              values.current_quantity = parseInt(updatedQuantity);
              values.new_quantity=parseInt(new_quantity);
              values.price = parseInt(price);
              values.weight = parseInt(values.weight);
              values.type = "Product";
              if (new_quantity > 0) {
                values.quantity_updated_dt = new Date();
              }
              values.weight_unit = alignment;
              //console.log("Values", values);
              values.category_id = categoriesId;
              delete values.category_names;
              values.category_name = undefined;
              values.category_names = undefined;
              console.log("Update Sub Category", values);
              makePostRequest("starcafe/updatesubcategory", values)
                .then((response) => {
                  if (response.data) {
                    getSubCategoryList(null);
                  }
                  setIsEditSubCategory(false);
                  setOpenAdProdCafeInventory(false);
                  setAlignment("");
                  setDuplicateFoodErrMsg("");
                  setCategoryUnitErrMsg("");
                  setUploadImg([]);
                  setImageErrMsg("");
                  setNewQuantity(0);
                  setCurrentQuantity(0);
                  setUpdatedQuantity(0);
                  resetForm();
                  CloseAdProdCafeInventory();
                })
                .catch((err) => {
                  console.log(err);
                  let errMsg = err.response.data.message;
                  if (err.response.status == 409) {
                    setDuplicateFoodErrMsg("Duplicate name");
                  }
                });
            } else setDuplicateFoodErrMsg("Duplicate name");
          } else {
            values.category_id = categoriesId;
            values.weight_unit = alignment;
            values.item_image = uploadImg;
            values.Star_price = parseInt(Star_price);
            values.current_quantity = parseInt(current_quantity);
            values.new_quantity = parseInt(new_quantity);
            values.price = parseInt(price);
            values.weight = parseInt(values.weight);
            values.type = "Product";
            values.status = true;
            values.category_name = undefined;
            console.log("Add Product", values);
            makePostRequest("starcafe/insertsubcategory", values)
              .then((response) => {
                if (response.data) {
                  console.log(response.data);
                }
                setUploadImg([]);
                setPrice(0);
                setStarPrice(0);
                setCategoriesId([]);
                setAlignment("");
                setOpenAdProdCafeInventory(false);
                setDuplicateFoodErrMsg("");
                setImageErrMsg("");
                setNewQuantity(0);
                setCurrentQuantity(0);
                setUpdatedQuantity(0);
                resetForm();
              })
              .catch((err) => {
                console.log(err);
                let errMsg = err.response.data.message;
                if (err.response.status == 409) {
                  setDuplicateFoodErrMsg("Duplicate name");
                }
              });
          }
        } else setCategoryListErrMsg("Please select category");
      }
    } else {
      setCategoryUnitErrMsg("Please select wt unit");
    }
  };
  //Add Product Sub Category End

  // Modal Add Prod In Cafe Inventory
  const [AdProdCafeInventory, setOpenAdProdCafeInventory] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpenAdProdCafeInventory = (scrollType) => () => {
    // getStarAmount();
    setOpenAdProdCafeInventory(true);
    setScroll(scrollType);
  };

  const CloseAdProdCafeInventory = () => {
    setOpenAdProdCafeInventory(false);
    setFormValuesSUbCategory(null);
    setIsEditSubCategory(false);
    setUploadImg([]);
    setCategoriesId([]);
    setAlignment("");
    setDuplicateFoodErrMsg("");
    setCategoryUnitErrMsg("");
    setDuplicateErrMsg("");
    setPrice(0);
    setStarPrice(0);
    setNewQuantity(0);
    setCurrentQuantity(0);
    setUpdatedQuantity(0);
  };

  const descriptionElementRef = useRef(null);

  // End

  // Modal
  const [AddFood, setOpenAddFood] = useState(false);

  const handleClickOpenAddFood = (scrollType) => () => {
    // getStarAmount();
    setOpenAddFood(true);
    setScroll(scrollType);
    setCategoriesId([]);
  };

  const handleCloseAddFood = () => {
    setOpenAddFood(false);
    setFormValuesAddFood(null);
    setIsEditFood(false);
    setUploadImg([]);
    setCategoriesId([]);
    setDuplicateErrMsg("");
    setDuplicateFoodErrMsg("");
    setCategoryUnitErrMsg("");
    setImageErrMsg("");
    setPrice(0);
    setStarPrice(0);
    setNewQuantity(0);
    setCurrentQuantity(0);
    setUpdatedQuantity(0);
  };
  // End

  // Modal
  const [open, setOpen] = useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setFormValues(null);
    setIsEditCategory(false);
    setPriority(false);
    setDuplicateErrMsg("");
    setOpen(false);
  };
  // End

  const handlePageChange = (e, page) => {
    // let pageno = page- 1
    // let offsetvalue = pageno * itemsPerPage +1
    // setoffsetvalue(offsetvalue);
    // setPageNo(page)
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  // Get SubCategory List Start
  const getSubCategoryList = async (searchString) => {
    console.log(searchString);
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "starcafe/subcategorysearch/" +
      sortColumnName +
      "/" +
      sortBy +
      "/" +
      searchString +
      "/" +
      offsetvalue +
      "/" +
      filterType +
      "/" +
      itemsPerPage
    )
      .then((response) => {
        if (response.data) {
          //setShowLoader(true);
          console.log("Sub Category List", response.data.data);
          setSubcategoryData(response.data.data.res);
          setTotalItems(response.data.data.totalCount);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // Get SubCategory List end

  //Delete SubCategory List  Start
  const deleteSubCategory = async (subcategory_id, type) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        let itemType = "";
        if (type == "Food") itemType = "Food";
        else itemType = "Product";
        swal({
          title: itemType + " Deleted!",
          text: itemType + " is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            subcategory_id: subcategory_id,
            is_deleted: true,
          };
          makePostRequest("starcafe/updatesubcategory", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getSubCategoryList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  //Delete SubCategory List  End

  // Status SubCategory List Start
  const handleSubCategoryStatus = (event, subcategory_id) => {
    console.log("status", event);
    let inputdata = {
      subcategory_id: subcategory_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starcafe/updatesubcategory", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getSubCategoryList(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status SubCategory List End
  // Edit SubCategory List Start
  const editSubCategory = (subCategoryObj, scrollType) => {
    if (subCategoryObj.type == "Product") {
      setFormValuesSUbCategory(subCategoryObj);
      setIsEditSubCategory(true);
      setOpenAdProdCafeInventory(true);
      setScroll(scrollType);
      setAlignment(subCategoryObj.weight_unit);
      setUploadImg(subCategoryObj.item_image);
      setCategoriesId(subCategoryObj.category_id);
      setNewQuantity(0);
      //handleClickOpenAdProdCafeInventory("body");
    } else {
      setFormValuesAddFood(subCategoryObj);
      setUploadImg(subCategoryObj.item_image);
      setIsEditFood(true);
      setOpenAddFood(true);
      setScroll(scrollType);
      setCategoriesId(subCategoryObj.category_id);
    

      //handleClickOpenAddFood("body");
    }
    setPrice(subCategoryObj.price);
    setStarPrice(subCategoryObj.Star_price);
    setCurrentQuantity(subCategoryObj.current_quantity);

  };
  // Edit SubCategory List Start

  const cancelSubCategory = () => {
    setFormValuesSUbCategory(null);
    setFormValuesAddFood(null);
    setIsEditSubCategory(false);
    setIsEditFood(false);
    handleCloseAddFood();
    CloseAdProdCafeInventory();
  };



  //added by yamini on 14jun24
  const categoryMap = categoryData.reduce((map, category) => {
    map[category.category_id] = category.category_name;
    return map;
  }, {});

  // Add or Edit Food Start
  const addOrEditFood =async (values) => {
    setCategoryListErrMsg("");
    setCategoryUnitErrMsg("");
    setDuplicateFoodErrMsg("");

    if (priceErrMsg == "") {
      if (categoriesId.length > 0) {
        setCategoryListErrMsg("");
        if (isEditFood) {
          let updateCategory = true;
          let dupRes = subcategoryData.find(
            (o) =>
              o.product_name.toLowerCase() === values.product_name.toLowerCase()
          );

          if (dupRes) {
            if (values.subcategory_id == dupRes.subcategory_id) {
              updateCategory = true;
            } else updateCategory = false;
          } else updateCategory = true;

          if (updateCategory) {
            //console.log("weight", values.weight_unit);
            delete values.category_name;
            values.Star_price = parseInt(Star_price);
            values.item_image = uploadImg;
            values.current_quantity = 0;
            values.price = parseInt(price);
            values.weight = null;
            values.status = values.status;
            //console.log("Values", values);
            values.category_id = categoriesId;
            values.category_names = undefined;
            console.log("Update Food", values);
            values.type = "Food";
            makePostRequest("starcafe/updatesubcategory", values)
              .then((response) => {
                if (response.data) {
                  getSubCategoryList(null);
                }
                setShowLoader(false);
                handleCloseAddFood();
                setAlignment("");
                setDuplicateFoodErrMsg("");
                setUploadImg([]);
                //setFormValuesSUbCategory([])
              })
              .catch((err) => {
                console.log("error", err);
                if (err.response.status == 409) {
                  setDuplicateFoodErrMsg("Duplicate name");
                }
                setShowLoader(false);
              });
          } else setDuplicateFoodErrMsg("Duplicate name");
        } else {

          let dupRes = subcategoryData.find( (o) =>o.product_name.toLowerCase() === values.product_name.toLowerCase()
          );

          // values.category_id = parseInt(values.category_id.split("|||")[0]);
          values.category_id = categoriesId;
          values.weight_unit = null;
          values.status = true;
          values.item_image = uploadImg;
          values.Star_price = parseInt(Star_price);
          values.current_quantity = 0;
          values.price = parseInt(price);
          values.weight = null;
          values.type = "Food";
          console.log("Add Food", values);

          makePostRequest("starcafe/insertsubcategory", values)
            .then((response) => {
              if (response.data) {
                console.log(response.data);
                getSubCategoryList(null);
              }
              setUploadImg([]);
              setShowLoader(false);
              setAlignment("");
              handleCloseAddFood();
              setDuplicateFoodErrMsg("");
              setCategoryUnitErrMsg("");
              setUploadImg([]);
            })
            .catch((err) => {
              if (err.response.status == 409) {
                setDuplicateFoodErrMsg("Duplicate name");
              }

              console.log(err);
              setShowLoader(false);
            });
        }
      } else setCategoryListErrMsg("Please select category");
    }
  };
  // Add or Edit Food Start

  // Edit Food List Start
  const editFood = (subCategoryObj) => {
    setFormValuesAddFood(subCategoryObj);
    setCategoriesId(subCategoryObj.category_id);
    setIsEditFood(true);
  };
  // Edit Food List Start

  // Sort By Sub Category Start
  function handleChangeSortBy() {
    //alert("In ASC")
    setSortColumnName("product_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getSubCategoryList(null);
  }
  // Sort By sub Category End

  // Edit Category
  const [openEditCategory, setOpenEditCategory] = useState(false);

  const handleClickOpenEditCategory = (scrollType) => () => {
    setOpenEditCategory(true);
    setScroll(scrollType);
  };

  const handleCloseEditCategory = () => {
    setOpenEditCategory(false);
  };

  // End

  const [alignment, setAlignment] = useState("");
  const handleAlignment = (event, newAlignment) => {
    //console.log("Select", newAlignment)
    setAlignment(newAlignment);
  };

  // Modal Main
  const [AddInventory, setOpenAddInventory] = useState(false);

  const handleClickOpenAddInventory = (scrollType) => () => {
    setOpenAddInventory(true);
    setScroll(scrollType);
  };

  const closeInventory = () => {
    setOpenAddInventory(false);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const checkQuantity = (value, quantity, index) => {
    let Value = 0;
    const regex = /^[0-9\b]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (isDeductProduct == true) {
      if (regex.test(value) && quantity >= value) {
        document.getElementById(index).innerHTML = quantity - value;
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    } else {
      if (regex.test(value)) {
        document.getElementById(index).innerHTML =
          parseInt(quantity) + parseInt(value);
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", Value);
  };

  // Add Kitchen Supplies Start
  async function addKitchen(value, subcategory_id, quantity, index, type) {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";

    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value)) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to add given quantity of items to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);

            let inputData = {
              subcategory_id: subcategory_id,
              current_quantity: parseInt(
                document.getElementById(index).innerHTML
              ),
              new_quantity:parseInt(value),
              quantity_updated_dt:new Date()
            };

            makePostRequest("starcafe/updatesubcategory", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity added to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getSubCategoryList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });

            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", value);
  }

  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const categoryIcoClick = () => {
    var element = document.getElementById("slideCateId");
    element.classList.toggle("slideCateSt");
  };

  React.useEffect(() => { }, [uploadImg, upload]);

  // Deduct
  const [DeductInventoryKitchen, setOpenDeductInventoryKitchen] =
    useState(false);
  const [isDeductProduct, setIsDeductProdct] = useState(true);

  const handleClickOpenDeductInventoryKitchen =
    (scrollType, isDeduct) => async () => {
      // setFilterType("Product");
      //  setoffsetvalue(0);
      //  setItemsPerPage(100)
      await getSubCategoryList(null);

      setIsDeductProdct(isDeduct);
      setOpenDeductInventoryKitchen(true);
      setScroll(scrollType);

    };

  const closeDeductInventoryKitchen = () => {
    setOpenDeductInventoryKitchen(false);
  };

  // Add Items to Kitchen
  const [AddToKitchen, setOpenAddToKitchen] = React.useState(false);

  const closeAddToKitchen = () => {
    setOpenDeductInventoryKitchen(false);
  };

  //useEffect start
  useEffect(() => {
    getCategoryList(null);
    getCategoryDropDown(null);
    getSubCategoryList(null);
    getMasterDollarAmount();

    if (AdProdCafeInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (AddInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (DeductInventoryKitchen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (openEditCategory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [
    AdProdCafeInventory,
    AddInventory,
    open,
    openEditCategory,
    filterType,
    pageNo,
  ]);
  //end

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <div className="listingSearchAndFilterMain">
              <div className="prodSearchDiv">
                <input
                  type="text"
                  className="prodSearchInpt"
                  placeholder="Search"
                  onChange={(e) => getSubCategoryList(e.target.value)}
                />
                <div>
                  <FilterListOutlinedIcon
                    className="filterIconSearch"
                    id="basic-button"
                    aria-controls={open1 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleFilter}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => handleFilter(null)}>All</MenuItem>
                    <MenuItem onClick={() => handleFilter("Food")}>
                      Food
                    </MenuItem>
                    <MenuItem onClick={() => handleFilter("Product")}>
                      Product
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <div className="sortingIcoOut">
                <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    handleChangeSortBy();
                  }}
                >
                  <ImportExportIcon
                    className="visitorFilterIco"
                    aria-haspopup="true"
                  />
                </ToggleButton>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={9} className="addImpotBtOuter">
            {/* <Button
              className="pageTopMainBt"
              onClick={handleClickScanPop("body")}
            >
              <AddIcon /> Add Cafe Inv.
            </Button> */}

            <Button className="pageTopMainBt" onClick={handleClickOpen("body")}>
              <AddIcon /> Manage Category
            </Button>
            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenDeductInventoryKitchen("body", false)}
            >
              <AddIcon /> Add to Kitchen
            </Button>
            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenAddFood("body")}
            >
              <AddIcon /> Add Food
            </Button>
            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenAdProdCafeInventory("body")}
            >
              <AddIcon /> Add Product
            </Button>
            <Button
              className="pageTopMainBt forImportB"
              component="label"
              variant="contained"
            >
              <UploadIcon />
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>

          {subcategoryData.length > 0 ? (
            subcategoryData.map((subCategoryObj) => (
              <Grid item xs={12} lg={12}>
                <div className="serviceistOuter">
                  <div
                    className="mainListDiv"
                    key={subCategoryObj.subcategory_id}
                  >
                    {subCategoryObj.type == "Food" ? (
                      <div className="flagSt">Food</div>
                    ) : (
                      <div className="flagSt">Product</div>
                    )}
                    <div className="mainListCont">
                      <h1
                        onClick={() =>
                          navigate(
                            "/FoodProductDetails/" +
                            subCategoryObj.subcategory_id
                          )
                        }
                      >
                        {subCategoryObj.product_name}
                      </h1>
                      <p>ID {subCategoryObj.subcategory_id}</p>
                      <span className="dateStNew">
                        {moment(subCategoryObj.created_at).format("DD/MM/YYYY")}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <label>Category</label>
                      <span className="mainListSubTx">
                        {subCategoryObj.category_names}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <label>Quantity</label>
                      <span className="mainListSubTx">
                        {subCategoryObj.current_quantity ?
                          subCategoryObj.current_quantity : <>---</>}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <label>Weight Per Unit</label>

                      {subCategoryObj.weight ? (
                        <span className="mainListSubTx">
                          {subCategoryObj.weight}
                          {subCategoryObj.weight_unit}
                        </span>
                      ) : (
                        <span className="mainListSubTx">---</span>
                      )}
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <label>Price Per Unit in INR (₹)</label>
                      <span className="mainListSubTx">
                        ₹ {subCategoryObj.price}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="actionBtnOut">
                        {/* <Tooltip title="Edit" arrow placement="top">
                          <IconButton>
                            <img src={printIco} />
                          </IconButton>
                        </Tooltip> */}

                        <Tooltip title="Edit" arrow placement="top">
                          <IconButton
                            onClick={() =>
                              editSubCategory(subCategoryObj, "body")
                            }
                          >
                            <img src={editIco} />
                          </IconButton>
                        </Tooltip>
                        <Switch
                          {...label}
                          checked={subCategoryObj.status}
                          className="swicthMain"
                          onChange={(event) =>
                            handleSubCategoryStatus(
                              event,
                              subCategoryObj.subcategory_id
                            )
                          }
                        />

                        <Tooltip title="Delete" arrow placement="top">
                          <IconButton
                            onClick={() =>
                              deleteSubCategory(
                                subCategoryObj.subcategory_id,
                                subCategoryObj.type
                              )
                            }
                          >
                            <img src={deletIco} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <div className="dataNotfond">
              <img src={dataNotFound} />
              Data not found
            </div>
          )}
          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={pageNo}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </div>

      {/* Add Category || Manage Category */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Manage Category{" "}
            <CloseIcon className="modalCloseBt" onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Formik
                        initialValues={formValues || initialValues}
                        validationSchema={validationSchema}
                        onSubmit={addOrUpdateCategory}
                        enableReinitialize
                      >
                        {(formik) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={12}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Category Name"
                                  placeholder="Category Name"
                                  labelClass="pageLabel"
                                  name="category_name"
                                  isRequired="true"
                                  //value={category_name}
                                  className="prodSearchInpt"
                                //onChange={(e) => handleCheckValue(e.target.value)}
                                />
                                <span className="errorSt">
                                  {duplicateErrMsg}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "right" }}
                              >
                                {isEditCategory ? (
                                  <Button
                                    className="pageTopMainBt pageTopMainCancelBt "
                                    onClick={() => cancelCategory()}
                                  >
                                    <>Cancel</>
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                <Button
                                  className="pageTopMainBt "
                                  type="submit"
                                >
                                  {isEditCategory ? (
                                    <>Update</>
                                  ) : (
                                    <>Add Category</>
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getCategoryList(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortByCategory();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain verticleScroll">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>
                                Category Name{" "}
                                <em style={{ color: "#878787", fontSize: 12 }}>
                                  (Total {categoriesList.length} Categories)
                                </em>
                              </th>
                              <th>Action</th>
                              <th>Set Priority</th>
                            </tr>
                          </thead>
                          {categoriesList.map((data) => (
                            <tbody key={data.category_id}>
                              <tr>
                                <td>{data.category_name}</td>
                                <td>
                                  <div className="actionBtnOut">
                                    <Tooltip title="Edit" arrow placement="top">
                                      <IconButton
                                        // onClick={() => navigate("/")}
                                        onClick={() => editCategory(data)}
                                      >
                                        <img src={editIco} />
                                      </IconButton>
                                    </Tooltip>

                                    <Switch
                                      {...label}
                                      checked={data.status}
                                      className="swicthMain"
                                      onChange={(event) =>
                                        handleStatusChange(
                                          event,
                                          data.category_id
                                        )
                                      }
                                    />

                                    <Tooltip
                                      title="Delete"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton
                                        onClick={() =>
                                          deleteCategory(data.category_id)
                                        }
                                      >
                                        <img src={deletIco} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td>
                                  <Switch
                                    {...label}
                                    checked={data.isoncafepriority}
                                    className="swicthMain"
                                    onChange={(event) =>
                                      handleChangePriority(
                                        event,
                                        data.category_id
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Product in Cafe Inventory  kkkk*/}
      <div>
        <Dialog
          open={AdProdCafeInventory}
          // onClose={CloseAdProdCafeInventory}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditSubCategory ? <>Edit Product</> : <>Add Product</>}
            <CloseIcon
              className="modalCloseBt"
              onClick={CloseAdProdCafeInventory}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={
                      formValuesSUbCategory || initialValuesAddProduct
                    }
                    validationSchema={validationSchemaAddProduct}
                    onSubmit={addOrEditSubCategory}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <div className="addRemoveImagesBox">
                              {uploadImg ? (
                                <>
                                  {uploadImg.map((img, index) => (
                                    <div className="imageAddedBox" key={index}>
                                      <img src={img} />
                                      <div className="cloIm">
                                        <CloseIcon
                                          onClick={() => deleteImg(index)}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                              {uploadImg.length < 4 ? (
                                <div className="forUploadImgErrorOuter">
                                  <div className="uploadImBox">
                                    <h1 className="">Upload Image</h1>
                                    <Button
                                      className="uploadImBt"
                                      component="label"
                                      variant="contained"
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload
                                      <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) =>
                                          uploadProfilePic(event)
                                        }
                                      />
                                    </Button>
                                    <span>or drag file in here</span>
                                  </div>
                                  <span className="errorSt">{imageErrMsg}</span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Product Name"
                              placeholder="Product Name"
                              labelClass="pageLabel"
                              name="product_name"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                            <span className="errorSt">
                              {duplicateFoodErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="muliSelectMain">
                              <label className="pageLabel">
                                Product Categories
                              </label>
                              <FormControl>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={categoriesId}
                                  onChange={handleChange}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) =>
                                    selected
                                      .map((id) => categoryMap[id])
                                      .join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {categoryData.map((category) => (
                                    <MenuItem
                                      key={category.category_name}
                                      name={category.category_name}
                                      value={category.category_id}
                                    >
                                      <Checkbox
                                        // checked={categoriesId.indexOf(category.category_id) > -1}
                                        checked={
                                          categoriesId.includes(
                                            category.category_id
                                          )
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <ListItemText
                                        primary={category.category_name}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <span className="errorSt">
                                {categoryListErrMsg}
                              </span>
                            </div>

                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label class="reggistraLabel">Current Quantity <span className="requiredStar">*</span></label>
                            <input
                              type="text"
                              placeholder="Current Quantity"
                              value={current_quantity}
                              onChange={(e) => setCurrentQuantity(e.target.value)}
                              className="prodSearchInpt"
                              isRequired="true"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label class="reggistraLabel">Enter New Quantity <span className="requiredStar">*</span></label>
                            <input
                              type="text"
                              placeholder="New Quantity"
                              value={new_quantity}
                              onChange={(e) => setNewQuantity(e.target.value)}
                              onBlur={(e) => handleChangeNewQuantity(e.target.value)}
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                          </Grid>
                          {/* <Grid item xs={12} md={6}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Enter New Quantity" 
                        placeholder="New Quantity"
                        labelClass="pageLabel"
                        name="new_quantity"
                        className="prodSearchInpt"
                      />
                    </Grid> */}
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Weight Per Unit"
                              placeholder="Weight"
                              labelClass="pageLabel"
                              name="weight"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select Weight Unit
                              <span className="requiredStar">*</span>
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <ToggleButtonGroup
                                  value={alignment}
                                  exclusive
                                  onChange={handleAlignment}
                                  className="detailsTableFilterSecActionInr"
                                >
                                  <ToggleButton
                                    value="Gm"
                                    className="filterBtn"
                                  >
                                    Grams
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Kg"
                                    className="filterBtn"
                                  >
                                    Kilo Grams
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Ltr"
                                    className="filterBtn"
                                  >
                                    Liter
                                  </ToggleButton>
                                  <ToggleButton
                                    value="ml"
                                    className="filterBtn"
                                  >
                                    Milliliter
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Tan"
                                    className="filterBtn"
                                  >
                                    Tonne
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </div>
                            <span className="errorSt">
                              {categoryUnitErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="textarea"
                              type="text"
                              label="Description"
                              placeholder="Write here"
                              labelClass="pageLabel"
                              name="discription"
                              isRequired="true"
                              className="modalTexArea"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Price In Stars (*)
                            </label>
                            <input
                              type="text"
                              placeholder="Price In Stars"
                              value={Star_price}
                              className="prodSearchInpt"
                              onChange={(e) =>
                                handleChangeDollarAmount(e.target.value)
                              }
                            />

                            <span style={{ color: "red", fontSize: 14 }}>
                              {priceErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Enter Price in INR (₹)
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Price in INR (₹)"
                              value={price}
                              className="prodSearchInpt"
                              disabled
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <span onClick={closeInventory} className="lineBtns">
                              {isEditSubCategory ? (
                                <Button
                                  className="cancelEditBtn"
                                  onClick={() => cancelSubCategory()}
                                >
                                  <>Cancel</>
                                </Button>
                              ) : (
                                <></>
                              )}
                              <Button
                                className="modalBtSmallWidth"
                                type="submit"
                              >
                                {isEditSubCategory ? (
                                  <>Update </>
                                ) : (
                                  <>Add Product</>
                                )}
                              </Button>
                              {/* {isEditSubCategory ? (
                                <Button
                                  className="modalBtSmallWidth"
                                  onClick={() => cancelSubCategory()}
                                >
                                  <>Cancel</>
                                </Button>
                              ) : (
                                <></>
                              )} */}
                            </span>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Food Dialog */}
      <div>
        <Dialog
          open={AddFood}
          // onClose={handleCloseAddFood}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditFood ? <>Edit Food</> : <>Add Food</>}
            <CloseIcon className="modalCloseBt" onClick={handleCloseAddFood} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={formValuesAddFood || initialValuesAddFood}
                    validationSchema={validationSchemaAddFood}
                    onSubmit={addOrEditFood}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <>
                            <Grid item xs={12} md={12}>
                              <div className="addRemoveImagesBox">
                                {uploadImg ? (
                                  <>
                                    {uploadImg.map((img, index) => (
                                      <div
                                        className="imageAddedBox"
                                        key={index}
                                      >
                                        <img src={img} />
                                        <div className="cloIm">
                                          <CloseIcon
                                            onClick={() => deleteImg(index)}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                ) : null}
                                {uploadImg ? (
                                  <>
                                    {uploadImg.length < 4 ? (
                                      <div className="forUploadImgErrorOuter">
                                        <div className="uploadImBox">
                                          <h1 className="">Upload Image</h1>
                                          <Button
                                            className="uploadImBt"
                                            component="label"
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                          >
                                            Upload
                                            <VisuallyHiddenInput
                                              type="file"
                                              onChange={(event) =>
                                                uploadProfilePic(event)
                                              }
                                            />
                                          </Button>
                                          <span>or drag file in here</span>
                                        </div>
                                        <span className="errorSt">
                                          {imageErrMsg}
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              {/* <span>{imageErrMsg}</span> */}
                            </Grid>
                          </>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Food Name"
                              placeholder="Food Name"
                              labelClass="pageLabel"
                              name="product_name"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                            <span className="errorSt">
                              {duplicateFoodErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="muliSelectMain">
                              <label className="pageLabel">
                                Food Categories
                              </label>
                              <FormControl>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={categoriesId}
                                  onChange={handleChange}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) =>
                                    selected
                                      .map((id) => categoryMap[id])
                                      .join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {categoryData.map((category) => (
                                    <MenuItem
                                      key={category.category_name}
                                      name={category.category_name}
                                      value={category.category_id}
                                    >
                                      <Checkbox
                                        checked={
                                          categoriesId.indexOf(
                                            category.category_id
                                          ) > -1
                                        }
                                      />
                                      <ListItemText
                                        primary={category.category_name}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <span className="errorSt">
                                {categoryListErrMsg}
                              </span>
                            </div>

                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Price In Stars (*)
                            </label>
                            <input
                              type="text"
                              placeholder="Price In Stars"
                              value={Star_price}
                              className="prodSearchInpt"
                              onChange={(e) =>
                                handleChangeDollarAmount(e.target.value)
                              }

                            />
                            <span style={{ color: "red", fontSize: 14 }}>
                              {priceErrMsg}
                            </span>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Enter Price in INR (₹)
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Price in INR (₹)"
                              value={price}
                              className="prodSearchInpt"
                              disabled
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="textarea"
                              type="text"
                              label="Description"
                              placeholder="Write here"
                              labelClass="pageLabel"
                              name="discription"
                              isRequired="true"
                              className="modalTexArea"
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <span onClick={closeInventory} className="lineBtns">
                              {isEditFood ? (
                                <Button
                                  className="cancelEditBtn"
                                  onClick={() => cancelSubCategory()}
                                >
                                  <>Cancel</>
                                </Button>
                              ) : (
                                <></>
                              )}

                              <Button
                                className="modalBtSmallWidth"
                                type="submit"
                              >
                                {isEditFood ? <>Update</> : <>Add food</>}
                              </Button>
                            </span>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add to Kitchen */}
      <div>
        <Dialog
          open={DeductInventoryKitchen}
          // onClose={closeAddToKitchen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add to Kitchen
            <CloseIcon className="modalCloseBt" onClick={closeAddToKitchen} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getSubCategoryList(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortBy();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Quantity In Stock</th>
                              <th>Purchase Price in INR (₹)</th>
                              <th>Quantity To Add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subcategoryData.map((obj, index) => {
                              return (
                                <tr>
                                  <td>{obj.product_name}</td>
                                  <td id={index}>{obj.current_quantity}</td>
                                  <td>₹ {obj.price}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="qutyInput"
                                      placeholder="Quantity"
                                      onChange={(e) =>
                                        checkQuantity(
                                          e.target.value,
                                          obj.current_quantity,
                                          index
                                        )
                                      }
                                      onBlur={(e) =>
                                        addKitchen(
                                          e.target.value,
                                          obj.subcategory_id,
                                          obj.current_quantity,
                                          index
                                        )
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      <span id={`span` + index}> </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Add Item</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Cafe Inventory */}
      <div>
        <Dialog
          open={ScanPop}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Cafe Inventory
            <CloseIcon className="modalCloseBt" onClick={closeScanPop} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Scan SKU"
                          // onChange={(e) => getGroceryData(e.target.value)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>SKU No.</th>
                              <th>Product Name</th>
                              <th>Quantity In Stock</th>
                              <th>Manage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td>Sku20</td>
                              <td>Cookie pasteries</td>
                              <td>625</td>
                              <td className="manageActIcons">
                                <TaskAltIcon className="managMarkCheck"/>
                                <RemoveCircleOutlineIcon className="managRemoveCheck"/>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Add Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CafeInventoryTab;
